import { useContext, useState } from 'react';
import { Button, List } from '@mui/material';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';

import { ConfiguratorListItem } from '../../common';
import { ConfiguratorContext } from '../../../context/configuratorContext';
import { PhotoPreview } from '../../photos';


export const ComponentDetails = () => {
  const [previewedPhoto, setPreviewedPhoto] = useState(null);
  const {
    selectedComponent: component,
    setSelectedComponent
  } = useContext(ConfiguratorContext);

  return (
    <div className="basic-form--in-content basic-form--details">
      <p>{component.description || '-'}</p>
      <div className="photos__basic-container">
        <h2>Detailed photos</h2>
        {component.photos.length > 0
          ? (
            <List>
              {component.photos.map(photo =>
                <ConfiguratorListItem
                  icon={<ImageOutlinedIcon fontSize="1rem" />}
                  key={photo.id}
                  name={photo.name}
                  onListItemClick={() => setPreviewedPhoto(photo)}
                />
              )}
            </List>
          )
          : <p className="info">There are no photos yet</p>
        }
      </div>
      <Button fullWidth variant="outlined" onClick={() => setSelectedComponent(null)}>
        Close
      </Button>
      {previewedPhoto &&
        <PhotoPreview photo={previewedPhoto} onClose={() => setPreviewedPhoto(null)} />
      }
    </div>
  )
}
