import { useState } from 'react';
import {
  Button,
  List,
} from "@mui/material";
import { Add, Tune } from '@mui/icons-material';
import { Link, useNavigate } from "react-router-dom";

import { PERMISSION } from '../../../helpers/consts';
import { URLS } from '../../../urls/frontend';
import { DefaultListItem, Dialog } from '../../common';
import { AddConfigurationForm } from '../';

export const ConfigurationList = ({
  configurations,
  isActiveVehicle,
  permission,
  vehicleId,
}) => {
  const [isAddSystemDialogOpened, setIsAddSystemDialogOpened] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <div className="configurations-list">
        {permission === PERMISSION.READ_WRITE &&
          <Button
            className="add-button"
            disabled={!isActiveVehicle}
            startIcon={<Add />}
            sx={{display: 'flex', marginBottom: '1.5rem'}}
            variant="contained"
            onClick={() => setIsAddSystemDialogOpened(true)}
          >Add configuration</Button>}

        <h2 className="configurations-list__title">Configurations</h2>

        {configurations.length > 0
          ? (
            <List className="systems-group-list__list-container">
              {configurations.map((config) => (
                <DefaultListItem
                  component={Link}
                  icon={<Tune />}
                  key={config.id}
                  navigate={URLS.configurationsGetDetails.getUrl(config.id, vehicleId)}
                  title={config.name}
                />
              ))}
            </List>
          ) : <p className="info">There are no configurations yet</p>
        }
      </div>
      {isAddSystemDialogOpened && <Dialog onCloseDialog={() => setIsAddSystemDialogOpened(false)}>
        <AddConfigurationForm
          addCallbackSuccess={(data) => navigate(URLS.configurationsGetDetails.getUrl(data.id, vehicleId))}
          onCancel={() => setIsAddSystemDialogOpened(false)}/>
      </Dialog>}
    </>
  );
};
