import { MAX_COMPONENT_PHOTOS_COUNT } from '../helpers/consts';
import { checkRequired, Validator } from './base';

export const checkMaxLength = (value) => {
  if (value.length > MAX_COMPONENT_PHOTOS_COUNT) {
    return `It is impossible to add more than ${MAX_COMPONENT_PHOTOS_COUNT} photos to the component.`
  }
  return ''
};

export const validate = (data) => {
  return new Validator(
    {'name': [checkRequired, ], 'photos': [checkMaxLength, ]},
    data
  ).validate()
};

export const validateNewComponent = (data) => {
  return new Validator(
    {'name': [checkRequired, ]},
    data
  ).validate()
};
