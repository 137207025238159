import { Link } from 'react-router-dom';
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import './Breadcrumbs.sass';

export const Breadcrumbs = ({currentLocation, breadcrumbs}) => {
  return (
    <div className="breadcrumbs">
      <MuiBreadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
        {breadcrumbs && breadcrumbs.map((brc, idx) =>
          <Link className="breadcrumbs__link" key={idx} to={brc[0]}><nobr>{brc[1]}</nobr></Link>
        )}
        <p className="breadcrumbs__list-element"><nobr>{currentLocation}</nobr></p>
      </MuiBreadcrumbs>
    </div>
  )
}
