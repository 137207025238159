import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  List,
} from "@mui/material";
import { Add } from '@mui/icons-material';

import { UserContext } from "../../../context/userContext";
import { useGetData } from '../../../helpers/hooks';
import { API_URLS } from '../../../urls/backend';
import { Dialog, Loader } from "../../common";
import { AddTagForm, Tag } from "..";
import './TagList.sass';

export const TagList = ({ isActiveVehicle }) => {
  const [isAddDialogOpened, setIsAddDialogOpened] = useState(false);
  const [tags, setTags] = useState([]);
  const { vehicleId } = useParams();
  const { user } = useContext(UserContext);

  const { data = {}, fetchingOn, fetchError } = useGetData({
    url: API_URLS.tagsGetForVehicle.getUrl(vehicleId),
  });

  useEffect(() => {
    if (Object.keys(data).length === 0) return;

    setTags(data.tags.reverse());
  }, [data]);

  const handleAddCallbackSuccess = (data) => {
    setTags([data, ...tags]);
    setIsAddDialogOpened(false);
  };

  const handleDeleteTag = (tagId) => {
    setTags(tags.filter((tag) => tag.id !== tagId));
  }

  return (
    <>
      <div className="tags-list">
        {user.isAdmin &&
          <Button
            className="add-button"
            disabled={!isActiveVehicle}
            startIcon={<Add />}
            sx={{ display: 'flex', marginBottom: '1.5rem' }}
            variant="contained"
            onClick={() => setIsAddDialogOpened(true)}
          >Add tag</Button>
        }

        <h2 className="tags-list__title">Tags</h2>
        {fetchingOn
          ? <Loader />
          : (fetchError !== null
            ? <p className="error">{fetchError}</p>
            : (
              <>
                {tags && tags.length > 0
                  ? (
                    <List className="tags-list__list">
                      {tags.sort((s1, s2) => s1.name.localeCompare(s2.name)).map((tag) => (
                        <Tag
                          isActiveVehicle={isActiveVehicle}
                          isAdmin={user.isAdmin}
                          key={tag.id}
                          tag={tag}
                          onDeleteItem={handleDeleteTag}
                        />
                      ))}
                    </List>
                  ) : <p className="info">There are no tags yet</p>
                }
                {isAddDialogOpened && (
                  <Dialog>
                    <AddTagForm
                      addCallbackSuccess={handleAddCallbackSuccess}
                      onCancel={() => setIsAddDialogOpened(false)}
                    />
                  </Dialog>
                )}
              </>
            )
          )
        }
      </div>
    </>
  );
};
