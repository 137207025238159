import { useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  IconButton,
  List,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { ConfiguratorContext } from '../../../context/configuratorContext';
import { UserContext } from '../../../context/userContext';
import { PERMISSION } from '../../../helpers/consts';
import { ConfiguratorDialog, ConfiguratorListItem } from '../../common';
import { AddSetForm, Set } from '..';
import './SetList.sass';


export const SetList = () => {
  const [isAddSetDialogOpened, setIsAddSetDialogOpened] = useState(false);
  const [isSetListOpened, setIsSetListOpened] = useState(true);

  const { systemId } = useParams();
  const {
    addSet,
    componentsAreFetching,
    selectedSet,
    sets,
    setSelectedSet,
    vehicleDetails
  } = useContext(ConfiguratorContext);
  const { current: permission } = useContext(UserContext).vehiclePermission;

  const handleAddCallbackSuccess = (data) => {
    addSet(data);
    setSelectedSet(data)
    setIsAddSetDialogOpened(false);
  };

  const sortedSets = useMemo(() => {
    // if set list is empty or it has only one element, sorting is unnecessary
    if (sets.length < 2) { return sets }
    const firstSet = sets.reduce((s1, s2) => s1.id < s2.id ? s1 : s2);
    return [
      firstSet,
      ...sets.filter(set => set.id !== firstSet.id)
        .sort((s1, s2) => s1.name.localeCompare(s2.name))
    ]
  }, [sets]);

  return (
    <div className="set-list">
      <div className="flex-container--end">
        {permission === PERMISSION.READ_WRITE &&
          <Button
            disabled={!vehicleDetails.isActive || componentsAreFetching}
            size="small"
            startIcon={<AddIcon />}
            onClick={() => setIsAddSetDialogOpened(true)}
          >
            Add set of components
          </Button>
        }
      </div>
      {!sortedSets.length
        ? <p className="error">There is no set available for the vehicle.</p>
        : (
          <>
            <button className="set-list__toggle-button" onClick={() => setIsSetListOpened(!isSetListOpened)}>
              <h2>Component set: {selectedSet?.name || 'all sets'}</h2>
              <IconButton component="span" size="small">
                {isSetListOpened ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </button>
            {isSetListOpened &&
              <List>
                <ConfiguratorListItem
                  disabled={componentsAreFetching}
                  isActive={!selectedSet}
                  name="All sets of components"
                  onListItemClick={() => setSelectedSet(null)}
                />
                {sortedSets.map(set =>
                  <Set
                    disabled={componentsAreFetching}
                    key={set.id}
                    set={set}
                    systemId={systemId}
                  />
                )}
              </List>
            }
          </>
        )
      }
      {isAddSetDialogOpened &&
        <ConfiguratorDialog onCloseDialog={() => setIsAddSetDialogOpened(false)}>
          <AddSetForm
            addCallbackSuccess={handleAddCallbackSuccess}
            systemId={systemId}
            onCancel={() => setIsAddSetDialogOpened(false)}
          />
      </ConfiguratorDialog>}
    </div>
  )
}
