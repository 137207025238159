import type {
  IComponentWithPositions,
  IPosition,
  ISubModule,
} from "./interfaces";
import levels from "./__mocks__/levels";
import { demoModules as modules } from "./__mocks__/modules";

export const updateSubModuleInCoreBase = (subModule: ISubModule) => {
  for (let i = 0; i < modules.length; i++) {
    for (let y = 0; y < modules[i].subModules.length; y++) {
      if (modules[i].subModules[y].id === subModule.id) {
        modules[i].subModules[y] = subModule;
      }
    }
  }
};

export const generateJSON = () => {
  return JSON.stringify({ modules, levels });
};

export const updateBase = (data: any) => {
  modules.length = 0;
  levels.length = 0;
  levels.push(...data.levels);
  modules.push(...data.modules);
};

export const getSubmoduleWithUpdatedPositions = (
  subModule: ISubModule,
  levelPositions: IPosition[],
  currentLevelId: string
): ISubModule => {
  const nModule = {
    ...subModule,
  };
  nModule.components.forEach((comp) => {
    const componentLvlPositions = levelPositions.filter(
      (lvlPos) => lvlPos.componentId === comp.id
    );
    const tempPosition: IPosition[] = [];

    comp.positions?.forEach((pos) => {
      if (pos.levelId !== currentLevelId) {
        tempPosition.push(pos);
      }
    });

    tempPosition.push(...componentLvlPositions);

    comp.positions = tempPosition;
  });

  return nModule;
};

export const getLevelPositions = (
  subModule?: ISubModule,
  currentLevelId?: string
) => {
  if (!subModule) return [];

  return subModule.components
    .map((com) => {
      if (!com.positions) return [];
      return com.positions.filter((pos) => pos.levelId === currentLevelId);
    })
    .flat();
};

export const calculateLevelComponents = (
  component: IComponentWithPositions
) => {
  const tempLevelComponents: any = [];

  component.positions?.forEach((pos) => {
    if (tempLevelComponents[pos.levelId.toString()]) {
      tempLevelComponents[pos.levelId.toString()] += 1;
    } else {
      tempLevelComponents[pos.levelId.toString()] = 1;
    }
  });

  return tempLevelComponents;
};


export function flipImage(inputImage: HTMLImageElement, degree: number) {
  const outputImage = document.createElement("canvas");
  outputImage.width = inputImage.naturalWidth;
  outputImage.height = inputImage.naturalHeight;

  const ctx = outputImage.getContext("2d");

  ctx?.save();
  ctx?.translate(outputImage.width / 2, outputImage.height / 2);
  ctx?.rotate((degree * Math.PI) / 180);
  ctx?.drawImage(inputImage, -inputImage.width / 2, -inputImage.width / 2);
  ctx?.restore();

  return outputImage;
}