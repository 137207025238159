import { Component, createContext } from 'react';

import { PERMISSION } from '../helpers/consts';


const DEFAULT_PREVIEW_MODE_LISTENERS = {
  activatePreview: true,  // in preview mode it always can be turned on
};
const DEFAULT_LISTENERS = {
  activatePreview: false,
  markNewComponent: false,
};


export const ConfiguratorContext = createContext({});


export class ConfiguratorPreviewModeProvider extends Component {

  constructor(props) {
    super(props);
    this.state = {
      listeners: {...DEFAULT_PREVIEW_MODE_LISTENERS},
      markDialogOpened: this.markDialogOpened,
      markInputFocused: this.markInputFocused,
      isPanoramaInRotatingMode: false,
      setIsPanoramaInRotatingMode: this.setIsPanoramaInRotatingMode,
      zoom: 1,
      setZoom: this.setZoom,
    };
  }

  markDialogOpened = (isOpened) =>
    this.togglePanoramaPreviewListeners(!isOpened);
  markInputFocused = (isFocused) =>
    this.togglePanoramaPreviewListeners(!isFocused);
  togglePanoramaPreviewListeners = (turnOn) => this.setState({
    listeners: {activatePreview: turnOn}
  });
  setIsPanoramaInRotatingMode = (isPanoramaInRotatingMode) =>
    this.setState(prevState => ({
      isPanoramaInRotatingMode,
      listeners: {...prevState.listeners, markNewComponent: !isPanoramaInRotatingMode},
    }));

  setZoom = (increase) => this.setState(prevState => {
    let zoom = prevState.zoom;
    if (increase) {
      zoom < 5 && zoom++;
    } else {
      zoom > 1 && zoom--;
    }
    return { zoom }
  });

  render() {
    return (
      <ConfiguratorContext.Provider value={this.state}>
        {this.props.children}
      </ConfiguratorContext.Provider>
    );
  }

}

export class ConfiguratorProvider extends ConfiguratorPreviewModeProvider {

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      listeners: {...DEFAULT_LISTENERS},

      vehicleDetails: {},
      setVehicleDetails: this.setVehicleDetails,
      panoramas: [],
      setPanoramas: this.setPanoramas,
      selectedPanorama: null,
      setSelectedPanorama: this.setSelectedPanorama,
      sets: [],
      setSets: this.setSets,
      selectedSet: null,
      setSelectedSet: this.setSelectedSet,
      addSet: this.addSet,
      editSet: this.editSet,
      removeSet: this.removeSet,

      components: [],
      setComponents: this.setComponents,
      outerSelectedComponentId: null,
      setOuterSelectedComponentId: this.setOuterSelectedComponentId,
      componentsAreFetching: false,
      setComponentsAreFetching: this.setComponentsAreFetching,
      temporaryComponent: null,
      setTemporaryComponent: this.setTemporaryComponent,
      addComponent: this.addComponent,
      selectedComponent: null,
      setSelectedComponent: this.setSelectedComponent,
      removeComponent: this.removeComponent,
      editComponent: this.editComponent,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      (this.state.selectedPanorama?.id && prevState.selectedPanorama?.id !== this.state.selectedPanorama?.id) ||
      (prevProps.permission !== this.props.permission)
    ) {
      // turn on listeners
      this.setState({listeners: {
        activatePreview: true,
        markNewComponent: this.props.permission === PERMISSION.READ_WRITE,
      }});
    }
  }

  togglePanoramaPreviewListeners = (turnOn) => this.setState({listeners: turnOn
    ? {
      activatePreview: true,
      markNewComponent: this.props.permission === PERMISSION.READ_WRITE,
    } : {...DEFAULT_LISTENERS}
  });

  setVehicleDetails = (vehicleDetails) => this.setState({vehicleDetails});
  setPanoramas = (panoramas) => this.setState({panoramas});
  setSelectedPanorama = (selectedPanorama) => this.setState({selectedPanorama});
  setSets = (sets, withFirstSelected = false) => this.setState({
    sets,
    ...(withFirstSelected
        ? sets.length
          ? {selectedSet: sets.reduce((prev, curr) => prev.id < curr.id ? prev : curr)}
          : {selectedSet : null}
        : {}
    ),
  });
  setSelectedSet = (selectedSet) => this.setState({selectedSet});
  addSet = (set) => this.setState(prevState => ({sets: [...prevState.sets, set]}));
  editSet = (set) => this.setState(prevState => ({
    sets: prevState.sets.map(set_ => set_.id === set.id ? set : set_),
    // check if selected set should be updated
    ...(prevState.selectedSet?.id === set.id ? {selectedSet: set} : {}),
  }));
  removeSet = (setId) => this.setState(prevState =>
    ({sets: prevState.sets.filter(set => set.id !== setId)}));

  setComponents = (components) => this.setState({components});
  setOuterSelectedComponentId = (outerSelectedComponentId) => this.setState({outerSelectedComponentId});
  setComponentsAreFetching = (componentsAreFetching) =>
    this.setState({componentsAreFetching});
  setTemporaryComponent = (temporaryComponent) =>
    this.setState({temporaryComponent, selectedComponent: null});
  addComponent = (component, callback) => {
    this.setState(prevState =>
      ({components: [...prevState.components, component]}));
    callback && callback();
  }
  setSelectedComponent = (selectedComponent) =>
    this.setState(({
      selectedComponent,
      // temporary component shouldn't exist anymore
      temporaryComponent: null,
    }));
  removeComponent = (componentId) => this.setState(prevState => ({
    components: prevState.components.filter(component => component.id !== componentId),
    ...(prevState.selectedComponent?.id === componentId ? {selectedComponent: null} : {}),
  }));
  editComponent = (component) => this.setState(prevState =>
    ({components: prevState.components.map(
      component_ => component_.id === component.id ? component : component_
    )})
  );
}
