import { VehiclesList } from '../../vehicles';
import { MainTemplate } from '../MainTemplate/MainTemplate';

import './HomePage.sass';

export const HomePage = () => {
  return (
    <MainTemplate>
      <VehiclesList />
    </MainTemplate>
  );
};
