import { useEffect } from 'react';
import { useThree } from '@react-three/fiber';
import { CubeRefractionMapping, CubeTextureLoader, sRGBEncoding } from 'three';

import { flipImage } from '../../../utils';


export const SkyBox = ({ images }) => {
  const { scene } = useThree();

  useEffect(() => {
    const imageList = [
      images.left,
      images.right,
      images.up,
      images.down,
      images.back,
      images.front,
    ];
    new CubeTextureLoader().load(imageList, (loadedTexture) => {
      loadedTexture.mapping = CubeRefractionMapping;
      loadedTexture.encoding = sRGBEncoding;

      // flip top and bottom
      loadedTexture.source.data[3] = flipImage(loadedTexture.source.data[3], 180);
      loadedTexture.source.data[2] = flipImage(loadedTexture.source.data[2], 180);

      scene.background = loadedTexture;
    });
  }, [images])

  return null;
};

