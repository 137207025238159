import './Positions.sass';

import { Position } from '..';

export const Positions = ({ onAddedPosition, permission, positions }) => {
  return (
    <>
      <div className="positions">
        {positions && Object.keys(positions).map((pos) => (
          <Position
            key={pos}
            className={`position--${pos}`}
            permission={permission}
            positionName={pos}
            src={positions[pos]}
            onAddedPosition={onAddedPosition}
          />
        ))}
      </div>
    </>
  );
};
