
export const URLS = {
  home: {
    path: '/',
  },
  accountsChangePassword: {
    path: '/change-password',
    getUrl: () => '/change-password',
  },
  accountsLogin: {
      path: '/login',
      getUrl: () => '/login',
  },
  configurationsGetDetails: {
    path: '/vehicles/:vehicleId/configurations/:configurationId',
    getUrl: (configurationId, vehicleId) => `/vehicles/${vehicleId}/configurations/${configurationId}`,
  },
  panoramasGetDetails: {
    path: '/vehicles/:vehicleId/panoramas/:panoramaPhotoId',
    getUrl: (panoramaPhotoId, vehicleId) => `/vehicles/${vehicleId}/panoramas/${panoramaPhotoId}`,
  },
  photos: {
    path: '/vehicles/:vehicleId/photos',
    getUrl: (vehicleId) => `/vehicles/${vehicleId}/photos`,
  },
  systemsConfigurator: {
    path: '/vehicles/:vehicleId/systems/:systemId',
    getUrl: (systemId, vehicleId) => `/vehicles/${vehicleId}/systems/${systemId}`,
  },
  vehiclesGetDetails: {
    path: '/vehicles/:vehicleId',
    getUrl: (vehicleId) => `/vehicles/${vehicleId}`,
  },
}
