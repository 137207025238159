import { useEffect, useRef, useState } from 'react';
import { useThree } from '@react-three/fiber';
import { useSpring, animated } from '@react-spring/three';


export const ComponentButton = ({
  isTemporary,
  isVisible,
  position,
  ...props
}) => {
  const meshRef = useRef(null);
  const [hovered, setHover] = useState(false);
  const { camera } = useThree();

  const { scale } = useSpring({ scale: (hovered || isTemporary) ? 1.3 : 1 });
  const { color } = useSpring({ color: (hovered || isTemporary)
      ? '#0000FF' : isVisible ? '#00F0FF' : '#D6FF00' });

  useEffect(() => {
    if (meshRef.current) {
      meshRef.current.lookAt(camera.position);
    }
  }, [meshRef?.current]);

  useEffect(() => {
    if (isTemporary && meshRef.current) {
      meshRef.current.lookAt(camera.position);
    }
  }, [position]);

  // Return view, these are regular three.js elements expressed in JSX
  return (
    <animated.mesh
      position={position}
      ref={meshRef}
      scale={scale}
      onPointerOver={(event) => setHover(true)}
      onPointerOut={(event) => setHover(false)}
      {...props}
    >
      <circleGeometry args={[0.3, 32]} />
      <animated.meshStandardMaterial
        emissive={color}
        emissiveIntensity={0}
        color={color}
        transparent={true}
      />
    </animated.mesh>
  );
};
