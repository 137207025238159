import { Component, createContext } from 'react';

import { PERMISSION } from '../helpers/consts';


const EMPTY_USER = {
    id: null,
    isAdmin: false,
    isAuthenticated: false,
    username: '',
};
const EMPTY_VEHICLE_PERMISSION = {
  vehicleId: null,
  original: null,
  current: null,
};


export const UserContext = createContext({
    user: { ...EMPTY_USER },
    initialized: false,
    setUserData: (data) => { },
    setInitialized: (value) => { },
    logout: () => { },

    vehiclePermission: { ...EMPTY_VEHICLE_PERMISSION },
    setVehiclePermission: () => {},
    // this value means that the view is allowed to be displayed in READ ONLY mode
    forcedReadOnlyModeAllowed: false,
    setForcedReadOnlyModeAllowed: (forcedReadOnlyModeAllowed) => {},
    readOnlyModeOn: false,
    setReadOnlyModeOn: (readOnlyModeOn) => {},
});


export class UserProvider extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: { ...EMPTY_USER },
            initialized: false,
            setUserData: this.setUserData,
            setInitialized: this.setInitialized,
            logout: this.logout,

            vehiclePermission: { ...EMPTY_VEHICLE_PERMISSION },
            setVehiclePermission: this.setVehiclePermission,
            forcedReadOnlyModeAllowed: false,
            setForcedReadOnlyModeAllowed: this.setForcedReadOnlyModeAllowed,
            readOnlyModeOn: false,
            setReadOnlyModeOn: this.setReadOnlyModeOn,
        };
    }

    setUserData = (userData) => {
        this.setState({
            user: { ...userData, isAuthenticated: true },
            initialized: true,
        })
    }

    setInitialized = (initialized) => {
        this.setState({ initialized });
    }

    logout = () => {
        this.setState({ user: { ...EMPTY_USER } });
    }

  setVehiclePermission = (vehicleId, originalPermission) =>
    // this method sets real user permission to the vehicle
    // besides it turns on forcedReadOnlyMode control in navbar
    this.setState(prevState => {
      const prevVehiclePermission = prevState.vehiclePermission;
      if (
        parseInt(prevVehiclePermission.vehicleId) !== parseInt(vehicleId) ||
        prevVehiclePermission.original !== originalPermission
      ) {
        return {
          vehiclePermission: {
            ...prevState.vehiclePermission,
            original: originalPermission,
            current: (originalPermission === PERMISSION.READ || prevState.readOnlyModeOn)
              ? PERMISSION.READ : PERMISSION.READ_WRITE,
          },
          forcedReadOnlyModeAllowed: true
        }
      }
      return {forcedReadOnlyModeAllowed: true}
    }
  );

  setForcedReadOnlyModeAllowed = (forcedReadOnlyModeAllowed) =>
    this.setState({forcedReadOnlyModeAllowed});

  setReadOnlyModeOn = (readOnlyModeOn) => this.setState(prevState => ({
    readOnlyModeOn,
    vehiclePermission: {
      ...prevState.vehiclePermission,
      current: (prevState.vehiclePermission.original === PERMISSION.READ || readOnlyModeOn) ?
        PERMISSION.READ : PERMISSION.READ_WRITE,
    },
  }));

    render() {
        return (
            <UserContext.Provider value={this.state}>
                {this.props.children}
            </UserContext.Provider>
        );
    }
}
