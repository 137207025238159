import { useEffect, useState } from 'react';

import { fetchApi, fetchFileApi } from './fetch';


export const useGetData = ({
  url,
  body = {},
  requestId = null,
  skipRequest = false,
  withCleaningData = false,
  callbackSuccess = null,
  callbackError = null
}) => {
  const [fetchingOn, setFetchingOn] = useState(requestId !== 0 && !skipRequest);
  const [data, setData] = useState({});
  const [fetchError, setFetchError] = useState(null);

  useEffect(() => {
    /*
      requestId === null means that hook isn't used repeatedly
      set requestId if you want to repeat hook; start from 1 if you need
      an initial request, otherwise 0 (first request on demand)
    */
    if (requestId === 0 || skipRequest) { return }
    if (withCleaningData) {
      setData({});
    }

    setFetchError(null);
    setFetchingOn(true);

    let xhrFetch = fetchApi({
      url,
      body,
      callbackSuccess: ({data: data_, status}) => {
        xhrFetch = null;
        setData(data_);
        setFetchingOn(false);
        if (callbackSuccess) {
          callbackSuccess({data: data_, status});
        }
      },
      callbackError: ({error, status}) => {
        xhrFetch = null;
        setData({});
        setFetchError(error);
        setFetchingOn(false);
        if (callbackError) {
          callbackError({error, status});
        }
      },
    });

    return () => {
      if (xhrFetch !== null) {xhrFetch.abort()}
    }
  }, [requestId, url]);

  return { data, fetchingOn, fetchError }
};


export const usePostData = ({
  method = 'POST',
  postData = {},
  savingOn,
  setSavingOn,
  url,
  callbackSuccess = null,
  callbackError = null,
}) => {
  const [fetchError, setFetchError] = useState(null);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!savingOn) {
      return
    }
    setErrors({});
    setFetchError(null);

    let xhrFetch = fetchApi({
      url,
      method,
      body: postData,
      callbackSuccess: ({data, status}) => {
        xhrFetch = null;
        setSavingOn(false);
        if (status === 400) {
          if (data.error) {
            callbackSuccess && callbackSuccess({data, status});
          } else {
            setErrors(data.errors || {});
          }
        } else {
          callbackSuccess && callbackSuccess({data, status});
        }
      },
      callbackError: ({error, status}) => {
        xhrFetch = null;
        setSavingOn(false);
        setFetchError(error);
        if (callbackError) {
          callbackError({error, status});
        }
      },
    });

    return () => {
      if (xhrFetch !== null) {xhrFetch.abort()}
    }
  }, [savingOn]);

  return { errors, fetchError }
}


export const usePostWithFileData = ({
  image,
  body = {},
  url,
  savingOn,
  setSavingOn,
  callbackSuccess,
  callbackError,
}) => {
  const [fetchError, setFetchError] = useState(null);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!savingOn) { return }

    setErrors({});
    setFetchError(null);

    let xhrFetch = null;

    xhrFetch = fetchFileApi({
      url,
      image,
      body,
      callbackSuccess: ({ status, data }) => {
        xhrFetch = null;
        if (status === 400) {
          // errors from form
          setErrors(data.errors);
          setSavingOn(false);
        }
        callbackSuccess && callbackSuccess({ data, status });
      },
      callbackError: ({ error, status }) => {
        xhrFetch = null;
        setFetchError(error);
        setSavingOn(false);
        callbackError && callbackError({ error, status });
      },
    });
    return () => {
      if (xhrFetch !== null) { xhrFetch.abort() }
    }

  }, [savingOn]);

  return { errors, fetchError }
};


export const useDeleteData = ({
  removingOn,
  url,
  data = {},
  callbackSuccess = null,
  callbackError = null,
}) => {
  const [fetchError, setFetchError] = useState(null);

  useEffect(() => {
    if (!removingOn) { return }

    setFetchError(null);
    let xhrFetch = fetchApi({
      url,
      method: 'DELETE',
      body: data,
      callbackSuccess: ({ data, status }) => {
        xhrFetch = null;
        if (callbackSuccess) {
          callbackSuccess({ data, status });
        }
      },
      callbackError: ({ error, status }) => {
        xhrFetch = null;
        setFetchError(error);
        if (callbackError) {
          callbackError({ error, status });
        }
      },
    });

    return () => {
      if (xhrFetch !== null) { xhrFetch.abort() }
    }
  }, [removingOn]);

  return { fetchError }
}

export const useFetchError = ({
  fetchError,
  setFetchError,
  timeoutValue = 3000
}) => {
  useEffect(() => {
    // this effect manages fetch error
    let timeout = null;
    if (fetchError) {
      timeout = setTimeout(() => {
        setFetchError(null);
      }, timeoutValue);
    } else {
      setFetchError(null);
    }
    return () => {
      if (timeout) { clearTimeout(timeout) }
    }
  }, [fetchError]);
}

export const useValidatingForm = ({
  data,
  validateFunction,
  validatingOn,
  setFormErrors,
  setSavingOn,
  setValidatingOn
}) => {
  useEffect(() => {
    if (!validatingOn) { return }
    setFormErrors({});
    const { isValid, errors: formErrors_ } = validateFunction(data);
    if (isValid) {
      setSavingOn(true);
    } else {
      setFormErrors(formErrors_);
    }
    setValidatingOn(false);
  }, [validatingOn]);
}

export const useAddPageTitle = (newTitle) => {
  useEffect(() => {
    document.title = newTitle;
  }, [newTitle]);
}

export const useCleanForcedReadOnlyMode = (setForcedReadOnlyModeAllowed) => {
  // forcedReadOnlyModeAllowed value in context is cleaned if component is destroying,
  // because some views don't need this widget
  useEffect(() => {
    return () => setForcedReadOnlyModeAllowed(false);
  }, []);
};
