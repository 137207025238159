import { useState } from 'react';
import { TextField } from '@mui/material';
import { useParams } from 'react-router-dom';

import { getFieldErrors } from '../../../helpers/helpers';
import { usePostData } from '../../../helpers/hooks';
import { API_URLS } from '../../../urls/backend';
import { BasicForm, FormButtons } from '../../common';

export const AddTagForm = ({
    addCallbackSuccess,
    initialName,
    isEditing,
    onCancel,
    tagId,
}) => {
  const [name, setName] = useState(isEditing ? initialName : '');
  const [savingOn, setSavingOn] = useState(false);
  const [fetchError, setFetchError] = useState(null);
  const [forcedEdit, setForcedEdit] = useState(false);

  const {vehicleId} = useParams();

  function handleSubmit(ev) {
    ev.preventDefault();
    setSavingOn(true);
  }

  const { errors } = usePostData({
    savingOn,
    setSavingOn,
    url: isEditing ? API_URLS.tagsEdit.getUrl(tagId) : API_URLS.tagsAdd.getUrl(vehicleId),
    method: isEditing ? 'PUT' : 'POST',
    postData: { name, ...(forcedEdit ? {force: 1} : {}) },
    callbackSuccess: ({ data, status }) => {
      if (status === 200) {
        addCallbackSuccess(data.tag);
      } else if (status === 400 && data.error) {
        setFetchError(data.error);
        setForcedEdit(true);
        setSavingOn(false);
      }
    },
    callbackError: ({ error }) => setFetchError(error),
  });


  const [isNameInvalid, nameErrors] = getFieldErrors(errors, 'name');
  const [isTagAttachedError, tagAttachedErrors] = getFieldErrors(errors, 'error');

  return (
    <BasicForm formTitle={isEditing ? 'Edit tag' : 'Add tag'}>
      <form onSubmit={handleSubmit}>
        <div>
          <TextField
            autoFocus
            disabled={savingOn}
            error={isNameInvalid}
            fullWidth
            id="nameId"
            inputProps={{ maxLength: 64 }}
            label="Tag name"
            variant="outlined"
            value={name}
            onChange={ev => setName(ev.target.value)}
          />
          {isNameInvalid && <p className="error">{nameErrors}</p>}
          {isTagAttachedError && <p className="error">{tagAttachedErrors}</p>}
        </div>
        <FormButtons
          disabled={savingOn || !name || (isEditing && name === initialName)}
          savingButtonText="Save tag"
          savingOn={savingOn}
          onCancel={onCancel}
          onSubmit={handleSubmit}
        />
        {!!fetchError && <p className="error">{fetchError}</p>}
      </form>
    </BasicForm>
  )
}
