import { validate as validatePhotoForm } from './PhotoForm';

export const validate = (data) => {
  let isValid_ = true;
  const errors_ = {};
  for (let [photoId, photoData] of Object.entries(data)) {
    const { errors, isValid } = validatePhotoForm(photoData);
    isValid_ = isValid_ && isValid;
    errors_[photoId] = errors;
  }
  return {errors: errors_, isValid: isValid_}
};
