import { useEffect, useMemo, useState } from 'react';
import {
    Button,
    TextField,
} from '@mui/material';

import { API_URLS } from '../../../urls/backend';
import { getFieldErrors } from '../../../helpers/helpers';
import { useAddPageTitle, usePostData } from '../../../helpers/hooks';
import { validate } from '../../../validators/ChangePasswordForm';
import { BasicForm, Loader, MainTemplate } from '../../common';

const EMPTY_FORM_DATA = {
    oldPassword: '',
    newPassword1: '',
    newPassword2: '',
};
const TO_SERVER_SERIALIZER = {
    oldPassword: 'old_password',
    newPassword1: 'new_password1',
    newPassword2: 'new_password2',
};
const FROM_SERVER_SERIALIZER = {
    old_password: 'oldPassword',
    new_password1: 'newPassword1',
    new_password2: 'newPassword2',
};

function serializeFormData(data, toServer = true) {
    const serializationDict =
        toServer ? TO_SERVER_SERIALIZER : FROM_SERVER_SERIALIZER;
    const dct = {};
    for (let [attrName, value] of Object.entries(data)) {
        dct[serializationDict[attrName] || attrName] = value;
    }
    return dct
}


export const ChangePasswordForm = () => {
    const [formData, setFormData] = useState({ ...EMPTY_FORM_DATA });
    const [formErrors, setFormErrors] = useState({});
    const [validatingOn, setValidatingOn] = useState(false);
    const [savingOn, setSavingOn] = useState(false);
    const [showSuccessInfo, setShowSuccessInfo] = useState(false);

    useAddPageTitle('Change password | JetVar');

    function handleChangeFormAttribute(attrName, value) {
        setFormData(prevState => ({ ...prevState, [attrName]: value }));
    }

    function handleSubmit(ev) {
        ev.preventDefault();
        setValidatingOn(true);
    }

    useEffect(() => {
        if (!validatingOn) { return }
        setFormErrors({});
        const { errors, isValid } = validate(formData);
        if (isValid) {
            setSavingOn(true);
        } else {
            setFormErrors(errors);
        }
        setValidatingOn(false);
    }, [validatingOn]);

    const { errors, fetchError } = usePostData(({
        savingOn,
        setSavingOn,
        url: API_URLS.accountsChangePassword.path,
        method: 'PUT',
        postData: { ...serializeFormData(formData) },
        callbackSuccess: ({ status }) => {
            if (status !== 400) {
                setShowSuccessInfo(true);
                setFormData({ ...EMPTY_FORM_DATA });
            }
        },
        callbackError: error => setSavingOn(false),
    }));

    useEffect(() => {
        setFormErrors(serializeFormData(errors, false))
    }, [errors]);

    useEffect(() => {
        if (!showSuccessInfo) { return }
        let timeout = setTimeout(() => {
            setShowSuccessInfo(false);
        }, 3000);
        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        }
    }, [showSuccessInfo]);

    const [
        isOldPasswordInvalid, oldPasswordErrors,
        isNewPassword1Invalid, newPassword1Errors,
        isNewPassword2Invalid, newPassword2Errors
    ] = useMemo(() => {
        return [
            ...getFieldErrors(formErrors, 'oldPassword'),
            ...getFieldErrors(formErrors, 'newPassword1'),
            ...getFieldErrors(formErrors, 'newPassword2'),
        ]
    }, [formErrors]);

    const formDisabled = savingOn || validatingOn;

    return (
        <MainTemplate>
            <BasicForm formTitle="Change password">
                <form onSubmit={handleSubmit}>
                    <div>
                        <TextField
                            autoFocus
                            disabled={formDisabled}
                            error={isOldPasswordInvalid}
                            fullWidth
                            id="oldPasswordId"
                            inputProps={{
                                maxLength: 128
                            }}
                            label="Old password"
                            variant="outlined"
                            type="password"
                            value={formData.oldPassword}
                            onChange={ev => handleChangeFormAttribute('oldPassword', ev.target.value)}
                        />
                        {isOldPasswordInvalid && <p className="error">{oldPasswordErrors}</p>}
                    </div>
                    <div>
                        <TextField
                            disabled={formDisabled}
                            error={isNewPassword1Invalid}
                            fullWidth
                            id="newPassword1Id"
                            inputProps={{
                                maxLength: 128,
                            }}
                            label="New password"
                            variant="outlined"
                            type="password"
                            value={formData.newPassword1}
                            onChange={ev => handleChangeFormAttribute('newPassword1', ev.target.value)}
                        />
                        {isNewPassword1Invalid && <p className="error">{newPassword1Errors}</p>}
                    </div>
                    <div>
                        <TextField
                            disabled={formDisabled}
                            error={isNewPassword2Invalid}
                            fullWidth
                            id="newPassword2Id"
                            inputProps={{
                                maxLength: 128,
                            }}
                            label="Repeat new password"
                            variant="outlined"
                            type="password"
                            value={formData.newPassword2}
                            onChange={ev => handleChangeFormAttribute('newPassword2', ev.target.value)}
                        />
                        {isNewPassword2Invalid && <p className="error">{newPassword2Errors}</p>}
                    </div>
                    <div>
                        <Button
                            variant="contained"
                            disabled={formDisabled}
                            fullWidth
                            type="submit"
                            onClick={handleSubmit}
                        >
                            {savingOn ? <Loader isButton /> : 'Change password'}
                        </Button>
                        {showSuccessInfo && <p className="success">Password changed successfully.</p>}
                        {!!fetchError && <p className="error">{fetchError}</p>}
                    </div>
                </form>
            </BasicForm>
        </MainTemplate>
    )
}
