import { useContext, useState } from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LanguageIcon from '@mui/icons-material/Language';

import { ConfiguratorContext } from '../../../context/configuratorContext';
import './Configurator.sass';


export const PanoramaList = ({ panoramas, sets }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const {
    componentsAreFetching,
    selectedPanorama,
    setSelectedPanorama
  } = useContext(ConfiguratorContext);

  return (
    <div className="configurator__dropdown">
      <List component="nav" aria-label="Select panorama">
        <ListItem
          component="button"
          aria-haspopup="true"
          aria-controls="lock-menu"
          sx={{width: 'auto'}}
          onClick={(ev) => setAnchorEl(ev.currentTarget)}
        >
          <ListItemIcon>
            <LanguageIcon color="primary" />
          </ListItemIcon>
          <Typography className="configurator__panorama-name" variant="h4" component="h2">{selectedPanorama.name}</Typography>
          {anchorEl ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {panoramas.map((panorama) => (
          <MenuItem
            key={panorama.id}
            className="configurator__menu-item"
            component="button"
            disabled={panorama.id === selectedPanorama.id || componentsAreFetching}
            selected={panorama.id === selectedPanorama.id}
            onClick={ev => {
              setSelectedPanorama(panorama);
              setAnchorEl(null);
            }}
          >
            {panorama.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
