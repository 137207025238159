import { useContext } from 'react';
import { Chip } from "@mui/material";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

import { VehicleTagsContext } from "../../../context/vehicleTagsContext";
import './PhotoTagList.sass';

export const PhotoTagList = ({
  addedTagIds,
  onAddTag,
  onRemoveTag,
}) => {
  const { vehicleTags } = useContext(VehicleTagsContext);

  const checkIfTagIsAdded = (tagId) => addedTagIds && addedTagIds.filter(id_ => id_ === tagId).length;

  const toggleTagOnList = (tag) => {
    if (checkIfTagIsAdded(tag.id)) {
      onRemoveTag && onRemoveTag(tag);
    } else {
      onAddTag && onAddTag(tag);
    }
  };

  return (
    <ul className="tags-list__list">
      {vehicleTags?.length > 0 ? vehicleTags.sort((s1, s2) => s1.name.localeCompare(s2.name)).map(tag =>
        <li key={tag.id}>
          <Chip
            clickable
            color="primary"
            component="button"
            icon={checkIfTagIsAdded(tag.id) ? <ClearOutlinedIcon /> : <AddOutlinedIcon />}
            label={tag.name}
            size="small"
            variant={checkIfTagIsAdded(tag.id) ? 'contained' : 'outlined'}
            onClick={() => toggleTagOnList(tag)}
          />
        </li>
      ) : <p className="info">There are no tags for this vehicle.</p>}
    </ul>
  );
};
