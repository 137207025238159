import { useContext, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { ConfiguratorContext } from '../../../context/configuratorContext';
import { VehicleTagsProvider } from '../../../context/vehicleTagsContext';
import {
  DEFAULT_PHOTOS_COUNTERS,
  MAX_COMPONENT_PHOTOS_COUNT
} from '../../../helpers/consts';
import { useFetchError } from '../../../helpers/hooks';
import { Dialog, FormButtons, PhotoList, ScrollContainer } from '../../common';


const portalRoot = document.getElementById('portal-root');


export const AddPhotosToComponent = ({
  componentPhotosIds,
  vehicleId,
  onCancel,
  onSave
}) => {
  const [photos, setPhotos] = useState([]);
  const [counters, setCounters] = useState({...DEFAULT_PHOTOS_COUNTERS});
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [fetchingPhotosOn, setFetchingPhotosOn] = useState(false);
  const [savingOn, setSavingOn] = useState(false);
  const [savingError, setSavingError] = useState(null);

  const scrollableElementRef = useRef();

  const { markDialogOpened } = useContext(ConfiguratorContext);

  useEffect(() => {
    markDialogOpened(true);
    return () => markDialogOpened(false);
  }, []);

  function handleSelectPhoto(selectedPhoto, selected) {
    if (selected) {
      setSelectedPhotos(prevVal => [...prevVal, selectedPhoto])
    } else {
      setSelectedPhotos(
        prevVal => prevVal.filter(photo => photo.id !== selectedPhoto.id));
    }
  }

  function handleSubmit(ev) {
    ev.preventDefault();
    setSavingOn(true);
  }

  useEffect(() => {
    if (savingOn) {
      if (selectedPhotos.length > MAX_COMPONENT_PHOTOS_COUNT - componentPhotosIds.length) {
        setSavingError('You selected too many photos.');
        setSavingOn(false);
      } else {
        onSave(selectedPhotos);
      }
    }
  }, [savingOn]);

  useFetchError({
    fetchError: savingError,
    setFetchError: setSavingError,
    timeoutValue: 5000,
  });

  return createPortal(
    <Dialog onCloseDialog={onCancel} scrollableElementRef={scrollableElementRef}>
      <p className="photos__accent-info">{selectedPhotos.length} selected / {MAX_COMPONENT_PHOTOS_COUNT - componentPhotosIds.length} possible to select</p>
      <ScrollContainer>
        <form id="addPhotosToComponent" onSubmit={handleSubmit}>
          <VehicleTagsProvider>
            <PhotoList
              canSelectPhoto={true}
              componentPhotosIds={componentPhotosIds}
              counters={counters}
              photos={photos}
              setCountersFunction={setCounters}
              scrollableElementRef={scrollableElementRef}
              selectedPhotosIds={selectedPhotos.map(photo => photo.id)}
              setPhotosFunction={setPhotos}
              vehicleId={vehicleId}
              onFetchingOnChange={setFetchingPhotosOn}
              onFiltersChange={() => setSelectedPhotos([])}
              onSelectPhoto={handleSelectPhoto}
            />
          </VehicleTagsProvider>
          {savingError && <p className="error">{savingError}</p>}
        </form>
      </ScrollContainer>
      <FormButtons
        className="flex-container"
        disabled={fetchingPhotosOn}
        disabledSavingButton={!selectedPhotos.length}
        idForm="addPhotosToComponent"
        savingButtonText="Apply selected photos"
        savingOn={savingOn}
        onCancel={onCancel}
        onSubmit={handleSubmit}
      />
    </Dialog>,
    portalRoot
  )
}
