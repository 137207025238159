import clsx from 'clsx';

import { THUMBNAILS_SIZES } from '../../../helpers/consts';
import { getThumbnailUrl } from '../../../helpers/helpers';
import plane from '../../../assets/plane.png';
import './VehicleMiniature.sass';

export const VehicleMiniature = ({
  alt,
  image: imageUrl,
  isActiveIcon,
  small,
}) => {
  const miniatureClassname = clsx([
    'vehicle-miniature',
    small && 'vehicle-miniature--small',
  ]);

  return (
    <div className={miniatureClassname}>
      <img
        alt={alt}
        src={imageUrl?.length > 0 ? getThumbnailUrl(imageUrl, THUMBNAILS_SIZES.S) : plane}
      />
      <span className="vehicle-miniature__is-active">
        {isActiveIcon}
      </span>
    </div>
  )
};
