import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, TextField } from '@mui/material';

import { API_URLS } from '../../../urls/backend';
import { getFieldErrors } from '../../../helpers/helpers';
import { useAddPageTitle, usePostData } from '../../../helpers/hooks';
import { UserContext } from '../../../context/userContext';
import { BasicForm, Loader, MainTemplate } from '../../common';


export const LoginForm = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [savingOn, setSavingOn] = useState(false);

    const { setUserData } = useContext(UserContext);
    const [searchParams] = useSearchParams();

    const next = searchParams.get('next') || null;
    const navigate = useNavigate();

    useAddPageTitle('Log in | JetVar');

    function handleSubmit(ev) {
        ev.preventDefault();
        setSavingOn(true);
    }

    function callbackSuccess({status, data}) {
        if (status === 400) {
            setFormErrors(data.errors);
        } else {
            setUserData(data.user);
            navigate(next || '/');
        }
        setSavingOn(false);
    }

    function callbackError({error}) {
        setFormErrors(error);
        setSavingOn(false);
    }

    const { errors, fetchError } = usePostData({
        savingOn,
        setSavingOn,
        url: API_URLS.accountsLogin.path,
        postData: { username, password },
        callbackSuccess,
        callbackError,
    });

    useEffect(() => {
        setFormErrors(errors);
    }, [errors]);

    const [isUsernameInvalid, usernameErrors] = getFieldErrors(
        formErrors, 'username');
    const [isPasswordInvalid, passwordErrors] = getFieldErrors(
        formErrors, 'password');

    return (
        <MainTemplate>
            <BasicForm formTitle="Log in">
                <form onSubmit={handleSubmit}>
                    <div>
                        <TextField
                            autoFocus
                            disabled={savingOn}
                            error={isUsernameInvalid}
                            fullWidth
                            id="usernameId"
                            inputProps={{
                                maxLength: 150,
                            }}
                            label="Username"
                            variant="outlined"
                            value={username}
                            onChange={ev => setUsername(ev.target.value)}
                        />
                        {isUsernameInvalid && <p className="error">{usernameErrors}</p>}
                    </div>
                    <div>
                        <TextField
                            disabled={savingOn}
                            error={isPasswordInvalid}
                            fullWidth
                            id="passwordId"
                            inputProps={{
                                maxLength: 128,
                            }}
                            label="Password"
                            variant="outlined"
                            type="password"
                            value={password}
                            onChange={ev => setPassword(ev.target.value)}
                        />
                        {isPasswordInvalid && <p className="error">{passwordErrors}</p>}
                    </div>
                    <div>
                        <Button
                            variant="contained"
                            disabled={savingOn}
                            fullWidth
                            type="submit"
                            onClick={handleSubmit}
                        >
                            {savingOn ? <Loader isButton /> : 'Log in'}
                        </Button>
                        {!!fetchError && <p className="error">{fetchError}</p>}
                    </div>
                </form>
            </BasicForm>
        </MainTemplate>
    )
}
