import { useEffect, useRef } from 'react';
import { Chip, Typography } from '@mui/material';
import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';

import { THUMBNAILS_SIZES } from '../../../helpers/consts';
import {
  formatDate,
  getThumbnailUrl,
  observeImages
} from '../../../helpers/helpers';
import { PhotoActionButtons } from '..';


export const Photo = ({
  canEdit,
  canSelect,
  initialSelected,
  photo,
  selected,
  onDelete,
  onSave,
  onSelect
}) => {
  const imgRef = useRef();

  useEffect(() => {
    observeImages(imgRef.current);
  }, []);

  return (
    <div className="photos__img-container">
      {canSelect && (initialSelected
        ? <span className="photos__in-component-info">
            <AttachmentOutlinedIcon size="small" />
            <span>In this component</span>
          </span>
        : (
          <div className="checkbox">
            <input
              checked={selected}
              className="checkbox__input"
              id={`photoCheckboxId_${photo.id}`}
              type="checkbox"
              onChange={ev => onSelect(photo, ev.target.checked)}
            />
            <label className="checkbox__label" htmlFor={`photoCheckboxId_${photo.id}`}><span className="checkbox__name">Check photo</span></label>
          </div>
        )
      )}
      <div className="photos__img-wrapper">
        <img
          alt="Added for vehicle"
          className="photos__img"
          src={getThumbnailUrl(photo.imageUrl, THUMBNAILS_SIZES.M)}
        />
      </div>
      <PhotoActionButtons
        canEdit={canEdit}
        photo={photo}
        onDelete={onDelete}
        onSave={onSave}
      />
      <Typography variant="subtitle2">{photo.name}</Typography>
      <Typography variant="body2" className="photos__text">{photo.description || '-'}</Typography>
      <Typography variant="caption" className="photos__text"><CalendarTodayOutlinedIcon fontSize="0.9rem" /> {formatDate(photo.createdAt)}</Typography>
      <ul className="tags-list__list tags-list__list--small">
        {photo.tags?.length > 0 && photo.tags.map(tag => (
          <li key={tag.id}>
            <Chip
              className="photos__tag"
              color="primary"
              label={tag.name}
              size="small"
            />
          </li>
        ))}
      </ul>
    </div>
  )
}
