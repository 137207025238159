import { useState } from 'react';
import { TextField } from '@mui/material';

import { getFieldErrors } from '../../../helpers/helpers';
import { usePostData } from '../../../helpers/hooks';
import { API_URLS } from '../../../urls/backend';
import { BasicForm, FormButtons, ScrollContainer } from '../../common';


export const AddSetForm = ({
  addCallbackSuccess,
  initialName,
  isEditing,
  setId = null,
  systemId,
  onCancel,
}) => {
  const [name, setName] = useState(isEditing ? initialName : '');
  const [savingOn, setSavingOn] = useState(false);

  function handleSubmit(ev) {
    ev.preventDefault();
    setSavingOn(true);
  }

  const { fetchError, errors } = usePostData({
    savingOn,
    setSavingOn,
    url: isEditing ? API_URLS.setsEdit.getUrl(setId) : API_URLS.systemsAddSet.getUrl(systemId),
    method: isEditing ? 'PUT' : 'POST',
    postData: { name },
    callbackSuccess: ({ data }) => addCallbackSuccess(data.set),
  });

  const [isNameInvalid, nameErrors] = getFieldErrors(errors, 'name');

  return (
    <BasicForm formTitle={`${isEditing ? 'Edit' : 'Add'} set of components`}>
      <ScrollContainer>
        <form id="addSetForm" onSubmit={handleSubmit}>
          <div>
            <TextField
              autoFocus
              disabled={savingOn}
              error={isNameInvalid}
              fullWidth
              id="nameId"
              inputProps={{ maxLength: 255 }}
              label="Set name"
              variant="outlined"
              value={name}
              onChange={ev => setName(ev.target.value)}
            />
            {isNameInvalid && <p className="error">{nameErrors}</p>}
          </div>
          {!!fetchError && <p className="error">{fetchError}</p>}
        </form>
      </ScrollContainer>
      <FormButtons
        disabled={savingOn || !name}
        idForm="addSetForm"
        savingButtonText="Save set"
        savingOn={savingOn}
        onCancel={onCancel}
        onSubmit={handleSubmit}
      />
    </BasicForm>
  )
}
