import { checkRequired, Validator } from './base';


const checkOneCapitalLetter = (value) => {
  if (!value) { return '' }
  return /[A-Z]/.test(value)
    ? '' : 'Password should contain at least 1 uppercase letter.'
}

const checkOneLowerLetter = (value) => {
  if (!value) { return '' }
  return /[a-z]/.test(value)
    ? '' : 'Password should contain at least 1 lowercase letter.'
}

const checkOneDigit = (value) => {
  if (!value) { return '' }
  return /[0-9]/.test(value)
    ? '' : 'Password should contain at least 1 digit.'
}

const checkOneSpecialChar = (value) => {
  if (!value) { return '' }
  return /[=!@#$%^&*()_+-]/.test(value)
    ? '' : 'Password should contain at least 1 special char: !@#$%^&*()_+-='
}

export const validate = (data) => {
  let { errors, isValid } = new Validator(
    {
      'oldPassword': [checkRequired,],
      'newPassword1': [checkRequired,],
      'newPassword2': [
        checkRequired, checkOneCapitalLetter, checkOneLowerLetter,
        checkOneDigit, checkOneSpecialChar
      ],
    },
    data
  ).validate()

  const { newPassword1, newPassword2 } = data;
  if (newPassword1 && newPassword2 && newPassword1 !== newPassword2) {
    isValid = false;
    errors.newPassword2.push('The two password fields didn\'t match.');
  }

  return { errors, isValid }
};
