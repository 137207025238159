import {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  CircularProgress,
  IconButton,
  Typography,
} from '@mui/material';
import { DeleteOutlineOutlined } from '@mui/icons-material';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';

import { VehicleTagsContext } from "../../../context/vehicleTagsContext";
import {
  useDeleteData,
  usePostData,
  usePostWithFileData,
  useValidatingForm
} from '../../../helpers/hooks';
import { API_URLS } from '../../../urls/backend';
import { validate } from '../../../validators/AddPhotosForm';
import { Dialog, FormButtons, ImageInput, Loader, ScrollContainer } from '../../common';
import { PhotoTagList } from '../../tags';
import { EditPhotoTemplate } from '..';
import '../Photos.sass';

const MAX_PHOTOS_NUMBER = 20;

export const AddPhotosForm = ({
  addCallbackSuccess,
  vehicleId,
  onClose
}) => {
  const [photosToSave, setPhotosToSave] = useState([]);
  const [savedPhotos, setSavedPhotos] = useState([]);
  const [savingImagesOn, setSavingImagesOn] = useState(false);
  const [photoIdToRemove, setPhotoIdToRemove] = useState(null);
  const [validatingOn, setValidatingOn] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [savingOn, setSavingOn] = useState(false);
  const [fetchError, setFetchError] = useState(null);
  const [groupAddedTagIds, setGroupAddedTagIds] = useState([]);
  const [uploadPhotosMessage, setUploadPhotosMessage] = useState('');

  const { vehicleTags } = useContext(VehicleTagsContext);
  const filesInputRef = useRef();

  const loadedPhotosCount = savedPhotos.filter(photo => !!photo.id).length;
  const photosLeftToSaveCount = MAX_PHOTOS_NUMBER - loadedPhotosCount;

  function handleAddPhotos(ev) {
    let newPhotos = [];
    const inputFiles = filesInputRef?.current?.files;
    const lastTemporaryIndex = Math.max(...savedPhotos.map(photo => photo.temporaryId), 0);
    for (let index of [...Array(inputFiles.length).keys()].slice(0, photosLeftToSaveCount)) {
      newPhotos.push({
        file: inputFiles[index],
        temporaryId: lastTemporaryIndex + parseInt(index) + 1,
      });
    }
    if (inputFiles.length > photosLeftToSaveCount) {
      setUploadPhotosMessage(`You was trying to upload more than ${photosLeftToSaveCount} photos. Your number of photos has been cropped to ${photosLeftToSaveCount}`);
    };
    setPhotosToSave(newPhotos);
  }

  useEffect(() => {
    if (!photosToSave.length) { return }
    // try to save the first photo from the list
    setSavingImagesOn(true);
  }, [photosToSave]);

  usePostWithFileData({
    url: API_URLS.photosAdd.getUrl(),
    image: photosToSave[0]?.file || null,
    body: { vehicle_id: vehicleId, name: photosToSave[0]?.file?.name },
    savingOn: savingImagesOn,
    setSavingOn: setSavingImagesOn,
    callbackSuccess: ({data, status}) => {
      const photo = photosToSave[0];
      if (status === 400) {
        setSavedPhotos(prevVal => [
          ...prevVal,
          {
            temporaryId: photo.temporaryId,
            name: photo.file.name,
            error: Object.values(data.errors).map(errors => errors.join(' ')).join(' '),
          }
        ]);
      } else {
        setSavedPhotos(prevVal => [
          ...prevVal,
          {...data.photo, temporaryId: photo.temporaryId}
        ]);
      }
      setPhotosToSave(prevVal => prevVal.length ? prevVal.slice(1) : []);
      setSavingImagesOn(false);
    },
    callbackError: ({error}) => {
      const photo = photosToSave[0];
      setSavedPhotos(prevVal => [
        ...prevVal,
        {name: photo.file.name, error, temporaryId: photo.temporaryId}
      ]);
      setPhotosToSave(prevVal => prevVal.length ? prevVal.slice(1) : []);
      setSavingImagesOn(false);
    }
  });

  function handleRemoveErrorPhoto(temporaryId) {
    // it is only removing from the list
    setSavedPhotos(prevVal => prevVal.filter(
      photo => photo.temporaryId !== temporaryId));
  }

  function handleRemovePhoto(photoId) {
    setSavedPhotos(prevVal => prevVal.filter(photo => photo.id !== photoId));
    setPhotoIdToRemove(null);
  }

  useDeleteData({
    removingOn: photoIdToRemove !== null,
    url: API_URLS.photosRemove.getUrl(photoIdToRemove),
    callbackSuccess: () => handleRemovePhoto(photoIdToRemove),
    // yes, this looks odd, but if something goes wrong, temporary photo will be removed automatically
    callbackError: () => handleRemovePhoto(photoIdToRemove),
  });

  function handleChangePhotoAttribute(photoId, name, value) {
    setSavedPhotos(prevVal => prevVal.map(photo => {
      if (photo.id === photoId) {
        photo[name] = value;
      }
      return photo
    }));
  }
  
  function handleSubmit(ev) {
    ev.preventDefault();
    setValidatingOn(true);
  }

  const formData = useMemo(() => {
    const formData_ = {};
    for (let photoData of savedPhotos) {
      if (photoData.id) {
        formData_[photoData.id] =
          {
            name: photoData.name,
            description: photoData.description,
            tags: photoData.tags,
          };
      }
    }
    return formData_
  }, [savedPhotos]);

  useEffect(() => {
    if (!validatingOn) { return }
    setFetchError(null);
  }, [validatingOn]);

  useValidatingForm({
    data: formData,
    validateFunction: validate,
    validatingOn,
    setFormErrors,
    setSavingOn,
    setValidatingOn,
  });

  const { errors } = usePostData({
    url: API_URLS.photosSavePhotos.getUrl(vehicleId),
    method: 'PUT',
    postData: { photos: formData },
    savingOn,
    setSavingOn,
    callbackSuccess: ({ data }) =>
      addCallbackSuccess(data.photos),
    callbackError: ({ error }) => setFetchError(error),
  });

  useEffect(() => {
    if (!Object.keys(errors).length) { return }
    setFormErrors(errors);
  }, [errors]);

  const disabled = photosToSave.length > 0 || validatingOn || savingOn ||
    photoIdToRemove !== null;

  return (
    <>
      <div className="flex-container--between">
        <Typography color="primary" variant="subtitle2">Loaded photos: {loadedPhotosCount}/{MAX_PHOTOS_NUMBER}</Typography>
        <p className="info">
          {photosToSave.length > 0 && <CircularProgress size=".8rem" sx={{mr: '.5rem'}}/>}
          Loading photos: {photosToSave.length}
        </p>
      </div>
      <form id="addPhotosForm" onSubmit={handleSubmit}>
        <ScrollContainer>
          <div className="basic-form__container">
            {!photosToSave.length && photosLeftToSaveCount > 0 &&
              <ImageInput
                disabled={disabled}
                multiple
                refElement={filesInputRef}
                uploadPhotosNumber={photosLeftToSaveCount}
                onAddFile={handleAddPhotos}
              />
            }
            {vehicleTags.length > 0 && loadedPhotosCount > 1 && (
              <>
                <h3>Tag group of photos ("Group tagging enabled" checked)</h3>
                <PhotoTagList
                  addedTagIds={groupAddedTagIds}
                  onAddTag={(tag) => setGroupAddedTagIds([...groupAddedTagIds, tag.id])}
                  onRemoveTag={(tag) => setGroupAddedTagIds(groupAddedTagIds.filter(id_ => id_ !== tag.id))}
                />
              </>
            )}
            <div className="photos--form">
              {savedPhotos.map(photo =>
                <div key={photo.temporaryId} className="photos__img-container">
                  {photo.error
                    ? (
                      <>
                        <IconButton
                          className="photos__remove-btn"
                          size="small"
                          onClick={() => handleRemoveErrorPhoto(photo.temporaryId)}
                        ><DeleteOutlineOutlined /></IconButton>
                        <p>{photo.name}</p>
                        <p className="error">{photo.error}</p>
                      </>
                    ) : (
                      <>
                        <IconButton
                          className="photos__remove-btn"
                          size="small"
                          onClick={() => setPhotoIdToRemove(photo.id)}
                        >
                          <DeleteOutlineOutlined />
                        </IconButton>
                        <EditPhotoTemplate
                          disabled={disabled}
                          formErrors={formErrors[photo.id] || {}}
                          showGroupTagging={vehicleTags.length > 0 && loadedPhotosCount > 1}
                          groupAddedTagIds={groupAddedTagIds}
                          photoData={photo}
                          onChangeAttribute={handleChangePhotoAttribute}
                        />
                      </>
                    )
                  }
                </div>
              )}
              {photosToSave.map(photo =>
                <div key={photo.temporaryId} className="photos__loading">
                  <AddPhotoAlternateOutlinedIcon />
                  <p>{photo.name}</p>
                  <Loader />
                </div>
              )}
            </div>
          </div>
          {!!fetchError && <p className="error">{fetchError}</p>}
        </ScrollContainer>
      </form>
      <FormButtons
        cancelText="Cancel"
        disabled={disabled || !loadedPhotosCount}
        idForm="addPhotosForm"
        savingButtonText="Save and close"
        savingOn={savingOn}
        onCancel={onClose}
        onSubmit={handleSubmit}
      />
      {uploadPhotosMessage &&
        <Dialog
          confirmationText="Ok"
          onCloseDialog={() => setUploadPhotosMessage('')}
          onConfirm={() => setUploadPhotosMessage('')}
        >
          <ScrollContainer>
            <p>{uploadPhotosMessage}</p>
          </ScrollContainer>
        </Dialog>
      }
    </>
  )
}
