import { Link } from 'react-router-dom';

import { URLS } from '../../../urls/frontend';
import './PageNotFound.sass';

export const PageNotFound = () => (
  <section className="page-not-found">
    <span className="page-not-found__404">404</span>
    <p className="page-not-found__title">Ups... We haven't found anything here...</p>
    <p className="page-not-found__sub-title">Please check the url or go to home page.</p>
    <Link to={URLS.home.path}>Home page</Link>
  </section>
);
