import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  List,
} from "@mui/material";
import { Add } from '@mui/icons-material';

import { PERMISSION } from '../../../helpers/consts';
import { useGetData } from '../../../helpers/hooks';
import { API_URLS } from '../../../urls/backend';
import { URLS } from '../../../urls/frontend';
import { Dialog, Loader } from '../../common';
import { AddSystemForm, System } from '..';
import './SystemList.sass';

export const SystemList = ({
  isActiveVehicle,
  permission,
  systemsGroupId,
}) => {
  const [isAddSystemDialogOpened, setIsAddSystemDialogOpened] = useState(false);
  const [systems, setSystems] = useState([]);
  const navigate = useNavigate();

  const sortedSystems = useMemo(
    () => systems.sort((s1, s2) => s1.code.localeCompare(s2.code)),
    [systems]
  );

  const {data = {}, fetchingOn, fetchError} = useGetData({
    url: API_URLS.systemsGroupsGetDetails.getUrl(systemsGroupId),
  });

  const { vehicleId } = useParams();

  useEffect(() => {
    if (Object.keys(data).length === 0) return;

    setSystems(data.systems);
  }, [data]);

  const handleDeleteItem = (id) => setSystems(systems.filter((s) => s.id !== id));

  return (
    <>
    <div className="systems-group-list--primary">
      {permission === PERMISSION.READ_WRITE &&
        <>
          <Button
            className="systems-group-list__add-btn"
            disabled={!isActiveVehicle}
            startIcon={<Add />}
            sx={{position: 'sticky'}}
            variant="contained"
            onClick={() => setIsAddSystemDialogOpened(true)}
          >
            Add system
          </Button>
        </>
      }

      <h2 className="systems-group-list__title">Systems</h2>
      {fetchingOn
        ? <Loader />
        : fetchError
          ? <p className="error">{fetchError}</p>
          : sortedSystems.length > 0
          ? (
              <List className="systems-group-list__list-container">
                {sortedSystems.map((system) => (
                  <System
                    key={system.id}
                    isActiveVehicle={isActiveVehicle}
                    permission={permission}
                    system={system}
                    vehicleId={vehicleId}
                    onDeleteItem={handleDeleteItem}
                  />
                ))}
              </List>
            ) : <p className="systems-group-list__info">There are no systems yet</p>
      }
      {isAddSystemDialogOpened && <Dialog onCloseDialog={() => setIsAddSystemDialogOpened(false)}>
        <AddSystemForm
          addCallbackSuccess={(data) => navigate(URLS.systemsConfigurator.getUrl(data.id, vehicleId))}
          onCancel={() => setIsAddSystemDialogOpened(false)}
          systemsGroupId={systemsGroupId}
        />
      </Dialog>}
    </div>
    </>
  );
};
