import { useContext, useState } from 'react';

import { ConfiguratorContext } from '../../../context/configuratorContext';
import { UserContext } from '../../../context/userContext';
import { PERMISSION } from '../../../helpers/consts';
import { useDeleteData } from '../../../helpers/hooks';
import { API_URLS } from '../../../urls/backend';
import {
  ConfiguratorDialog,
  ConfiguratorListItem,
  ScrollContainer
} from '../../common';
import { ComponentDetails, ComponentForm } from '..';

export const Component = ({ component }) => {
  const [isRemoveDialogOpened, setIsRemoveDialogOpened] = useState(false);
  const [removingOn, setRemovingOn] = useState(false);

  const {
    removeComponent,
    selectedComponent,
    setSelectedComponent,
    vehicleDetails
  } = useContext(ConfiguratorContext);
  const { current: permission } = useContext(UserContext).vehiclePermission;

  const { fetchError } = useDeleteData({
    removingOn,
    url: API_URLS.componentsRemove.getUrl(component.id),
    callbackSuccess: () => removeComponent(component.id),
    callbackError: () => setRemovingOn(false),
  });

  const isActiveVehicle = vehicleDetails.isActive;

  return (
    <>
      <ConfiguratorListItem
        canEdit={permission === PERMISSION.READ_WRITE}
        disabled={!isActiveVehicle}
        iconColor={component.isVisible ? 'componentVisible' :  'componentNotVisible'}
        isActive={selectedComponent?.id === component.id}
        isActiveVehicle={isActiveVehicle}
        isExpandable
        isExpanded={selectedComponent && selectedComponent.id === component.id}
        isRemoveDialogOpened={isRemoveDialogOpened}
        name={component.name}
        removeDialog={(
          <ConfiguratorDialog
            confirmationText="Remove"
            dialogTitle="Remove component"
            onCancel={() => setIsRemoveDialogOpened(false)}
            onCloseDialog={() => setIsRemoveDialogOpened(false)}
            onConfirm={() => setRemovingOn(true)}
          >
            <ScrollContainer>
              <p>Are you sure you want to remove this component: {component.name}?</p>
              {fetchError !== null && <p className="error">{fetchError}</p>}
            </ScrollContainer>
          </ConfiguratorDialog>
        )}
        onListItemClick={() => selectedComponent && selectedComponent.id === component.id ? setSelectedComponent(null) : setSelectedComponent(
          { ...component, clickFromList: true })
        }
        onRemoveClick={ev => {
          ev.stopPropagation();
          setIsRemoveDialogOpened(true);
        }}
      />
      {selectedComponent && selectedComponent.id === component.id &&
        <>
          {permission === PERMISSION.READ_WRITE
            ? <ComponentForm key={selectedComponent.id} />
            : <ComponentDetails key={selectedComponent.id} />
          }
        </>
      }
    </>
  )
}
