import './BasicForm.sass';

export const BasicForm = ({ className, children, formTitle }) => {
  return (
    <div className={`basic-form ${className || ''}`}>
      {formTitle && <h2>{formTitle}</h2>}
      {children}
    </div>
  );
}
