import { useContext, useState } from 'react';

import { ConfiguratorContext } from '../../../context/configuratorContext';
import { UserContext } from '../../../context/userContext';
import { PERMISSION } from '../../../helpers/consts';
import { useDeleteData } from '../../../helpers/hooks';
import { API_URLS } from '../../../urls/backend';
import {
  ConfiguratorDialog,
  ConfiguratorListItem,
  ScrollContainer
} from '../../common';
import { AddSetForm } from '..';

export const Set = ({
  disabled,
  set,
  systemId
}) => {
  const [isEditDialogOpened, setIsEditDialogOpened] = useState(false);
  const [isRemoveDialogOpened, setIsRemoveDialogOpened] = useState(false);
  const [removingOn, setRemovingOn] = useState(false);

  const {
    editSet,
    removeSet,
    selectedSet,
    setSelectedSet,
    vehicleDetails
  } = useContext(ConfiguratorContext);
  const { current: permission } = useContext(UserContext).vehiclePermission;

  const { fetchError } = useDeleteData({
    removingOn,
    url: API_URLS.setsRemove.getUrl(set.id),
    callbackSuccess: () => {
      if (selectedSet?.id === set.id) {
        setSelectedSet(null);
      }
      removeSet(set.id);
    },
    callbackError: () => setRemovingOn(false),
  });

  const isActiveVehicle = vehicleDetails.isActive;

  return (
    <ConfiguratorListItem
      canEdit={permission === PERMISSION.READ_WRITE}
      disabled={disabled}
      editDialog={(
        <ConfiguratorDialog onCloseDialog={() => setIsEditDialogOpened(false)}>
          <AddSetForm
            addCallbackSuccess={(data) => {
              editSet(data);
              setIsEditDialogOpened(false);
            }}
            initialName={set.name}
            isEditing
            setId={set.id}
            systemId={systemId}
            onCancel={() => setIsEditDialogOpened(false)}
          />
        </ConfiguratorDialog>
      )}
      isActive={selectedSet?.id === set.id}
      isActiveVehicle={isActiveVehicle}
      isEditDialogOpened={isEditDialogOpened}
      isRemoveDialogOpened={isRemoveDialogOpened}
      name={set.name}
      removeDialog={(
        <ConfiguratorDialog
          confirmationText="Remove"
          dialogTitle="Remove set of components"
          onCancel={() => setIsRemoveDialogOpened(false)}
          onCloseDialog={() => setIsRemoveDialogOpened(false)}
          onConfirm={() => setRemovingOn(true)}
        >
          <ScrollContainer>
            <p>Are you sure you want to remove this set of components: {set.name}?</p>
            {fetchError !== null && <p className="error">{fetchError}</p>}
          </ScrollContainer>
        </ConfiguratorDialog>
      )}
      onEditClick={ev => {
        ev.stopPropagation();
        setIsEditDialogOpened(true);
      }}
      onRemoveClick={ev => {
        ev.stopPropagation();
        setIsRemoveDialogOpened(true);
      }}
      onListItemClick={() => setSelectedSet(set)}
    />
  );
};
