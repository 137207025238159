import { useEffect, useMemo, useState } from 'react';
import { Add, DeleteOutlineOutlined } from '@mui/icons-material';
import {
  Avatar,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import SyncIcon from '@mui/icons-material/Sync';
import TodayIcon from '@mui/icons-material/Today';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

import { formatDate } from '../../../helpers/helpers';
import {
  useDeleteData,
  useFetchError,
  useGetData,
  usePostData
} from '../../../helpers/hooks';
import { API_URLS } from '../../../urls/backend';
import { Dialog, Loader, ScrollContainer } from '../../common';
import { VRPackageLink } from './VRPackageLink';
import './VRPackages.sass';


const VR_PACKAGES_VALIDITY_TIME = 2;  // in hours


const ConfirmationDialog = ({
  disabled,
  error,
  identifier,
  verbLabel,
  onCancel,
  onConfirm
}) => {
  return (
    <Dialog
      confirmationText={verbLabel}
      dialogTitle={`${verbLabel} VR package`}
      disabled={disabled}
      onCancel={onCancel}
      onCloseDialog={onCancel}
      onConfirm={onConfirm}
    >
      <ScrollContainer>
        <p>Are you sure you want to {verbLabel} VR package: {identifier}?</p>
        {error && <p className="error">{error}</p>}
      </ScrollContainer>
    </Dialog>
  )
}


export const VRPackages = ({ configurationId }) => {
  const [vrPackages, setVrPackages] = useState([]);
  const [requestId, setRequestId] = useState(1);
  const [creatingOn, setCreatingOn] = useState(false);
  const [creatingError, setCreatingError] = useState(null);
  const [removingIdentifier, setRemovingIdentifier] = useState(null);
  const [removingOn, setRemovingOn] = useState(false);
  const [renewingIdentifier, setRenewingIdentifier] = useState(null);
  const [renewingOn, setRenewingOn] = useState(false);
  const [renewingError, setRenewingError] = useState(null);
  const [
    closingToThePublicIdentifier, setClosingToThePublicIdentifier
  ] = useState(null);
  const [closingToThePublicOn, setClosingToThePublicOn] = useState(false);
  const [closingToThePublicError, setClosingToThePublicError] = useState(null);
  const [
    identifierToRemoveIfCreating, setIdentifierToRemoveIfCreating
  ] = useState('0');

  const { fetchingOn, fetchError } = useGetData({
    url: API_URLS.configurationsVRPackages.getUrl(configurationId),
    requestId,
    callbackSuccess: ({data}) => setVrPackages(data.vrPackages),
  });

  usePostData({
    url: API_URLS.configurationsCreateVRPackage.getUrl(configurationId),
    postData: {package_to_remove: identifierToRemoveIfCreating !== '0'
        ? identifierToRemoveIfCreating : null
    },
    savingOn: creatingOn,
    setSavingOn: setCreatingOn,
    callbackSuccess: ({data}) => {
      if (data.message) {
        setCreatingError(data.message);
      } else if (data.vrPackages) {
        setVrPackages(data.vrPackages);
      }
    },
    callbackError: ({error}) => setCreatingError(error),
  });

  useFetchError({
    fetchError: creatingError,
    setFetchError: setCreatingError,
    timeoutValue: 10000
  });

  const { fetchError: removingError } = useDeleteData({
    removingOn,
    url: API_URLS.configurationsRemoveVRPackage.getUrl(
      configurationId, removingIdentifier),
    callbackSuccess: ({data}) => {
      setVrPackages(data.vrPackages);
      setRemovingIdentifier(null);
      setRemovingOn(false);
    },
    callbackError: () => setRemovingOn(false),
  });

  usePostData({
    url: API_URLS.configurationsRenewVRPackage.getUrl(
      configurationId, renewingIdentifier),
    method: 'PUT',
    savingOn: renewingOn,
    setSavingOn: setRenewingOn,
    callbackSuccess: ({data}) => {
      setVrPackages(data.vrPackages);
      setRenewingIdentifier(null);
      setRenewingOn(false);
    },
    callbackError: ({error}) => setRenewingError(error),
  });

  useFetchError({
    fetchError: renewingError,
    setFetchError: setRenewingError,
    timeoutValue: 5000
  });

  usePostData({
    url: API_URLS.configurationsCloseToThePublicVRPackage.getUrl(
      configurationId, closingToThePublicIdentifier),
    method: 'PUT',
    savingOn: closingToThePublicOn,
    setSavingOn: setClosingToThePublicOn,
    callbackSuccess: ({data}) => {
      setVrPackages(data.vrPackages);
      setClosingToThePublicIdentifier(null);
      setClosingToThePublicOn(false);
    },
    callbackError: ({error}) => setClosingToThePublicError(error),
  });

  useFetchError({
    fetchError: closingToThePublicError,
    setFetchError: setClosingToThePublicError,
    timeoutValue: 5000,
  });

  useEffect(() => {
    // clean identifier for removing select if creating a package
    setIdentifierToRemoveIfCreating('0');
  }, [vrPackages]);

  const sortedVRPackages = useMemo(() => {
    return vrPackages.sort((p1, p2) =>
      new Date(p2.requestedAt).getTime() - new Date(p1.requestedAt).getTime()
    )
  }, [vrPackages]);

  const [creatingPackages, identifiersToRemoveIfCreating] = useMemo(() => {
    return [
      sortedVRPackages.filter(vrPackage => !vrPackage.createdAt),
      sortedVRPackages.filter(vrPackage => !!vrPackage.createdAt)
        .map(vrPackage => vrPackage.identifier),
    ]
  }, [sortedVRPackages]);

  const disabled = creatingOn || removingOn || renewingOn;

  return (
    <section className="vr-package">
      <h2>VR packages</h2>
      {fetchingOn
        ? <Loader />
        : fetchError
          ? <p className="error">{fetchError}</p>
          : (
            <>
              <div className="flex-container--between">
                <Button
                  disabled={disabled}
                  variant="outlined"
                  startIcon={<SyncIcon />}
                  color="success"
                  onClick={() => setRequestId(prevVal => prevVal + 1)}
                >
                  Refresh VR packages data
                </Button>
                {/* It is possible to create a package only if no one is creating right now */}
                {!creatingPackages.length &&
                  <div className="vr-package__actions">
                    {vrPackages.length >= 3 && identifiersToRemoveIfCreating.length > 0 &&
                      <>
                        <span className="info vr-package__full-info">You can have only 3 packages. If you want to create new one, please select one of created packages to remove and then create new package.</span>
                        <select
                          value={identifierToRemoveIfCreating}
                          onChange={e => setIdentifierToRemoveIfCreating(e.target.value)}
                        >
                          <option key="0" value="0">--- Select ---</option>
                          {identifiersToRemoveIfCreating.map(identifier =>
                            <option key={identifier} value={identifier}>
                              {identifier}
                            </option>
                          )}
                        </select>
                      </>
                    }
                    <Button
                      disabled={disabled || (
                        vrPackages.length >= 3 &&
                        identifiersToRemoveIfCreating.length > 0 &&
                        identifierToRemoveIfCreating === '0'
                      )}
                      startIcon={<Add />}
                      variant="contained"
                      onClick={() => setCreatingOn(true)}
                    >
                      {creatingOn ? <Loader isButton /> : 'Create VR package'}
                    </Button>
                  </div>
                }
              </div>
              {creatingError && <p className="error">{creatingError}</p>}
              {sortedVRPackages.length === 0
                ? <p className="info">There are no VR packages yet</p>
                : (
                  <List>
                    {sortedVRPackages.map(vrPackage =>
                      <ListItem key={vrPackage.identifier} className="vr-package__list-item">
                        <ListItemAvatar>
                          <Avatar>
                            <ViewInArIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <span className="flex-container">
                              <span>{vrPackage.identifier}</span>
                              {!vrPackage.createdAt && <span className="error"> Package hasn't been created yet</span>}
                            </span>
                          }
                          secondary={
                            <>
                              <span>
                                <Tooltip title="Requested at">
                                  <TodayIcon className="vr-package__icon" />
                                </Tooltip>
                                {formatDate(vrPackage.requestedAt)}
                              </span>
                              {vrPackage.createdAt && (
                                <>
                                  <span>
                                    <Tooltip title="Created at">
                                      <EventAvailableIcon className="vr-package__icon" />
                                    </Tooltip>
                                    {formatDate(vrPackage.createdAt)}
                                  </span>
                                  <span>
                                    <Tooltip title="Size">
                                      <DocumentScannerOutlinedIcon className="vr-package__icon" />
                                    </Tooltip>
                                    {`${(vrPackage.zip.size / 1048576).toFixed(1)} MB`}
                                  </span>
                                  <span>
                                    <Tooltip title="Valid to">
                                      <AccessTimeIcon className="vr-package__icon" />
                                    </Tooltip>
                                    {vrPackage.validTo ? formatDate(vrPackage.validTo) : 'not available for downloading'}
                                  </span>
                                </>
                              )}
                            </>
                          }
                        />

                        <div className="vr-package__btn-container flex-container">
                          {(
                            vrPackage.createdAt ||
                            (new Date(vrPackage.requestedAt).getTime() + VR_PACKAGES_VALIDITY_TIME * 3600000) < new Date().getTime()
                          ) &&
                            <>
                              {!vrPackage.createdAt &&
                              <span className="error">The package is creating longer than 2 hours!</span>}
                              <Tooltip title="Delete package">
                                <IconButton
                                  aria-label="Delete"
                                  disabled={disabled}
                                  size="small"
                                  variant="outlined"
                                  onClick={() => setRemovingIdentifier(vrPackage.identifier)}
                                >
                                  <DeleteOutlineOutlined fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </>
                          }
                          {vrPackage.createdAt &&
                            <>
                              <Tooltip title={`Renew for ${VR_PACKAGES_VALIDITY_TIME} hours`}>
                                <IconButton
                                  aria-label={`Renew for ${VR_PACKAGES_VALIDITY_TIME} hours`}
                                  disabled={disabled}
                                  size="small"
                                  variant="outlined"
                                  onClick={() => setRenewingIdentifier(vrPackage.identifier)}
                                >
                                  <MoreTimeIcon />
                                </IconButton>
                              </Tooltip>
                              {vrPackage.validTo &&
                                (new Date(vrPackage.validTo).getTime() + VR_PACKAGES_VALIDITY_TIME * 3600000) > new Date().getTime()
                                ? (
                                  <Tooltip title="Close to the public">
                                    <IconButton
                                      aria-label="Close to the public"
                                      disabled={disabled}
                                      size="small"
                                      variant="outlined"
                                      onClick={() => setClosingToThePublicIdentifier(vrPackage.identifier)}
                                    >
                                      <LockOutlinedIcon />
                                    </IconButton>
                                  </Tooltip>
                                ) : <Tooltip title="Closed to the public">
                                  <VisibilityOffOutlinedIcon size="small" color="error" />
                                </Tooltip>
                              }
                              <VRPackageLink packageName={vrPackage.identifier} url={vrPackage.zip.url} />
                            </>
                          }
                        </div>
                      </ListItem>
                    )}
                  </List>
                )
              }
              {removingIdentifier &&
                <ConfirmationDialog
                  disabled={removingOn}
                  error={removingError}
                  identifier={removingIdentifier}
                  verbLabel="Remove"
                  onCancel={() => setRemovingIdentifier(null)}
                  onConfirm={() => setRemovingOn(true)}
                />
              }
              {renewingIdentifier &&
                <ConfirmationDialog
                  disabled={renewingOn}
                  error={renewingError}
                  identifier={renewingIdentifier}
                  verbLabel="Renew"
                  onCancel={() => setRenewingIdentifier(null)}
                  onConfirm={() => setRenewingOn(true)}
                />
              }
              {closingToThePublicIdentifier &&
                <ConfirmationDialog
                  disabled={closingToThePublicOn}
                  error={closingToThePublicError}
                  identifier={closingToThePublicIdentifier}
                  verbLabel="Close to the public"
                  onCancel={() => setClosingToThePublicIdentifier(null)}
                  onConfirm={() => setClosingToThePublicOn(true)}
                />
              }
            </>
          )
      }
    </section>
  )
}
