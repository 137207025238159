import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

import { UserContext } from '../../../context/userContext';
import { VehicleTagsProvider } from '../../../context/vehicleTagsContext';
import { DEFAULT_PHOTOS_COUNTERS } from '../../../helpers/consts';
import { getVehicleFullName } from '../../../helpers/helpers';
import { useCleanForcedReadOnlyMode } from '../../../helpers/hooks';
import { URLS } from '../../../urls/frontend';
import { Breadcrumbs, Dialog, MainTemplate, PhotoList } from '../../common';
import { AddPhotosForm } from '..';


export const Photos = () => {
  const [isAddPhotosDialogOpened, setIsAddPhotosDialogOpened] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [counters, setCounters] = useState({...DEFAULT_PHOTOS_COUNTERS});
  const [vehicleDetails, setVehicleDetails] = useState({});
  const { vehicleId } = useParams();

  const {
    setForcedReadOnlyModeAllowed,
    setVehiclePermission,
    user
  } = useContext(UserContext);

  useCleanForcedReadOnlyMode(setForcedReadOnlyModeAllowed);

  function handleAddCallbackSuccess(photoList) {
    setPhotos(prevVal => [...photoList.reverse(), ...prevVal]);
    setCounters(prevVal => ({
      ...prevVal,
      all: prevVal.all + photoList.length,
      unused: prevVal.unused + photoList.length,
    }));
    setIsAddPhotosDialogOpened(false);
  }

  function handleDeletePhoto(photoId) {
    setPhotos(prevVal => prevVal.filter(photo => photo.id !== photoId));
    setCounters(prevVal => ({
      ...prevVal,
      all: prevVal.all - 1,
      unused: prevVal.unused - 1,
    }));
  }

  function handleSavePhoto(photoData) {
    setPhotos(prevVal =>
      prevVal.map(photo => photo.id === photoData.id ? photoData : photo));
  }

  return (
    <MainTemplate>
      <Breadcrumbs
        breadcrumbs={[
          [URLS.home.path, 'My aircrafts'],
          [URLS.vehiclesGetDetails.getUrl(vehicleDetails.id), getVehicleFullName(vehicleDetails)],
        ]}
        currentLocation="Photos 2D"
      />
      <VehicleTagsProvider>
        <PhotoList
          addButtonDisabled={!vehicleDetails.isActive}
          canEditPhoto={user.isAdmin && vehicleDetails.isActive}
          counters={counters}
          photos={photos}
          setCountersFunction={setCounters}
          setPhotosFunction={setPhotos}
          withAddButton={user.isAdmin}
          vehicleId={vehicleId}
          onAddPhotosClick={() => setIsAddPhotosDialogOpened(true)}
          onDeletePhoto={handleDeletePhoto}
          onLoadPhotos={data => {
            !Object.keys(vehicleDetails).length && setVehicleDetails(data.vehicle);
            !!data.permission && setVehiclePermission(vehicleId, data.permission);
          }}
          onSavePhoto={handleSavePhoto}
        />
        {isAddPhotosDialogOpened &&
          <Dialog onCloseDialog={() => setIsAddPhotosDialogOpened(false)}>
            <AddPhotosForm
              addCallbackSuccess={handleAddCallbackSuccess}
              vehicleId={vehicleId}
              onClose={() => setIsAddPhotosDialogOpened(false)}
            />
          </Dialog>
        }
      </VehicleTagsProvider>
    </MainTemplate>
  )
}
