import {useContext, useState} from 'react';
import { Button, List} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';

import { ConfiguratorContext } from '../../../context/configuratorContext';
import { MAX_COMPONENT_PHOTOS_COUNT } from '../../../helpers/consts';
import { ConfiguratorListItem } from '../../common';
import { AddPhotosToComponent, PhotoPreview } from '..';


export const ComponentPhotoList = ({
  canEdit,
  disabled,
  photos,
  onAddPhotos,
  onRemovePhoto
}) => {
  const [isAddDialogOpened, setIsAddDialogOpened] = useState(false);
  const [previewedPhoto, setPreviewedPhoto] = useState(null);
  const { vehicleDetails } = useContext(ConfiguratorContext);

  function handleAddSelectedPhotos(selectedPhotos) {
    setIsAddDialogOpened(false);
    onAddPhotos(selectedPhotos);
  }

  return (
    <>
      <div>
        {canEdit && photos.length < MAX_COMPONENT_PHOTOS_COUNT &&
          <div className="flex-container--end">
            <Button
              disabled={disabled}
              size="small"
              startIcon={<AddIcon />}
              onClick={() => setIsAddDialogOpened(true)}
            >
              Add photos
            </Button>
          </div>
        }
        <h2>Detailed photos</h2>
        {photos.length > 0
          ? (
            <List>
              {photos.map(photo =>
                <ConfiguratorListItem
                  canEdit={canEdit}
                  disabled={disabled}
                  icon={<ImageOutlinedIcon fontSize="1rem" />}
                  isActiveVehicle={vehicleDetails.isActive}
                  key={photo.id}
                  name={photo.name}
                  onListItemClick={() => setPreviewedPhoto(photo)}
                  onRemoveClick={ev => {
                    ev.stopPropagation();
                    onRemovePhoto(photo.id);
                  }}
                />
              )}
            </List>
          )
          : <p className="info">There are no photos yet</p>
        }
      </div>
      {isAddDialogOpened && canEdit &&
        <AddPhotosToComponent
          componentPhotosIds={photos.map(photo => photo.id)}
          vehicleId={vehicleDetails.id}
          onCancel={() => setIsAddDialogOpened(false)}
          onSave={handleAddSelectedPhotos}
        />
      }
      {previewedPhoto &&
        <PhotoPreview photo={previewedPhoto} onClose={() => setPreviewedPhoto(null)}/>}
    </>
  )
}
