import { useEffect, useMemo, useRef, useState } from 'react';
import {
    FormControlLabel,
    Switch,
    TextField,
} from '@mui/material';

import {getFieldErrors} from '../../../helpers/helpers';
import { usePostWithFileData } from '../../../helpers/hooks';
import { API_URLS } from '../../../urls/backend';
import { checkRequired, Validator } from '../../../validators/base';
import { BasicForm, FormButtons, ScrollContainer } from '../../common';
import './AddVehicleForm.sass';

const EMPTY_FORM_DATA = {
    imageUrl: '',
    description: '',
    isActive: true,
    model: '',
    producer: '',
};

export const AddVehicleForm = ({
    addCallbackSuccess,
    imageUrl,
    initialData,
    isEditing,
    onCancel,
    vehicleId,
}) => {
    const [formData, setFormData] = useState(isEditing ? {...initialData} : { ...EMPTY_FORM_DATA });
    const [formErrors, setFormErrors] = useState({});
    const [validatingOn, setValidatingOn] = useState(false);
    const [savingOn, setSavingOn] = useState(false);
    const [imageName, setImageName] = useState(null);

    const fileInputRef = useRef();

    function handleChangeFormAttribute(attrName, value) {
        setFormData(prevState => ({ ...prevState, [attrName]: value }));
    }

    function validate(data) {
        return new Validator(
            {
                'model': [checkRequired],
                'producer': [checkRequired],
                'description': [checkRequired],
            },
            data
        ).validate();
    };

    function handleSubmit(ev) {
        ev.preventDefault();
        setValidatingOn(true);
    }

    function changeObjKey(obj, oldKey, newKey) {
        obj[newKey] = obj[oldKey];
        delete obj[oldKey];
        return obj;
    };

    const onChangeFile = (ev) => {
        const file = ev.target.files[0];
        if (file) setImageName(file.name);
    }

    let imgFile = null;
    if (fileInputRef.current?.files.length) {
        imgFile = fileInputRef.current.files[0];
    }

    useEffect(() => {
        if (!validatingOn) { return }
        setFormErrors({});
        const { errors, isValid } = validate(formData);
        if (isValid) {
            setSavingOn(true);
        } else {
            setFormErrors(errors);
        }
        setValidatingOn(false);
    }, [validatingOn]);

    const { errors, fetchError } = usePostWithFileData({
        savingOn,
        setSavingOn,
        url: isEditing ? API_URLS.vehiclesEdit.path : API_URLS.vehiclesAdd.path,
        image: imgFile || imageUrl,
        body: isEditing ? { ...changeObjKey({ ...formData }, 'isActive', 'is_active'), vehicle_id: vehicleId } : changeObjKey({ ...formData }, 'isActive', 'is_active'),
        callbackSuccess: ({ data, status }) => {
          status === 200 && addCallbackSuccess(data.vehicle);
        },
        callbackError: () => setSavingOn(false),
    });

    useEffect(() => {
        setFormErrors(errors);
    }, [errors]);

    const [
        isModelInvalid, modelErrors,
        isProducerInvalid, producerErrors,
        isDescriptionInvalid, descriptionErrors,
        isIsActiveInvalid, isActiveErrors,
        isImageInvalid, imageErrors,
    ] = useMemo(() => {
        return [
            ...getFieldErrors(formErrors, 'model'),
            ...getFieldErrors(formErrors, 'producer'),
            ...getFieldErrors(formErrors, 'description'),
            ...getFieldErrors(formErrors, 'isActive'),
            ...getFieldErrors(formErrors, 'image'),
        ]
    }, [formErrors]);

    const formDisabled = savingOn || validatingOn;

    return (
        <BasicForm formTitle={isEditing ? 'Edit vehicle' : 'Add vehicle'}>
            <ScrollContainer>
                <form id="addVehicleForm" onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="photoFileId" className="label label--file">
                            Representative image
                        </label>
                        <input
                            accept="image/*"
                            disabled={savingOn}
                            id="photoFileId"
                            ref={fileInputRef}
                            label="Vehicle image"
                            type="file"
                            onChange={onChangeFile}
                        />
                    </div>
                    {imageName && <p className="success">Choosen image: {imageName}</p>}
                    {!!isImageInvalid && <p className="error">{imageErrors}</p>}
                    <div>
                        <TextField
                            autoFocus
                            disabled={formDisabled}
                            error={isModelInvalid}
                            fullWidth
                            id="modelId"
                            inputProps={{
                                maxLength: 128,
                            }}
                            label="Model"
                            variant="outlined"
                            value={formData.model}
                            onChange={ev => handleChangeFormAttribute('model', ev.target.value)}
                        />
                        {isModelInvalid && <p className="error">{modelErrors}</p>}
                    </div>
                    <div>
                        <TextField
                            disabled={formDisabled}
                            error={isProducerInvalid}
                            fullWidth
                            id="producerId"
                            inputProps={{
                                maxLength: 128,
                            }}
                            label="Producer"
                            variant="outlined"
                            value={formData.producer}
                            onChange={ev => handleChangeFormAttribute('producer', ev.target.value)}
                        />
                        {isProducerInvalid && <p className="error">{producerErrors}</p>}
                    </div>
                    <div>
                        <TextField
                            disabled={formDisabled}
                            error={isDescriptionInvalid}
                            fullWidth
                            id="descriptionId"
                            inputProps={{
                                maxLength: 400,
                            }}
                            label="Description"
                            variant="outlined"
                            value={formData.description}
                            onChange={ev => handleChangeFormAttribute('description', ev.target.value)}
                        />
                        {isDescriptionInvalid && <p className="error">{descriptionErrors}</p>}
                    </div>
                    <div>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={formData.isActive}
                                    disabled={formDisabled}
                                    onChange={ev => handleChangeFormAttribute('isActive', ev.target.checked)}
                                />
                            }
                            label="Active"
                        />
                        {isIsActiveInvalid && <p className="error">{isActiveErrors}</p>}
                    </div>
                    <div>
                        {!!fetchError && <p className="error">{fetchError}</p>}
                    </div>
                </form>
            </ScrollContainer>
            <FormButtons
                disabled={formDisabled}
                idForm="addVehicleForm"
                savingButtonText="Save vehicle"
                savingOn={savingOn}
                onCancel={onCancel}
                onSubmit={handleSubmit}
            />
        </BasicForm>
    )
}
