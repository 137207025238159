export const checkRequired = (value) => {
  if (!value || !value.trim().length) {
    return 'This field is required.'
  }
  return ''
};


export const checkRequiredFile = (value) => {
  return !value ? 'This field is required.' : ''
};


export class Validator {

  constructor(fieldsConfig, formData) {
    /*
      fieldsConfig example needed structure:
      {name: [checkRequired, ]}
    */
    this.fieldsConfig = fieldsConfig;
    this.formData = formData;
    this.isValid = true;
    this.errors = this.initializeErrorsDict();
  }

  initializeErrorsDict() {
    let errors = {};
    for (let fieldName of Object.keys(this.fieldsConfig)) {
      errors[fieldName] = [];
    }
    return errors
  }

  validate() {
    for (let [fieldName, validators] of Object.entries(this.fieldsConfig)) {
      const value = this.formData[fieldName];
      for (let validator of validators) {
        const error = validator(value);
        if (error.length) {
          this.isValid = false;
          this.errors[fieldName].push(error);
        }
      }
    }
    return { errors: this.errors, isValid: this.isValid }
  }
}
