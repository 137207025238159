import Container from '@mui/material/Container';
import clsx from 'clsx';

import { NavBar } from '..';

import './MainTemplate.sass';

export const MainTemplate = ({ children, viewPart }) => {

  const containerClass = clsx([
    'main-container',
    viewPart && 'main-container--view-part',
  ]);

  return (
    <>
      <NavBar />
      <Container maxWidth="lg" className={containerClass}>
       {children}
      </Container>
    </>
  );
};
