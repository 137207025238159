import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';

import { UserContext } from '../../../context/userContext';
import { PERMISSION } from '../../../helpers/consts';
import { getVehicleFullName } from '../../../helpers/helpers';
import {
  useAddPageTitle,
  useCleanForcedReadOnlyMode,
  useDeleteData,
  useGetData
} from '../../../helpers/hooks';
import { API_URLS } from '../../../urls/backend';
import { URLS } from '../../../urls/frontend';
import {
  Breadcrumbs,
  DetailsHeader,
  Dialog,
  Loader,
  MainTemplate,
  ScrollContainer,
  VehicleInfo
} from '../../common';
import { SystemList } from "../../systems";
import { SystemsGroupList } from "../../systemsGroups";
import { AddConfigurationForm, VRPackages } from '..';


export const ConfigurationDetails = () => {
  const [configurationDetails, setConfigurationDetails] = useState({});
  const [fetchedSystemsGroupId, setFetchedSystemsGroupId] = useState(null);
  const [isEditDialogOpened, setIsEditDialogOpened] = useState(false);
  const [isRemoveDialogOpened, setIsRemoveDialogOpened] = useState(false);
  const [removingOn, setRemovingOn] = useState(false);
  const [systemsGroups, setSystemsGroups] = useState([]);
  const [vehicleDetails, setVehicleDetails] = useState({});
  const [isVrPackagesDialogOpen, setIsVrPackagesDialogOpen] = useState(false);

  const navigate = useNavigate();
  const { configurationId, vehicleId } = useParams();

  const {
    setForcedReadOnlyModeAllowed,
    setVehiclePermission,
    vehiclePermission
  } = useContext(UserContext);

  useCleanForcedReadOnlyMode(setForcedReadOnlyModeAllowed);

  const { fetchingOn, fetchError } = useGetData({
    url: API_URLS.configurationsGetDetails.getUrl(configurationId),
    callbackSuccess: ({data}) => {
      if (Object.keys(data).length === 0) return;
      setConfigurationDetails(data.configuration);
      setVehiclePermission(vehicleId, data.permission)
      setVehicleDetails(data.vehicle);
      setSystemsGroups(data.systemsGroups.reverse());
    }
  });

  const { fetchError: removeFetchError } = useDeleteData({
    removingOn,
    url: API_URLS.configurationsRemove.getUrl(configurationId),
    callbackSuccess: () => navigate(URLS.vehiclesGetDetails.getUrl(vehicleId)),
    callbackError: () => setRemovingOn(false),
  });

  useAddPageTitle(`${configurationDetails.name || ''} | ${vehicleDetails.model || ''} | JetVar`);

  const handleDeleteItem = (id) => setSystemsGroups(systemsGroups.filter((group) => group.id !== id));

  const handleGetSystems = (id) => setFetchedSystemsGroupId(id);

  const { current: permission } = vehiclePermission;

  return (
    <MainTemplate>
      <Breadcrumbs
        breadcrumbs={[
          [URLS.home.path, 'My aircrafts'],
          [URLS.vehiclesGetDetails.getUrl(vehicleDetails.id), getVehicleFullName(vehicleDetails)],
        ]}
        currentLocation={configurationDetails.name}
      />
      {fetchingOn
        ? <Loader />
        : (fetchError !== null
          ? <p className="error">{fetchError}</p>
          : (
              <>
                <VehicleInfo
                  isActive={vehicleDetails.isActive}
                  vehicleDetails={vehicleDetails}
                >
                </VehicleInfo>
                <DetailsHeader
                  actionButtons={(
                    <div className="flex-container">
                      {permission === PERMISSION.READ_WRITE && (
                        <>
                          <Button
                            color="secondary"
                            disabled={!vehicleDetails.isActive}
                            startIcon={<DeleteForeverIcon />}
                            variant="outlined"
                            onClick={() => setIsRemoveDialogOpened(true)}
                          >
                            Remove
                          </Button>
                          <Button
                            disabled={!vehicleDetails.isActive}
                            startIcon={<EditIcon />}
                            variant="contained"
                            onClick={() => setIsEditDialogOpened(true)}
                          >
                            Edit
                          </Button>
                        </>
                      )}
                      <Button
                        variant="outlined"
                        onClick={() => setIsVrPackagesDialogOpen(true)}
                      >
                        VR packages
                      </Button>
                    </div>
                  )}
                  image={null}
                  model={configurationDetails.name}
                />
                <div className="systems-container">
                  <SystemsGroupList
                    activeItemId={fetchedSystemsGroupId}
                    configurationId={configurationId}
                    isActiveVehicle={vehicleDetails.isActive}
                    permission={permission}
                    systemsGroups={systemsGroups}
                    vehicleId={vehicleId}
                    onAddSystemsGroup={(data) => setSystemsGroups([data, ...systemsGroups])}
                    onDeleteItem={handleDeleteItem}
                    onGetSystems={handleGetSystems}
                  />
                  {fetchedSystemsGroupId !== null && (
                    <SystemList
                      isActiveVehicle={vehicleDetails.isActive}
                      permission={permission}
                      systemsGroupId={fetchedSystemsGroupId}
                    />
                  )}
                  {fetchedSystemsGroupId === null && <p className="systems-group-list__info--center">You haven't chosen systems group. Select systems group from the list to see its details</p>}
                </div>
                {isRemoveDialogOpened &&
                  <Dialog
                    confirmationText="Remove"
                    dialogTitle="Remove configuration"
                    onCancel={() => setIsRemoveDialogOpened(false)}
                    onCloseDialog={() => setIsRemoveDialogOpened(false)}
                    onConfirm={() => setRemovingOn(true)}
                  >
                    <ScrollContainer>
                      <p>Are you sure you want to remove this configuration?</p>
                      {removeFetchError !== null && <p className="error">{removeFetchError}</p>}
                    </ScrollContainer>
                  </Dialog>
                }
                {isEditDialogOpened &&
                  <Dialog onCloseDialog={() => setIsEditDialogOpened(false)}>
                    <AddConfigurationForm
                      addCallbackSuccess={(data) => {
                        setConfigurationDetails(data);
                        setIsEditDialogOpened(false);
                      }}
                      initialName={configurationDetails.name}
                      isEditing
                      onCancel={() => setIsEditDialogOpened(false)}
                    />
                  </Dialog>
                }
                {isVrPackagesDialogOpen &&
                  <Dialog
                    onCancel={() => setIsVrPackagesDialogOpen(false)}
                    onCloseDialog={() => setIsVrPackagesDialogOpen(false)}
                  >
                    <ScrollContainer>
                      <VRPackages configurationId={configurationId} />
                    </ScrollContainer>
                  </Dialog>
                }
              </>
          )
        )
      }
    </MainTemplate>
  );
};
