import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

export const mainTheme = createTheme({
  palette: {
    primary: {
      main: '#027AFF',
    },
    secondary: {
      main: '#000',
    },
    componentVisible: {
      main: '#00F0FF',
    },
    componentNotVisible: {
      main: '#D6FF00',
    },
  },
  typography: {
    h4: {
      fontWeight: 500,
      fontSize: '1.95rem',
    },
  },
  components: {
    MuiListItemButton: {
      styleOverrides: {
        MuiSelected: {
          backgroundColor: red,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          flexShrink: 1,
          fontWeight: 500,
          wordBreak: 'break-word',
        },
        secondary: {
          flexShrink: 0,
          fontSize: '0.84rem',
        },
      },
    },
  },
});
