import { useMemo, useState } from 'react';
import {
  Button,
  List,
} from "@mui/material";
import { Add } from '@mui/icons-material';

import { PERMISSION } from '../../../helpers/consts';
import { Dialog } from '../../common';
import { AddSystemsGroupForm, SystemsGroup } from '..';
import './SystemsGroupList.sass';

export const SystemsGroupList = ({
  activeItemId,
  isActiveVehicle,
  onDeleteItem,
  onGetSystems,
  onAddSystemsGroup,
  permission,
  systemsGroups,
}) => {
  const [isAddDialogOpened, setIsAddDialogOpened] = useState(false);

  const sortedSystemGroups = useMemo(
    () => systemsGroups.sort((s1, s2) => s1.code.localeCompare(s2.code)),
    [systemsGroups]
  );

  const handleAddCallbackSuccess = (data) => {
    onAddSystemsGroup(data);
    setIsAddDialogOpened(false);
  };

  return (
    <div className="systems-group-list">
      {permission === PERMISSION.READ_WRITE &&
        <>
          <Button
            className="systems-group-list__add-btn"
            disabled={!isActiveVehicle}
            startIcon={<Add />}
            sx={{position: 'sticky'}}
            variant="contained"
            onClick={() => setIsAddDialogOpened(true)}
          >
            Add systems group
          </Button>
        </>
      }

      <h2 className="systems-group-list__title">Systems groups</h2>
      {sortedSystemGroups.length > 0
        ? (
          <List className="systems-group-list__list-container">
            {sortedSystemGroups.map((sg) => (
              <SystemsGroup
                key={sg.id}
                activeItemId={activeItemId}
                isActiveVehicle={isActiveVehicle}
                permission={permission}
                systemsGroup={sg}
                onDeleteItem={onDeleteItem}
                onGetSystems={onGetSystems}
              />
            ))}
          </List>
        ) : <p className="systems-group-list__info">There are no systems groups yet</p>
      }
      {isAddDialogOpened && <Dialog onCloseDialog={() => setIsAddDialogOpened(false)}>
        <AddSystemsGroupForm addCallbackSuccess={handleAddCallbackSuccess} onCancel={() => setIsAddDialogOpened(false)}/>
      </Dialog>}
    </div>
  );
};
