import ReactDOM from 'react-dom/client';

import reportWebVitals from './reportWebVitals';
import { UserProvider } from './context/userContext';
import App from './App';

import './index.css';

declare global {
  interface Window {
    USER_PROVIDER: any;
  }
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <UserProvider ref={c => window.USER_PROVIDER = c}>
    <App />
  </UserProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
