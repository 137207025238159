import { API_URLS } from '../urls/backend';
import { getCookieValue } from './helpers';

export const REQUEST_GET_ERROR = 'There was a temporary problem with getting' +
  ' the data. Please, try again later.';
export const REQUEST_POST_ERROR = 'There was a temporary problem with ' +
  'sending data to the server. Please, try again later.';
const REQUEST_DELETE_ERROR = 'There was a temporary problem with deleting ' +
  'data. Please, try again later.';
const CSRF_COOKIE_NAME = 'csrftoken';


const setCsrfHeader = (xhr) => {
  const csrfToken = getCookieValue(CSRF_COOKIE_NAME);
  if (csrfToken) {
    xhr.setRequestHeader('X-CSRFToken', csrfToken);
  }
}

const getResponseError = (method) => {
  return ['POST', 'PUT'].includes(method)
    ? REQUEST_POST_ERROR
    : method === 'DELETE'
      ? REQUEST_DELETE_ERROR : REQUEST_GET_ERROR
};

export const fetchApi = ({
    url,
    method = 'GET',
    body = {},
    callbackSuccess = (() => {}),
    callbackError = (() => {}),
}) => {

    const xhr = new XMLHttpRequest();
    if (['GET', 'DELETE'].includes(method)) {
        if (Object.keys(body).length) {
            let urlParamsPart = '';
            for (let [k, v] of Object.entries(body)) {
                if (Array.isArray(v)) {
                    for (let subvalue of v) {
                        urlParamsPart += `&${k}=${subvalue}`;
                    }
                } else {
                    urlParamsPart += `&${k}=${v}`;
                }
            }
            if (urlParamsPart.length) {
                url += `?${urlParamsPart.substring(1)}`;
            }
        }
    }
    xhr.open(method, `/api${url}`);
    xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');

    setCsrfHeader(xhr);

    xhr.onerror = () => {callbackError({
      status: xhr.status,
      error: xhr.responseText || getResponseError(method),
    })};
    xhr.onload = () => {
        if ([200, 400].includes(xhr.status)) {
            let success = true;
            let jsonData = {};
            try {
                jsonData = JSON.parse(xhr.responseText);
            } catch(error) {
                success = false;
            }
            if (success) {
                callbackSuccess({
                    status: xhr.status,
                    data: jsonData
                });
            } else {
                callbackError({
                  status: xhr.status,
                  error: getResponseError(method),
                });
            }
        } else {
            if (xhr.status === 401 && ![
                API_URLS.accountsIdentify.path, API_URLS.accountsLogin.path
            ].includes(url)) {
                // there are some urls for what 401 means something different
                window.USER_PROVIDER.logout();
                window.location.href = '/';
                return
            }
            callbackError({
              status: xhr.status,
              error: getResponseError(method),
            });
        }
    };

    xhr.send(JSON.stringify(body));
    return xhr
};

const fileUploadFinish = (xhr, callbackSuccess, callbackError) => {
    if ([200, 400].includes(xhr.status)) {
        let success = true;
        let jsonData = {};
        try {
            jsonData = JSON.parse(xhr.responseText);
        } catch (error) {
            success = false;
        }
        if (success) {
            callbackSuccess({
                status: xhr.status,
                data: jsonData
            });
        } else {
            callbackError({
                status: xhr.status,
                error: REQUEST_POST_ERROR,
            });
        }
    } else if (xhr.status === 413) {
        callbackSuccess({
            status: 400,
            data: {
                errors: {
                    file: ['Selected file is too big! Maximum size is 10MB.',],
                },
            }
        });
    } else if (xhr.status === 401) {
        window.USER_PROVIDER.logout();
    } else {
        callbackError({
            status: xhr.status,
            error: REQUEST_POST_ERROR,
        });
    }
};

export const fetchFileApi = ({
    url,
    body = {},
    image,
    callbackSuccess,
    callbackError,
}) => {
    // create a XHR request
    const xhr = new XMLHttpRequest();
    // and form data
    const formData = new FormData();
    formData.append('image', image);
    for (let [k, v] of Object.entries(body)) {
        formData.append(k, v);
    }

    xhr.open('POST', `/api${url}`);
    setCsrfHeader(xhr);

    xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
            fileUploadFinish(xhr, callbackSuccess, callbackError);
        }
    }

    // send
    xhr.send(formData)

    return xhr
}
