import { useContext } from 'react';
import {
  Navigate,
  Route,
  Routes as ReactRoutes,
  useLocation
} from 'react-router-dom';

import { ChangePasswordForm, LoginForm } from './components/accounts';
import { HomePage, PageNotFound } from './components/common';
import { ConfigurationDetails } from './components/configurations';
import { PanoramaDetails } from './components/panoramas';
import { Photos } from './components/photos';
import { Configurator } from './components/systems';
import { VehicleDetails } from './components/vehicles';
import { ConfiguratorProvider } from './context/configuratorContext';
import { UserContext } from './context/userContext';
import { URLS } from './urls/frontend';


const LoginRequired = ({ children }) => {
    const { user } = useContext(UserContext);
    let location = useLocation();
    if (!user.isAuthenticated) {
        return <Navigate to="/" state={{ from: location }} replace />;
    }
    return children;
}

export const Routes = () => {
    const { user, vehiclePermission } = useContext(UserContext);

    return (
      <ReactRoutes>
        <Route
          path="/"
          element={user.isAuthenticated
            ? <HomePage />
            : <LoginForm />
          }
        />
        <Route path={URLS.accountsLogin.path} element={<LoginForm />} />
        <Route
          path={URLS.accountsChangePassword.path}
          element={<LoginRequired><ChangePasswordForm /></LoginRequired>}
        />
        <Route
          path={URLS.configurationsGetDetails.path}
          element={<LoginRequired><ConfigurationDetails /></LoginRequired>}
        />
        <Route
          path={URLS.panoramasGetDetails.path}
          element={<LoginRequired><PanoramaDetails /></LoginRequired>}
        />
        <Route
          path={URLS.systemsConfigurator.path}
          element={
            <LoginRequired>
              <ConfiguratorProvider permission={vehiclePermission.current}>
                <Configurator />
              </ConfiguratorProvider>
            </LoginRequired>}
        />
        <Route
          path={URLS.vehiclesGetDetails.path}
          element={<LoginRequired><VehicleDetails /></LoginRequired>}
        />
        <Route
          path={URLS.photos.path}
          element={<LoginRequired><Photos /></LoginRequired>}
        />
        <Route
          path="*"
          element={<LoginRequired><PageNotFound /></LoginRequired>}
        />
      </ReactRoutes>
    )
}
