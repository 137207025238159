import { useContext, useEffect, useState } from 'react';
import { List } from '@mui/material';

import { ConfiguratorContext } from '../../../context/configuratorContext';
import { UserContext } from '../../../context/userContext';
import { PERMISSION } from '../../../helpers/consts';
import { useGetData } from '../../../helpers/hooks';
import { API_URLS } from '../../../urls/backend';
import { OuterPanoramaConfiguratorListItem, Loader } from '../../common';
import { Component, ComponentForm } from '..';
import './ComponentList.sass';


export const ComponentList = ({ panoramas, systemId }) => {
  const [outerComponentsPanoramas, setOuterComponentsPanoramas] = useState([]);
  const [otherComponentClickedId, setOtherComponentClickedId] = useState(null);
  const {
    components,
    outerSelectedComponentId,
    selectedPanorama,
    selectedSet,
    setComponentsAreFetching,
    setComponents,
    setOuterSelectedComponentId,
    setSelectedComponent,
    setTemporaryComponent,
    temporaryComponent,
  } = useContext(ConfiguratorContext);
  const { current: permission } = useContext(UserContext).vehiclePermission;

  useEffect(() => {
    setSelectedComponent(null);
    setTemporaryComponent(null);
  }, [selectedSet]);

  // get components (the list will change if url changes)
  const { fetchingOn, fetchError } = useGetData({
    url: API_URLS.panoramaPhotosComponents.getUrl(
      selectedPanorama.id, systemId, selectedSet?.id || 0),
    callbackSuccess: ({data}) => {
      setComponents(data.components);
      setOuterComponentsPanoramas(data.otherComponentsPanoramas);

      if (outerSelectedComponentId) {
        const filteredComponent = data.components.filter(component => component.id === outerSelectedComponentId)[0];
        setSelectedComponent(filteredComponent ? { ...filteredComponent, clickFromList: true } : null);
        setOuterSelectedComponentId(null);
      }
    },
  });

  useEffect(() => {
    setComponentsAreFetching(fetchingOn);
  }, [fetchingOn]);

  return (
    <>
      <h2 className="component-list__header">Components from current panorama</h2>
      {fetchingOn
        ? <Loader />
        : (
          <>
            {fetchError && <p className="error">{fetchError}</p>}
            {!components.length
              ? <p className="info">There are no components in this set yet.</p>
              : (
                <List>
                  {components.sort((a, b) => a.id > b.id ? 1 : -1).map(component =>
                    <Component component={component} key={component.id} />
                  )}
                </List>
              )
            }
            <h2 className="component-list__header component-list__outers-list">Components in other panoramas</h2>
            {!outerComponentsPanoramas.length
              ? <p className="info">{`There are no components in other panoramas${selectedSet ? ' in this set' : ''} yet.`}</p>
              : (
                <List>
                  {outerComponentsPanoramas.map(panorama =>
                    <li key={panorama.id}>
                      <h3 className="info component-list__outers-name">{panorama.name}</h3>
                      <List>
                        {panorama.components.map(component =>
                          <OuterPanoramaConfiguratorListItem
                            key={component.id}
                            component={component}
                            panorama={panorama}
                            panoramas={panoramas}
                            isExpanded={otherComponentClickedId === component.id}
                            onExpandListItem={() => setOtherComponentClickedId(otherComponentClickedId === component.id ? null : component.id)}
                          />
                        )}
                      </List>
                    </li>
                  )}
                </List>
              )
            }
          </>
        )
      }
      {temporaryComponent && permission === PERMISSION.READ_WRITE &&
        <ComponentForm isTemporary={true} key={temporaryComponent.id} />}
    </>
  )
}
