import { useState } from 'react';
import { Button } from '@mui/material';
import { CloudUploadOutlined } from '@mui/icons-material';

import './ImageInput.sass';

export const ImageInput = ({
  disabled,
  isUploadingSingleFile,
  multiple,
  refElement,
  uploadPhotosNumber,
  onAddFile,
}) => {
  const [inputBoxClassName, setInputBoxClassName] = useState('image-input');

  return (
    <div
      className={inputBoxClassName}
      onDragLeave={() => setInputBoxClassName('image-input')}
      onDragOver={() => setInputBoxClassName('image-input--drag-effect')}
    >
      <input
        className="image-input__input"
        accept="image/*"
        disabled={disabled}
        id="fileId"
        multiple={multiple || null}
        ref={refElement}
        type="file"
        onChange={(ev) => {
          onAddFile(ev);
          setInputBoxClassName('image-input');
        }}
      />
      <label className="image-input__label" htmlFor="fileId">
        <CloudUploadOutlined color="disabled" sx={{fontSize: '5rem'}}/>
        <p className="image-input__info">
          <span>{isUploadingSingleFile ? 'Drag&Drop file here' : 'Drag&Drop files here'}</span>
          <span className="image-input__info--secondary">or</span>
        </p>
        <Button component="span" variant="outlined">{isUploadingSingleFile ? 'Choose image' : 'Choose images'}</Button>
        {uploadPhotosNumber && <span className="image-input__upload-info">You can add {uploadPhotosNumber} photos</span>}
      </label>
    </div>
  )
}
