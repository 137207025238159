import { useState } from 'react';
import { Link } from 'react-router-dom';
import { WebAsset } from '@mui/icons-material';

import { useDeleteData } from '../../../helpers/hooks';
import { API_URLS } from '../../../urls/backend';
import { URLS } from '../../../urls/frontend';
import { DefaultListItem, Dialog, ScrollContainer } from '../../common';
import { AddSystemForm } from '..';

export const System = ({
  isActiveVehicle,
  onDeleteItem,
  permission,
  system,
  vehicleId
}) => {
  const [isEditDialogOpened, setIsEditDialogOpened] = useState(false);
  const [isRemoveDialogOpened, setIsRemoveDialogOpened] = useState(false);
  const [removingOn, setRemovingOn] = useState(false);
  const [systemDetails, setSystemDetails] = useState(system);

  const { fetchError } = useDeleteData({
    removingOn,
    url: API_URLS.systemsRemove.getUrl(systemDetails.id),
    callbackSuccess: () => onDeleteItem(systemDetails.id),
    callbackError: () => setRemovingOn(false),
  });

  return (
    <>
      <DefaultListItem
        actionButtons
        component={Link}
        description={systemDetails.name}
        icon={<WebAsset />}
        isActiveVehicle={isActiveVehicle}
        navigate={URLS.systemsConfigurator.getUrl(system.id, vehicleId)}
        permission={permission}
        title={systemDetails.code}
        onDelete={(e) => {
          e.preventDefault();
          setIsRemoveDialogOpened(true);
        }}
        onEdit={(e) => {
          e.preventDefault();
          setIsEditDialogOpened(true);
        }}
      />
      {isRemoveDialogOpened &&
        <Dialog
          confirmationText="Remove"
          dialogTitle="Remove system"
          onCancel={() => setIsRemoveDialogOpened(false)}
          onCloseDialog={() => setIsRemoveDialogOpened(false)}
          onConfirm={() => setRemovingOn(true)}
        >
          <ScrollContainer>
            <p>Are you sure you want to remove this system - name: {systemDetails.name}, id: {systemDetails.id}?</p>
            {fetchError !== null && <p className="error">{fetchError}</p>}
          </ScrollContainer>
        </Dialog>
      }
      {isEditDialogOpened &&
        <Dialog onCloseDialog={() => setIsEditDialogOpened(false)}>
          <AddSystemForm
            addCallbackSuccess={(data) => {
              setSystemDetails(data);
              setIsEditDialogOpened(false);
            }}
            initialCode={systemDetails.code}
            initialName={systemDetails.name}
            isEditing
            onCancel={() => setIsEditDialogOpened(false)}
            systemId={systemDetails.id}
          />
        </Dialog>
      }
    </>
  );
};
