import { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  List,
} from "@mui/material";
import {
  Add,
  AddPhotoAlternate,
  CheckCircleOutlined,
  PanoramaPhotosphere
} from '@mui/icons-material';

import { UserContext } from "../../../context/userContext";
import { useGetData } from '../../../helpers/hooks';
import { API_URLS } from '../../../urls/backend';
import { URLS } from '../../../urls/frontend';
import { DefaultListItem, Dialog, Loader } from "../../common";
import { AddPanoramaForm } from "..";

export const PanoramaList = ({ isActiveVehicle }) => {
  const [isAddDialogOpened, setIsAddDialogOpened] = useState(false);
  const [panoramas, setPanoramas] = useState([]);
  const { vehicleId } = useParams();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const { data = {}, fetchingOn, fetchError } = useGetData({
    url: API_URLS.panoramaPhotosGetForVehicle.getUrl(vehicleId),
  });

  useEffect(() => {
    if (Object.keys(data).length === 0) return;

    setPanoramas(data.panoramaPhotos.reverse());
  }, [data]);

  const completeInfo = (panorama) => {
    return (
      panorama.isComplete
        ? <span className="complete-info__container"><AddPhotoAlternate color="primary" />added 6/6<CheckCircleOutlined color="success" /></span>
        : <span className="complete-info__container">
            <AddPhotoAlternate color="secondary" sx={{mb: '.2rem', opacity: '.4'}} /> 
            <span className="complete-info__counter">{`added: ${panorama.imagesNumber}/6`}</span>
          </span>
    )

  };

  const sortedPanoramas = useMemo(
    () => panoramas.sort((p1, p2) => {
      if (p1.imagesNumber === p2.imagesNumber) {
        return p1.name.localeCompare(p2.name)
      } else {
        return p2.imagesNumber - p1.imagesNumber
      }
    }),
    [panoramas]
  );

  return (
    <>
      <div className="panoramas-list">
        {user.isAdmin &&
          <Button
            className="add-button"
            disabled={!isActiveVehicle}
            startIcon={<Add />}
            sx={{display: 'flex', marginBottom: '1.5rem'}}
            variant="contained"
            onClick={() => setIsAddDialogOpened(true)}
          >Add panorama</Button>}

        <h2 className="panoramas-list__title">Panoramas</h2>

        {fetchingOn
          ? <Loader />
          : (fetchError !== null
            ? <p className="error">{fetchError}</p>
            : (
                <>
                  {sortedPanoramas.length > 0
                    ? (
                      <List>
                        {sortedPanoramas.map((panorama) => (
                          <DefaultListItem
                            component={Link}
                            description={completeInfo(panorama)}
                            icon={<PanoramaPhotosphere />}
                            key={panorama.id}
                            navigate={URLS.panoramasGetDetails.getUrl(panorama.id, vehicleId)}
                            title={panorama.name}
                          />
                        ))}
                      </List>
                    ) : <p className="info">There are no panoramas yet</p>
                  }
                  {isAddDialogOpened && (
                    <Dialog onCloseDialog={() => setIsAddDialogOpened(false)}>
                      <AddPanoramaForm
                        addCallbackSuccess={(data) => navigate(URLS.panoramasGetDetails.getUrl(data.id, vehicleId))}
                        vehicleId={vehicleId}
                        onCancel={() => setIsAddDialogOpened(false)}
                      />
                    </Dialog>
                  )}
                </>
              )
            )
        }
      </div>
    </>
  );
};
