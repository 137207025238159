import { useEffect } from 'react';
import clsx from 'clsx';
import { Button } from '@mui/material';

import './Dialog.sass';

export const Dialog = ({
  cancelText,
  children,
  confirmationText,
  contentClassName,
  dialogContentId,
  dialogTitle,
  dialogTitleId,
  dialogWide,
  dialogWindowClassName,
  disabled,
  scrollableElementRef,
  onCancel,
  onCloseDialog,
  onConfirm,
}) => {
  const windowClassName = clsx([
    'dialog',
    dialogWide && 'dialog--wide',
    dialogWindowClassName,
  ]);

  const contentClassNameClsx = clsx([
    'dialog__content',
    !onCancel && !onConfirm && 'dialog__content--no-dialog-buttons',
    contentClassName,
  ]);

  useEffect(() => {
    if (onCloseDialog) {
      const close = (e) => {
        if(e.keyCode === 27){
          onCloseDialog();
        }
      }
      window.addEventListener('keydown', close);
      return () => window.removeEventListener('keydown', close);
    };
  },[]);

  return (
    <div className="dialog-container">
      <div
        aria-labelledby={dialogTitleId || 'dialogtitleId'}
        aria-describedby={dialogContentId || 'dialogContentId'}
        aria-modal="true"
        role="dialog"
        className={windowClassName}
        tabIndex="-1"
      >
        <h3 id={dialogTitleId || 'dialogTitleId'}>{dialogTitle}</h3>
        <div
          className={contentClassNameClsx}
          id={dialogContentId || 'dialogContentId'}
          ref={scrollableElementRef}
        >
          {children}
        </div>
        {(onCancel || onConfirm) && (
          <div className="dialog__btn-container">
            {onCancel && <Button
              disabled={disabled}
              variant="outlined"
              onClick={onCancel}>
                {cancelText || 'Cancel'}
              </Button>}
            {onConfirm && <Button
              disabled={disabled}
              variant="contained"
              onClick={onConfirm}
            >
              {confirmationText || 'OK'}
            </Button>}
          </div>
        )}
      </div>
    </div>
  )
}
