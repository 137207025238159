import { useState } from 'react';
import { TextField } from '@mui/material';

import { getFieldErrors } from '../../../helpers/helpers';
import { usePostData } from '../../../helpers/hooks';
import { API_URLS } from '../../../urls/backend';
import { BasicForm, FormButtons, ScrollContainer } from '../../common';

export const AddSystemForm = ({
    addCallbackSuccess,
    initialCode = '',
    initialName = '',
    isEditing,
    systemId,
    systemsGroupId,
    onCancel,
}) => {
    const [code, setCode] = useState(initialCode);
    const [name, setName] = useState(initialName);
    const [savingOn, setSavingOn] = useState(false);

    function handleSubmit(ev) {
        ev.preventDefault();
        setSavingOn(true);
    }

    const { errors: formErrors, fetchError } = usePostData({
        savingOn,
        setSavingOn,
        url: isEditing ? API_URLS.systemsEdit.getUrl(systemId) : API_URLS.systemsAdd.getUrl(systemsGroupId),
        method: isEditing ? 'PUT' : 'POST',
        postData: { code, name },
        callbackSuccess: ({ data }) => addCallbackSuccess(data.system),
    });

    const [isCodeInvalid, codeErrors] = getFieldErrors(
        formErrors, 'code');
    const [isNameInvalid, nameErrors] = getFieldErrors(
        formErrors, 'name');

    return (
        <BasicForm formTitle={isEditing ? 'Edit system' : 'Add system'}>
            <ScrollContainer>
                <form id="addSystemForm" onSubmit={handleSubmit}>
                    <div>
                        <TextField
                            autoFocus
                            disabled={savingOn}
                            error={isCodeInvalid}
                            fullWidth
                            id="systemCodeId"
                            inputProps={{maxLength: 11}}
                            label="System code"
                            placeholder='00-00-00'
                            variant="outlined"
                            value={code}
                            onChange={ev => setCode(ev.target.value)}
                        />
                        {isCodeInvalid && <p className="error">{codeErrors}</p>}
                    </div>
                    <div>
                        <TextField
                            disabled={savingOn}
                            error={isNameInvalid}
                            fullWidth
                            id="systemNameId"
                            inputProps={{maxLength: 128}}
                            label="System name"
                            variant="outlined"
                            value={name}
                            onChange={ev => setName(ev.target.value)}
                        />
                        {isNameInvalid && <p className="error">{nameErrors}</p>}
                    </div>
                    {!!fetchError && <p className="error">{fetchError}</p>}
                </form>
            </ScrollContainer>
            <FormButtons
                disabled={savingOn || !name || !code}
                idForm="addSystemForm"
                savingButtonText="Save system"
                savingOn={savingOn}
                onCancel={onCancel}
                onSubmit={handleSubmit}
            />
        </BasicForm>
    )
}
