import { useContext, useMemo } from 'react';
import { Canvas } from '@react-three/fiber';
import { sRGBEncoding, WebGLRenderer } from 'three';

import { ConfiguratorContext } from '../../../context/configuratorContext';
import PointerLockCamera from './PointerLockCamera';
import { ComponentsManager } from './ComponentsManager';
import { SkyBox } from './SkyBox';


export const WebGLEngine = ({
  panoramaImages = null,
  previewMode = false
}) => {
  const { selectedPanorama } = useContext(ConfiguratorContext);
  const images = useMemo(
    () => panoramaImages || selectedPanorama?.images,
    [panoramaImages, selectedPanorama?.images]
  );

  return (
    <Canvas
      gl={(canvas) => {
        const renderer = new WebGLRenderer({ canvas, antialias: false });
        renderer.outputEncoding = sRGBEncoding;
        return renderer;
      }}
    >
      <PointerLockCamera />
      {images && (
        <>
          <SkyBox images={images}/>
          {!previewMode && <ComponentsManager />}
        </>
      )}

      <ambientLight />
    </Canvas>
  );
};
