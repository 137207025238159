export const getCookieValue = (name) => {
    for (let cookie of document.cookie.split(';')) {
        const [n, v] = cookie.trim().split('=');
        if (n === name) {
            return v
        }
    }
    return null
};


export const getFieldErrors = (errors, fieldName) => {
  const errors_ = errors[fieldName] || [];
  return [errors_.length > 0, errors_]  // [isInvalid, errors]
};


export const formatDate = (date) => {
    const d = new Date(date);
    return d.toLocaleString();
};

export const getVehicleFullName = (vehicleDetails) =>
  `${vehicleDetails.producer || ''} ${vehicleDetails.model || ''}`

export const observeImages = ($img=null) => {

  function observe($img) {
    let observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          observer.disconnect();
          if ($img.dataset.src) {
            $img.src = $img.dataset.src;
          }
        }
      });
    });
    observer.observe($img);
  }

  if ($img === null) {
    document.querySelectorAll('img').forEach($img => {
      observe($img);
    });
  } else {
    observe($img);
  }
};

export const getThumbnailUrl = (photoUrl, size) => {
  if (!photoUrl?.length) { return '' }
  const splitUrl = photoUrl.split('?');
  if (splitUrl.length === 2) {
    return `${splitUrl[0]}/${size}?${splitUrl[1]}`
  }
  return `${photoUrl}/${size}`
}
