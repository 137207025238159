import { useContext, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Tooltip } from "@mui/material";
import {
  CheckCircle,
  DeleteForever,
  Edit,
  Numbers,
  NotInterested, 
} from '@mui/icons-material';
import ImageIcon from '@mui/icons-material/Image';

import { UserContext } from "../../../context/userContext";
import { formatDate, getVehicleFullName } from '../../../helpers/helpers';
import {
  useAddPageTitle,
  useCleanForcedReadOnlyMode,
  useDeleteData,
  useGetData
} from '../../../helpers/hooks';
import { API_URLS } from '../../../urls/backend';
import { URLS } from '../../../urls/frontend';
import { Breadcrumbs, DetailsHeader, Dialog, Loader, MainTemplate, ScrollContainer } from "../../common";
import { ConfigurationList } from "../../configurations";
import { PanoramaList } from "../../panoramas";
import { TagList } from "../../tags";
import { AddVehicleForm } from "..";

export const VehicleDetails = () => {
  const [configurations, setConfigurations] = useState([]);
  const [isEditDialogOpened, setIsEditDialogOpened] = useState(false);
  const [isRemoveDialogOpened, setIsRemoveDialogOpened] = useState(false);
  const [removingOn, setRemovingOn] = useState(false);
  const [vehicleDetails, setVehicleDetails] = useState({});
  const {
    setForcedReadOnlyModeAllowed,
    setVehiclePermission,
    user,
    vehiclePermission
  } = useContext(UserContext);

  useCleanForcedReadOnlyMode(setForcedReadOnlyModeAllowed);

  const navigate = useNavigate();
  const { vehicleId } = useParams();

  const { fetchingOn, fetchError } = useGetData({
    url: API_URLS.vehiclesGetDetails.getUrl(vehicleId),
    callbackSuccess: ({data}) => {
      if (Object.keys(data).length === 0) return;

      setVehicleDetails(data.vehicle);
      setVehiclePermission(vehicleId, data.permission);
      setConfigurations(data.configurations.reverse());
    }
  });

  const { fetchError: removeFetchError } = useDeleteData({
    removingOn,
    url: API_URLS.vehiclesRemove.getUrl(vehicleId),
    callbackSuccess: () => navigate(URLS.home.path),
    callbackError: () => setRemovingOn(false),
  });

  useAddPageTitle(`${vehicleDetails.model || ''} | My aircrafts | JetVar`);

  const {
    current: permission,
    original: originalPermission
  } = vehiclePermission;

  return (
    <MainTemplate>
      <Breadcrumbs
        breadcrumbs={[[URLS.home.path, 'My aircrafts'], ]}
        currentLocation={getVehicleFullName(vehicleDetails)} />

      {fetchingOn
        ? <Loader />
        : (fetchError !== null
          ? <p className="error">{fetchError}</p>
          : (
              <>
                <DetailsHeader
                  actionButtons={user.isAdmin && (
                    <div className="flex-container">
                      <Button
                        color="secondary"
                        startIcon={<DeleteForever />}
                        variant="outlined"
                        onClick={() => setIsRemoveDialogOpened(true)}
                      >
                        Remove
                      </Button>
                      <Button
                        startIcon={<Edit />}
                        variant="contained"
                        onClick={() => setIsEditDialogOpened(true)}
                      >
                        Edit
                      </Button>
                    </div>
                  )}
                  hasDetails
                  icon={
                    <Tooltip title={vehicleDetails.isActive ? 'Active' : 'Inactive'}>
                      {vehicleDetails.isActive
                        ? <CheckCircle color="primary" />
                        : <NotInterested color="secondary" sx={{opacity: '.3'}}/>}
                    </Tooltip>
                  }
                  model={getVehicleFullName(vehicleDetails)}
                  image={vehicleDetails.imageUrl}
                >
                  <p className="details-header__details__item--wrap"><span className="details-header__details__item--name">Description:</span> {vehicleDetails.description}</p>
                  <div className="details-header__details-container">
                    <div className="details-header__details__item expand">
                      <Button
                        component={Link}
                        startIcon={<ImageIcon />}
                        to={URLS.photos.getUrl(vehicleId)}
                        variant="contained"
                      >Photos</Button>
                    </div>
                    <p className="details-header__details__item">
                      <Tooltip title="Modified at">
                        <Edit color="primary"/>
                      </Tooltip>
                      {formatDate(vehicleDetails.modifiedAt)}
                    </p>
                    <p className="details-header__details__item">
                      <Tooltip title="Id">
                        <Numbers color="primary"/>
                      </Tooltip>
                      {vehicleDetails.id}
                    </p>
                    <p className="details-header__details__item">
                      <Tooltip title="Permission">
                        <span className="color-badge">{originalPermission.replace('_', ' & ')}</span>
                      </Tooltip>
                    </p>
                  </div>
                </DetailsHeader>
                <ConfigurationList
                  configurations={configurations}
                  isActiveVehicle={vehicleDetails.isActive}
                  permission={permission}
                  vehicleId={vehicleId}
                />
                <PanoramaList isActiveVehicle={vehicleDetails.isActive} />
                <TagList isActiveVehicle={vehicleDetails.isActive} />
                {isRemoveDialogOpened &&
                  <Dialog
                    confirmationText="Remove"
                    dialogTitle="Remove vehicle"
                    onCancel={() => setIsRemoveDialogOpened(false)}
                    onCloseDialog={() => setIsRemoveDialogOpened(false)}
                    onConfirm={() => setRemovingOn(true)}
                  >
                    <ScrollContainer>
                      <p>Are you sure you want to remove this vehicle?</p>
                      {removeFetchError !== null && <p className="error">{removeFetchError}</p>}
                    </ScrollContainer>
                  </Dialog>
                }
                {isEditDialogOpened &&
                  <Dialog onCloseDialog={() => setIsEditDialogOpened(false)}>
                    <AddVehicleForm
                      addCallbackSuccess={(data) => {
                        setVehicleDetails(data);
                        setIsEditDialogOpened(false);
                      }}
                      initialData={{
                        vehicleId: vehicleDetails.id,
                        description: vehicleDetails.description,
                        isActive: vehicleDetails.isActive,
                        model: vehicleDetails.model,
                        producer: vehicleDetails.producer,
                      }}
                      imageUrl={vehicleDetails.imageUrl}
                      isEditing
                      onCancel={() => setIsEditDialogOpened(false)}
                      vehicleId={vehicleId}
                    />
                  </Dialog>
                }
              </>
          )
        )
      }
    </MainTemplate>
  );
};
