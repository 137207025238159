import { useState } from 'react';
import { IconButton } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

import { useDeleteData } from '../../../helpers/hooks';
import { API_URLS } from '../../../urls/backend';
import { Dialog, ScrollContainer } from '../../common';
import { EditPhotoForm, PhotoPreview } from '..';


export const PhotoActionButtons = ({
  canEdit,
  photo,
  onDelete,
  onSave
}) => {
  const [isEditDialogOpened, setIsEditDialogOpened] = useState(false);
  const [isPreviewDialogOpened, setIsPreviewDialogOpened] = useState(false);
  const [isRemoveDialogOpened, setIsRemoveDialogOpened] = useState(false);
  const [removeFetchError, setRemoveFetchError] = useState(null);
  const [removingOn, setRemovingOn] = useState(false);

  useDeleteData({
    removingOn,
    url: API_URLS.photosRemove.getUrl(photo.id),
    callbackSuccess: ({ data, status }) => {
      if (status === 400) {
        setRemoveFetchError(data.error);
        setRemovingOn(false)
      } else {
        onDelete(photo.id);
      }
    },
    callbackError: ({ error }) => {
      setRemoveFetchError(error);
      setRemovingOn(false);
    },
  });

  return (
    <>
      <div className="photos__remove-btn">
        <IconButton
          aria-label="Preview"
          size="small"
          variant="outlined"
          onClick={() => setIsPreviewDialogOpened(true)}
        >
          <RemoveRedEyeOutlinedIcon fontSize="small" />
        </IconButton>
        {canEdit && (
          <>
            <IconButton
              aria-label="Edit"
              size="small"
              variant="outlined"
              onClick={() => setIsEditDialogOpened(true)}
            >
              <EditIcon fontSize="small" />
            </IconButton>
            <IconButton
              aria-label="Delete"
              size="small"
              variant="outlined"
              onClick={() => setIsRemoveDialogOpened(true)}
            >
              <DeleteForeverIcon fontSize="small" />
            </IconButton>
          </>
        )}
      </div>
      {isPreviewDialogOpened &&
        <PhotoPreview
          photo={photo}
          onClose={() => setIsPreviewDialogOpened(false)}
        />
      }
      {isEditDialogOpened &&
        <Dialog onCloseDialog={() => setIsEditDialogOpened(false)}>
          <EditPhotoForm
            initialData={{
              name: photo.name,
              description: photo.description,
              imageUrl: photo.imageUrl,
              tags: photo.tags.map(tag => tag.id),
            }}
            photoId={photo.id}
            onCancel={() => setIsEditDialogOpened(false)}
            onSave={photoData => {
              onSave(photoData);
              setIsEditDialogOpened(false);
            }}
          />
        </Dialog>
      }
      {isRemoveDialogOpened &&
        <Dialog
          onCloseDialog={() => setIsRemoveDialogOpened(false)}
          confirmationText="Remove"
          dialogTitle="Remove photo"
          onCancel={() => setIsRemoveDialogOpened(false)}
          onConfirm={() => setRemovingOn(true)}
        >
          <ScrollContainer>
            <p>Are you sure you want to remove this photo: {photo.name}?</p>
            {removeFetchError !== null && <p className="error">{removeFetchError}</p>}
          </ScrollContainer>
        </Dialog>
      }
    </>
  )
}
