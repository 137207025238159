export const PERMISSION = {
  READ: 'READ',
  READ_WRITE: 'READ_WRITE',
};

export const MAX_COMPONENT_PHOTOS_COUNT = 10;

export const DEFAULT_PHOTOS_COUNTERS = {
  all: 0,
  used: 0,
  unused: 0,
};

export const THUMBNAILS_SIZES = {
  S: 's', // 120px shorter dimension
  M: 'm', // 180px shorter dimension
  L: 'l', // 260px shorter dimension
};
