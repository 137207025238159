import { Tooltip, Typography } from "@mui/material";
import { CheckCircle, NotInterested } from '@mui/icons-material';

import { getVehicleFullName } from '../../../helpers/helpers';
import { VehicleMiniature } from '../VehicleMiniature/VehicleMiniature';
import './VehicleInfo.sass';

export const VehicleInfo = ({ children, isActive, vehicleDetails }) => {
  return (
    <div className="vehicle-info">
      <div className="vehicle-info__title">
        <div className="vehicle-info__icon">
          <VehicleMiniature
            alt={getVehicleFullName(vehicleDetails)}
            image={vehicleDetails.imageUrl}
            isActiveIcon={
              isActive
                ? <Tooltip title="Active"><CheckCircle color="primary" /></Tooltip>
                : <Tooltip title="Inactive"><NotInterested color="secondary" sx={{ opacity: ".3" }} /></Tooltip>
            }
            small
          />
        </div>
        <Typography
          sx={{ fontWeight: '700', wordBreak: 'break-word'}}
          variant="subtitle1"
        >
          {getVehicleFullName(vehicleDetails)}
        </Typography>
        {children}
      </div>
    </div>
  )
}
