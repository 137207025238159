import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useThree } from '@react-three/fiber';
import {
  DoubleSide,
  Mesh,
  MeshStandardMaterial,
  SphereGeometry,
  Vector3
} from 'three';

import { ConfiguratorContext } from '../../../context/configuratorContext';
import { UserContext } from '../../../context/userContext';
import { PERMISSION } from '../../../helpers/consts';
import { ComponentButton } from './ComponentButton';


export const ComponentsManager = () => {
  const { camera, gl, scene, raycaster } = useThree();
  const {
    components,
    listeners,
    selectedComponent,
    selectedSet,
    setSelectedComponent,
    setTemporaryComponent,
    temporaryComponent
  } = useContext(ConfiguratorContext);
  const { current: permission } = useContext(UserContext).vehiclePermission;

  const addPosition = () => {
    if (!selectedSet) return

    const intersects = raycaster.intersectObjects(scene.children, false);
    setTemporaryComponent({
      id: 0,
      isTemporary: true,
      name: selectedSet.name,
      description: '',
      isVisible: true,
      coordinates: intersects[0]?.point || null,
      size: {w: 1, h: 1},
    });
  };

  const handleSkyBoxClick = useCallback((e) => {
    if (e.button === 1) addPosition();
  }, [selectedSet]);

  const handleChangePosition = useCallback(() => {
    const intersects = raycaster.intersectObjects(scene.children, false);
    if (intersects.length > 0) {
      const newCoordinates = intersects[0]?.point;
      if (newCoordinates) {
        setSelectedComponent({...selectedComponent, newCoordinates});
      }
    }
  }, [selectedComponent]);

  const handlePressKey = useCallback((e) => {
    if (e.key === 'a') {
      e.stopPropagation();
      addPosition();
    }
  }, [selectedSet]);

  const handleMouseEnter = useCallback((e) => {
    window.addEventListener('keydown', handlePressKey);
  }, [selectedSet]);

  const handleMouseLeave = useCallback((e) => {
    window.removeEventListener('keydown', handlePressKey);
  }, [selectedSet]);

  useEffect(() => {
    if (selectedSet && listeners.markNewComponent) {
      gl.domElement.addEventListener('mousedown', handleSkyBoxClick);
      gl.domElement.addEventListener('mouseenter', handleMouseEnter);
      gl.domElement.addEventListener('mouseleave', handleMouseLeave);
    } else {
      gl.domElement.removeEventListener('mousedown', handleSkyBoxClick);
      gl.domElement.removeEventListener('mouseenter', handleMouseEnter);
      gl.domElement.removeEventListener('mouseleave', handleMouseLeave);
    }

    return () => {
      if (permission === PERMISSION.READ_WRITE) {
        gl.domElement.removeEventListener('mousedown', handleSkyBoxClick);
        gl.domElement.removeEventListener('keydown', handlePressKey);
        gl.domElement.removeEventListener('mouseenter', handleMouseEnter);
        gl.domElement.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, [listeners.markNewComponent, selectedSet]);

  useEffect(() => {
    if (selectedComponent?.isMoving) {
      gl.domElement.addEventListener('mouseup', handleChangePosition);
    } else {
      gl.domElement.removeEventListener('mouseup', handleChangePosition);
    }

    return () => gl.domElement.removeEventListener('mouseup', handleChangePosition);
  }, [selectedComponent?.isMoving]);

  useEffect(() => {
    const geometry = new SphereGeometry(30, 32, 16);
    const material = new MeshStandardMaterial({
      side: DoubleSide,
      opacity: 0,
      transparent: true,
    });
    const mesh = new Mesh(geometry, material);
    mesh.position.set(0, 0, 0);
    scene.add(mesh);
  }, []);

  useEffect(() => {
    if (selectedComponent?.clickFromList) {
      const { x, y, z } = selectedComponent.coordinates;
      delete selectedComponent['clickFromList'];
      camera.lookAt(x, y, z);
    }
  }, [selectedComponent]);

  const componentsToRender = useMemo(() => {
    return temporaryComponent ? [temporaryComponent, ...components] : components
  }, [components, temporaryComponent]);

  return componentsToRender.map(component =>
    <ComponentButton
      isTemporary={component.isTemporary || selectedComponent?.id === component.id}
      isVisible={component.isVisible}
      key={component.id}
      position={new Vector3(
        component.coordinates.x,
        component.coordinates.y,
        component.coordinates.z
      )}
      onClick={() => component.isTemporary ? {} : setSelectedComponent(component)}
    />
  )
}
