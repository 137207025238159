import { useContext, useEffect } from 'react';

import { ConfiguratorContext } from '../../../context/configuratorContext';
import { Dialog } from '../../common';


export const ConfiguratorDialog = ({ children, ...props }) => {
  const { markDialogOpened } = useContext(ConfiguratorContext);

  useEffect(() => {
    markDialogOpened(true);
    return () => markDialogOpened(false)
  }, []);

  return <Dialog {...props}>{children}</Dialog>
}
