import { useState } from 'react';
import {
  Button,
} from "@mui/material";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

import { PERMISSION, THUMBNAILS_SIZES } from '../../../helpers/consts';
import { getThumbnailUrl } from '../../../helpers/helpers';
import { Dialog } from '../../common';
import { AddPositionForm } from '../AddPositionForm/AddPositionForm';
import './Position.sass';


export const Position = ({
  className,
  onAddedPosition,
  permission,
  positionName,
  src,
}) => {
  const [isAddDialogOpened, setIsAddDialogOpened] = useState(false);

  const handleAddCallbackSuccess = (data) => {
    setIsAddDialogOpened(false);
    onAddedPosition(positionName, data.imageUrl)
  };

  return (
    <>
      <div className={`position ${className}`}>
        <p className="position__name">{positionName}</p>
        <div className="position__img-placeholder">
          {src ? <img src={getThumbnailUrl(src, THUMBNAILS_SIZES.L)} alt={positionName} className="position__img" /> : <AddPhotoAlternateIcon />}
        </div>
        {permission === PERMISSION.READ_WRITE &&
          <Button
            fullWidth
            variant="contained"
            onClick={() => setIsAddDialogOpened(true)}
          >
            {`${src ? 'Change' : 'Add'} ${positionName} image`}
          </Button>
        }
      </div>
      {isAddDialogOpened && (
        <Dialog onCloseDialog={() => setIsAddDialogOpened(false)}>
          <AddPositionForm
            addCallbackSuccess={handleAddCallbackSuccess}
            positionName={positionName}
            onCancel={() => setIsAddDialogOpened(false)}
          />
        </Dialog>
      )}
    </>
  );
};
