import {
  Avatar,
  IconButton,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { EditOutlined, DeleteOutlineOutlined } from '@mui/icons-material';

import { PERMISSION } from '../../../helpers/consts';

import './DefaultListItem.sass';

export const DefaultListItem = ({
  actionButtons,
  component,
  description,
  icon,
  isActiveVehicle,
  navigate,
  selected,
  title,
  onClick,
  onDelete,
  onEdit,
  permission,
}) => {
  return (
    <li className="default-list-item">
      <ListItemButton
        className="default-list-item__item"
        component={component}
        selected={selected}
        sx={{
          '&.Mui-selected': {
            backgroundColor: "#56a4fa21",
          },
          '&.MuiListItemButton-root': {
            px: '1.5rem',
          },
        }}
        onClick={onClick}
        to={navigate}
      >
        <ListItemAvatar>
          <Avatar
            size="small"
            sx={selected ? {backgroundColor: "#027AFF"} : {}}
          >
            {icon}
          </Avatar>
        </ListItemAvatar>
        <ListItemText className="list-item-text" primary={title} secondary={description} />
        {actionButtons && <div className="default-list-item__btn-container">
          {permission === PERMISSION.READ_WRITE && (
            <>
              <IconButton
                aria-label="Delete"
                disabled={!isActiveVehicle}
                size="small"
                variant="outlined"
                onClick={onDelete}
              >
                <DeleteOutlineOutlined color="disabled" fontSize="small" />
              </IconButton>
              <IconButton
                aria-label="Edit"
                disabled={!isActiveVehicle}
                size="small"
                variant="outlined"
                onClick={onEdit}
              >
                <EditOutlined color="disabled" fontSize="small" />
              </IconButton>
            </>
          )}
        </div>}
      </ListItemButton>
    </li>
  )
}
