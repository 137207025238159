import { checkRequiredFile, Validator } from './base';


const checkImgContentType = (value) => {
  if (!value) { return '' }
  if (value.type.split('/')[0] !== 'image') {
    return 'Load image file.'
  }
  return ''
};

export const validate = (data) => {
  return new Validator(
    {
      'image': [checkRequiredFile, checkImgContentType],
    },
    data
  ).validate()
};
