import { useState } from 'react';
import { TextField } from '@mui/material';

import { getFieldErrors } from '../../../helpers/helpers';
import { usePostData } from '../../../helpers/hooks';
import { API_URLS } from '../../../urls/backend';
import { BasicForm, FormButtons, ScrollContainer } from '../../common';


export const AddPanoramaForm = ({
    addCallbackSuccess,
    initialName = '',
    isEditing,
    onCancel,
    panoramaPhotoId,
    vehicleId,
}) => {
    const [name, setName] = useState(initialName);
    const [savingOn, setSavingOn] = useState(false);

    function handleSubmit(ev) {
        ev.preventDefault();
        setSavingOn(true);
    }

    const { errors: formErrors, fetchError } = usePostData({
        savingOn,
        setSavingOn,
        url: isEditing ? API_URLS.panoramaPhotosEdit.getUrl(panoramaPhotoId) : API_URLS.panoramaPhotoAdd.getUrl(vehicleId),
        method: isEditing ? 'PUT' : 'POST',
        postData: { name },
        callbackSuccess: ({ data }) => addCallbackSuccess(data.panoramaPhoto),
    });

    const [isNameInvalid, nameErrors] = getFieldErrors(
        formErrors, 'name');

    return (
        <BasicForm formTitle={isEditing ? 'Edit panorama' : 'Add panorama'}>
            <ScrollContainer>
                <form id="addPanoramaForm" onSubmit={handleSubmit}>
                    <div>
                        <TextField
                            autoFocus
                            disabled={savingOn}
                            error={isNameInvalid}
                            fullWidth
                            id="panoramaNameId"
                            inputProps={{ maxLength: 128 }}
                            label="Panorama name"
                            variant="outlined"
                            value={name}
                            onChange={ev => setName(ev.target.value)}
                        />
                        {isNameInvalid && <p className="error">{nameErrors}</p>}
                    </div>
                    {!!fetchError && <p className="error">{fetchError}</p>}
                </form>
            </ScrollContainer>
            <FormButtons
                disabled={savingOn || !name}
                idForm="addPanoramaForm"
                savingButtonText="Save panorama"
                savingOn={savingOn}
                onCancel={onCancel}
                onSubmit={handleSubmit}
            />
        </BasicForm>
    )
}
