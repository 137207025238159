import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ConfiguratorContext } from '../../../context/configuratorContext';
import { UserContext } from '../../../context/userContext';
import { PERMISSION } from '../../../helpers/consts';
import { getVehicleFullName } from '../../../helpers/helpers';
import {
  useAddPageTitle,
  useCleanForcedReadOnlyMode,
  useGetData
} from '../../../helpers/hooks';
import { API_URLS } from '../../../urls/backend';
import { URLS } from '../../../urls/frontend';
import { Breadcrumbs, Loader, MainTemplate } from '../../common';
import { ComponentList } from '../../components';
import { SetList } from '../../sets';
import { Legend } from './Legend';
import { PanoramaList } from './PanoramaList';
import { WebGLEngine } from './WebGLEngine';
import './Configurator.sass';


export const Configurator = () => {
  const [configurationDetails, setConfigurationDetails] = useState({});
  const [systemDetails, setSystemDetails] = useState({});
  const [systemsGroupDetails, setSystemsGroupDetails] = useState({});
  // initial requestId 1 will be set in useEffect below
  const [requestId, setRequestId] = useState(0);
  const { systemId } = useParams();

  useAddPageTitle(`${systemDetails.name || ''} | ${systemsGroupDetails.name || ''} | JetVar`);

  const {
    panoramas,
    selectedPanorama,
    setPanoramas,
    setSelectedPanorama,
    sets,
    setSets,
    setVehicleDetails,
    vehicleDetails
  } = useContext(ConfiguratorContext);

  const {
    setForcedReadOnlyModeAllowed,
    setVehiclePermission,
    vehiclePermission
  } = useContext(UserContext);

  useCleanForcedReadOnlyMode(setForcedReadOnlyModeAllowed);

  const { current: permission } = vehiclePermission;
  const prevPermissionRef = useRef();

  useEffect(() => {
    if (prevPermissionRef.current === null && permission !== null) {
      // skip unnecessary request after initializing permission value from server
      prevPermissionRef.current = permission
    } else {
      setRequestId(prevVal => prevVal + 1);
    }
  }, [permission]);

  const { fetchingOn, fetchError } = useGetData({
    url: API_URLS.systemsGetDetails.getUrl(systemId),
    body: permission === PERMISSION.READ ? {read_only_mode: 1} : {},
    requestId,
    callbackSuccess: ({data}) => {
      const panoramas = data.panoramaPhotos.sort(
        (p1, p2) => p1.name.localeCompare(p2.name));
      setPanoramas(panoramas);
      if (panoramas.length) {
        if (
          !selectedPanorama ||
          !panoramas.find(panorama => panorama.id === selectedPanorama.id)
        ) {
          setSelectedPanorama(panoramas[0]);
        }
      } else {
        setSelectedPanorama(null);
      }
      if (requestId === 1) {
        // set it only for the first time
        setConfigurationDetails(data.configuration);
        setVehicleDetails(data.vehicle);
        setSystemsGroupDetails(data.systemsGroup);
        setSystemDetails(data.system);
        setSets(data.sets, true);
        // this one is for skipping unnecessary request after initializing permission value from server
        prevPermissionRef.current = vehiclePermission.current;
        setVehiclePermission(data.vehicle.id, data.permission);
      }
    }
  });

  return (
    <>
      <MainTemplate viewPart>
        <Breadcrumbs
          breadcrumbs={[
            [URLS.home.path, 'My aircrafts'],
            [URLS.vehiclesGetDetails.getUrl(vehicleDetails.id), getVehicleFullName(vehicleDetails)],
            [URLS.configurationsGetDetails.getUrl(configurationDetails.id, vehicleDetails.id), `ATA ${systemsGroupDetails.code || ''} ${systemsGroupDetails.name || ''}`],
          ]}
          currentLocation={`${systemDetails.code || ''} ${systemDetails.name || ''}`}
        />
        {fetchingOn
          ? <Loader />
          : (fetchError !== null
            ? <p className="error">{fetchError}</p>
            : (
             !panoramas.length
                ? <p className="error">There is no completed panorama available for this vehicle.</p>
                : <PanoramaList sets={sets} panoramas={panoramas} />
            )
          )
        }
      </MainTemplate>
      <div className="configurator">
        {fetchingOn
          ? <Loader />
          : (panoramas.length || permission === PERMISSION.READ_WRITE) &&
              <div className="configurator__panorama-grid">
                {!!panoramas.length && (
                  <div className="configurator__panorama-container">
                    <Legend showInstruction />
                    <div className="configurator__panorama">
                      <WebGLEngine key={selectedPanorama.id} />
                    </div>
                  </div>
                )}
                <div className="configurator__panorama-lists">
                  <SetList />
                  {!!panoramas.length && !!sets.length && <ComponentList panoramas={panoramas} systemId={systemId} />}
                </div>
              </div>
        }
      </div>
    </>
  )
}
