import { useState } from 'react';
import {
    TextField,
} from '@mui/material';
import { useParams } from 'react-router-dom';

import { getFieldErrors } from '../../../helpers/helpers';
import { usePostData } from '../../../helpers/hooks';
import { API_URLS } from '../../../urls/backend';
import { BasicForm, FormButtons, ScrollContainer } from '../../common';

export const AddSystemsGroupForm = ({
    addCallbackSuccess,
    initialCode = '',
    initialName = '',
    isEditing,
    onCancel,
    systemsGroupId,
}) => {
    const [code, setCode] = useState(initialCode);
    const [name, setName] = useState(initialName);
    const [savingOn, setSavingOn] = useState(false);

    const { configurationId } = useParams();

    function handleSubmit(ev) {
        ev.preventDefault();
        setSavingOn(true);
    }

    const { errors: formErrors, fetchError: fetchAddError } = usePostData({
        savingOn,
        setSavingOn,
        url: isEditing ? API_URLS.systemsGroupsEdit.getUrl(systemsGroupId) : API_URLS.systemsGroupsAdd.getUrl(configurationId),
        method: isEditing ? 'PUT' : 'POST',
        postData: { code, name },
        callbackSuccess: ({ data }) => addCallbackSuccess(data.systemsGroup),
    });

    const [isCodeInvalid, codeErrors] = getFieldErrors(
        formErrors, 'code');
    const [isNameInvalid, nameErrors] = getFieldErrors(
        formErrors, 'name');

    return (
        <BasicForm formTitle={isEditing ? 'Edit systems group' : 'Add systems group'}>
            <ScrollContainer>
                <form id="addSystemsGroupForm" onSubmit={handleSubmit}>
                    <div>
                        <TextField
                            autoFocus
                            disabled={savingOn}
                            error={isCodeInvalid}
                            fullWidth
                            id="codeId"
                            inputProps={{
                                maxLength: 10,
                            }}
                            label="Code"
                            variant="outlined"
                            value={code}
                            onChange={ev => setCode(ev.target.value)}
                        />
                        {isCodeInvalid && <p className="error">{codeErrors}</p>}
                    </div>
                    <div>
                        <TextField
                            disabled={savingOn}
                            error={isNameInvalid}
                            fullWidth
                            id="nameId"
                            inputProps={{
                                maxLength: 128,
                            }}
                            label="Systems group name"
                            variant="outlined"
                            value={name}
                            onChange={ev => setName(ev.target.value)}
                        />
                        {isNameInvalid && <p className="error">{nameErrors}</p>}
                    </div>
                    {!!fetchAddError && <p className="error">{fetchAddError}</p>}
                </form>
            </ScrollContainer>
            <FormButtons
                disabled={savingOn || !name || !code}
                idForm="addSystemsGroupForm"
                savingButtonText="Save systems group"
                savingOn={savingOn}
                onCancel={onCancel}
                onSubmit={handleSubmit}
            />
        </BasicForm>
    )
}
