import { useContext, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';

import { API_URLS } from './urls/backend';
import { Loader } from './components/common';
import { mainTheme } from './styles/mainTheme';
import { Routes } from './Routes';
import { useGetData } from './helpers/hooks';
import { UserContext } from './context/userContext';
import './App.sass';

function App() {
  const [loadingAppError, setLoadingAppError] = useState(null);

  const {
    initialized,
    setInitialized,
    setUserData,
  } = useContext(UserContext);


  function callbackSuccess({ data }) {
    setUserData(data.user);
  }

  function callbackError({ status, error }) {
    if (status === 401) {
      setInitialized(true);
    } else {
      setLoadingAppError(error);
    }
  }

  useGetData({
    url: API_URLS.accountsIdentify.path,
    callbackSuccess,
    callbackError,
  });

  return (
    <ThemeProvider theme={mainTheme}>
      <BrowserRouter>
        {loadingAppError
          ? <p className="main-container--view-part error">{loadingAppError}</p>
          : initialized ? <Routes /> : <Loader />
        }
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
