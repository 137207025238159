import { Typography } from "@mui/material";

import { VehicleMiniature } from "../VehicleMiniature/VehicleMiniature";
import './DetailsHeader.sass';

export const DetailsHeader = ({
  actionButtons,
  children,
  hasDetails,
  icon,
  image,
  model,
}) => {
  return (
    <div className="details-header">
      <div className="details-header__title">
        {image !== null && <VehicleMiniature alt={model} image={image} isActiveIcon={icon} />}
        <div className="details-header__title-name">
          <Typography
            component="h3"
            sx={{wordBreak: 'break-word'}}
            variant="h4"
          >
            {model}
          </Typography>
        </div>
        <div className="details-header__buttons">
          {actionButtons}
        </div>
      </div>
      {hasDetails && <Typography component="p" sx={{mb: '.5rem', mt: '1rem', fontWeight: '500'}} variant="h6">Details:</Typography>}
      <div className="details-header__details">
        {children}
      </div>
    </div>
  )
}
