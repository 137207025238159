import { useState } from 'react';
import QRCode from "react-qr-code";
import { Button } from '@mui/material';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import QrCode2Icon from '@mui/icons-material/QrCode2';

import { Dialog } from '../../common';
import './VRPackages.sass';


export const VRPackageLink = ({ packageName, url }) => {
  const [showCopiedInfo, setShowCopiedInfo] = useState(false);
  const [qrDialogOpened, setQrDialogOpened] = useState(false);

  const handleCopyLink = () => {
    const textarea = document.createElement('textarea');
    textarea.innerText = url;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    setShowCopiedInfo(true);

    setTimeout(() => {
      setShowCopiedInfo(false);
    }, 1500);
  }

  return (
    <>
      <Button
        color={showCopiedInfo ? "success" : "primary"}
        size="small"
        startIcon={showCopiedInfo ? <CheckOutlinedIcon /> : <ContentCopyIcon />}
        variant="outlined"
        onClick={handleCopyLink}
      >
        {showCopiedInfo ? 'Copied' : 'Copy link'}
      </Button>
      <Button
        size="small"
        startIcon={<QrCode2Icon />}
        variant="outlined"
        onClick={() => setQrDialogOpened(true)}
      >
        QR code
      </Button>
      {qrDialogOpened &&
        <Dialog
          cancelText="Close"
          dialogTitle={`VR package: ${packageName}`}
          onCancel={() => setQrDialogOpened(false)}
        >
          <div className="vr-package__qr">
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={url}
              viewBox={`0 0 256 256`}
            />
          </div>
        </Dialog>
      }
    </>
  )
}
