import { useEffect, useState } from 'react';
import {
    FormControlLabel,
    Switch,
    TextField,
} from '@mui/material';
import { useParams } from 'react-router-dom';

import { getFieldErrors } from '../../../helpers/helpers';
import { usePostData } from '../../../helpers/hooks';
import { API_URLS } from '../../../urls/backend';
import { BasicForm, FormButtons, ScrollContainer } from '../../common';

export const AddConfigurationForm = ({
    addCallbackSuccess,
    initialName,
    isEditing,
    onCancel,
}) => {
    const [name, setName] = useState(isEditing ? initialName : '');
    const [formErrors, setFormErrors] = useState({});
    const [savingOn, setSavingOn] = useState(false);

    const {configurationId, vehicleId} = useParams();

    function handleSubmit(ev) {
        ev.preventDefault();
        setSavingOn(true);
    }

    const { errors, fetchError: fetchAddError } = usePostData({
        savingOn,
        setSavingOn,
        url: isEditing ? API_URLS.configurationsEdit.getUrl(configurationId) : API_URLS.configurationsAdd.getUrl(vehicleId),
        method: isEditing ? 'PUT' : 'POST',
        postData: { name },
        callbackSuccess: ({ data }) => addCallbackSuccess(data.configuration),
        callbackError: (error) => {
            setFormErrors(error);
            setSavingOn(false)
        },
    });

    useEffect(() => {
        setFormErrors(errors);
    }, [errors]);

    const [isNameInvalid, nameErrors] = getFieldErrors(
        formErrors, 'name');

    return (
        <BasicForm formTitle={isEditing ? 'Edit configuration' : 'Add configuration'}>
            <ScrollContainer>
                <form id="addConfigurationForm" onSubmit={handleSubmit}>
                    <div>
                        <TextField
                            autoFocus
                            disabled={savingOn}
                            error={isNameInvalid}
                            fullWidth
                            id="nameId"
                            inputProps={{
                                maxLength: 128,
                            }}
                            label="Configuration name"
                            variant="outlined"
                            value={name}
                            onChange={ev => setName(ev.target.value)}
                        />
                        {isNameInvalid && <p className="error">{nameErrors}</p>}
                    </div>
                    {!isEditing && <div>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={false}
                                    disabled={savingOn}
                                    onChange={ev => console.log(ev.target.checked)}
                                />
                            }
                            label="With full data"
                        />
                    </div>}
                    {!!fetchAddError && <p className="error">{fetchAddError}</p>}
                </form>
            </ScrollContainer>
            <FormButtons
                disabled={savingOn || !name}
                idForm="addConfigurationForm"
                savingButtonText="Save configuration"
                savingOn={savingOn}
                onCancel={onCancel}
                onSubmit={handleSubmit}
            />
        </BasicForm>
    )
}
