import { useEffect, useMemo, useState } from 'react';
import {
  FormControlLabel,
  TextField,
  Switch,
  Typography,
} from '@mui/material';

import { getFieldErrors } from '../../../helpers/helpers';
import { PhotoTagList } from '../../tags';


export const EditPhotoTemplate = ({
  disabled,
  formErrors,
  groupAddedTagIds = [],
  isEditing,
  photoData,
  showGroupTagging,
  onChangeAttribute
}) => {
  const [canBeGroupTagged, setCanBeGroupTagged] = useState(true);
  const [prevGroupAddedTagIds, setPrevGroupAddedTagIds] = useState([]);

  useEffect(() => {
    if (canBeGroupTagged) {
      onChangeAttribute(photoData.id, 'tags', [
        ...photoData.tags.filter(tag => !prevGroupAddedTagIds.includes(tag)),
        ...groupAddedTagIds
      ]);
    }
    setPrevGroupAddedTagIds(groupAddedTagIds);
  }, [groupAddedTagIds]);

  const [
    isNameInvalid, nameErrors,
    isDescriptionInvalid, descriptionErrors,
  ] = useMemo(() => {
    return [
      ...getFieldErrors(formErrors, 'name'),
      ...getFieldErrors(formErrors, 'description'),
    ]
  }, [formErrors]);

  return (
    <>
      <div className="photos__img-wrapper">
        <img src={photoData.imageUrl} className="photos__img" alt="Added for vehicle" />
      </div>
      <div>
        <TextField
          disabled={disabled}
          error={isNameInvalid}
          fullWidth
          id="nameId"
          inputProps={{ maxLength: 255 }}
          label="Name"
          variant="outlined"
          value={photoData.name}
          onChange={ev => onChangeAttribute(photoData.id, 'name', ev.target.value)}
        />
        {isNameInvalid && <p className="error">{nameErrors}</p>}
      </div>
      <div>
        <TextField
          disabled={disabled}
          error={isDescriptionInvalid}
          fullWidth
          id="descriptionId"
          inputProps={{ maxLength: 400 }}
          label="Description"
          variant="outlined"
          value={photoData.description}
          onChange={ev => onChangeAttribute(photoData.id, 'description', ev.target.value)}
        />
        {isDescriptionInvalid && <p className="error">{descriptionErrors}</p>}
      </div>
      <div>
        {!isEditing && showGroupTagging && <div className="photos__tags-switch">
          <FormControlLabel
            control={
              <Switch
                checked={canBeGroupTagged}
                onChange={ev => setCanBeGroupTagged(ev.target.checked)}
              />
            }
            label={<Typography component="span" variant="subtitle2">Group tagging enabled</Typography>}
          />
        </div>}
        <h3>Individual tags</h3>
        <PhotoTagList
          addedTagIds={photoData.tags}
          onAddTag={(tag) => onChangeAttribute(
            photoData.id, 'tags', [...photoData.tags, tag.id])
          }
          onRemoveTag={(tag) => onChangeAttribute(
            photoData.id, 'tags', photoData.tags.filter(id_ => id_ !== tag.id))
          }
        />
      </div>
    </>
  )
}
