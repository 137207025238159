import { useEffect, useRef } from 'react';
import {
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';

import './ConfiguratorListItem.sass';

export const ConfiguratorListItem = ({
  canEdit,
  disabled,
  editDialog,
  icon,
  iconColor,
  isActive,
  isActiveVehicle,
  isEditDialogOpened,
  isExpandable,
  isExpanded,
  isRemoveDialogOpened,
  name,
  onEditClick,
  onExpandClick,
  onListItemClick,
  onRemoveClick,
  removeDialog,
}) => {
  const scrollToRef = useRef();

  useEffect(() => {
    if (isExpanded) {
      scrollToRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isExpanded]);

  return (
    <li className="list-item">
      <ListItemButton
        className="list-item__button"
        disabled={disabled}
        onClick={onListItemClick}
      >
        <ListItemIcon className="list-item__icon">
          {icon || <CircleIcon
            color={isActive ? 'primary' : iconColor || 'secondary'}
            fontSize="8px"
          />}
        </ListItemIcon>
        <ListItemText primary={name} />
        {canEdit && (
          <div className="flex-container--small">
            {editDialog && <IconButton
              aria-label="Edit"
              color="cancel"
              disabled={!isActiveVehicle || disabled}
              size="small"
              variant="outlined"
              onClick={onEditClick}
            >
              <ModeEditOutlinedIcon fontSize="small" />
            </IconButton>}
            {isExpandable && <IconButton
              aria-label={isExpanded ? 'Collapse' : 'Expand'}
              color="cancel"
              disabled={!isActiveVehicle || disabled}
              ref={isExpanded ? scrollToRef : null}
              size="small"
              variant="outlined"
              onClick={onExpandClick}
            >
              {isExpanded
                ? <ExpandLessIcon fontSize="small" />
                : <ExpandMoreIcon fontSize="small" />
              }
            </IconButton>}
            <IconButton
              aria-label="Delete"
              color="cancel"
              disabled={!isActiveVehicle || disabled}
              size="small"
              variant="outlined"
              onClick={onRemoveClick}
            >
              <DeleteOutlineOutlinedIcon fontSize="small" />
            </IconButton>
          </div>
        )}
      </ListItemButton>
      {isRemoveDialogOpened && isActiveVehicle && removeDialog}
      {isEditDialogOpened && isActiveVehicle && editDialog}
    </li>
  );
}
