import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  AddPhotoAlternate,
  CheckCircleOutlined,
  DeleteForever,
  Edit,
  PanoramaPhotosphere,
} from '@mui/icons-material';
import { Button, Tooltip } from "@mui/material";
import { ConfiguratorPreviewModeProvider } from '../../../context/configuratorContext';
import { UserContext } from "../../../context/userContext";
import { getVehicleFullName } from '../../../helpers/helpers';
import {
  useAddPageTitle,
  useCleanForcedReadOnlyMode,
  useDeleteData,
  useGetData
} from '../../../helpers/hooks';
import { API_URLS } from '../../../urls/backend';
import { URLS } from '../../../urls/frontend';
import {
  Breadcrumbs,
  DetailsHeader,
  Dialog,
  Loader,
  MainTemplate,
  ScrollContainer,
  VehicleInfo,
} from "../../common";
import { Legend } from '../../systems/Configurator/Legend';
import { WebGLEngine } from '../../systems/Configurator/WebGLEngine';
import { AddPanoramaForm, Positions } from "..";
import './PanoramaDetails.sass';

const PANORAMA_POSITIONS_COUNT = 6;


export const PanoramaDetails = () => {
  const [isEditDialogOpened, setIsEditDialogOpened] = useState(false);
  const [isPreviewOpened, setIsPreviewOpened] = useState(false);
  const [isRemoveDialogOpened, setIsRemoveDialogOpened] = useState(false);
  const [removeFetchError, setRemoveFetchError] = useState(null);
  const [vehicle, setVehicle] = useState({});
  const [removingOn, setRemovingOn] = useState(false);
  const [panoramaDetails, setPanoramaDetails] = useState({});
  const [addedPositionsNumber, setAddedPositionsNumber] = useState(0);
  const {
    setForcedReadOnlyModeAllowed,
    setVehiclePermission,
    user,
    vehiclePermission
  } = useContext(UserContext);
  const navigate = useNavigate();
  const { vehicleId, panoramaPhotoId } = useParams();

  useAddPageTitle(`${panoramaDetails.name || ''} | ${vehicle.model || ''} | JetVar`);

  useCleanForcedReadOnlyMode(setForcedReadOnlyModeAllowed);

  const { fetchingOn, fetchError } = useGetData({
    url: API_URLS.panoramaPhotosGetDetails.getUrl(panoramaPhotoId),
    callbackSuccess: ({data}) => {
      if (Object.keys(data).length === 0) return;

      setVehicle(data.vehicle);
      setPanoramaDetails(data.panoramaPhoto);
      setVehiclePermission(vehicleId, data.permission);
    }
  });

  useDeleteData({
    removingOn,
    url: API_URLS.panoramaPhotosRemove.getUrl(panoramaPhotoId),
    callbackSuccess: ({ data, status }) => {
      if (status === 400) {
        setRemoveFetchError(data.error);
        setRemovingOn(false)
      } else {
        navigate(URLS.vehiclesGetDetails.getUrl(vehicleId));
      }
    },
    callbackError: ({ error }) => {
      setRemoveFetchError(error);
      setRemovingOn(false);
    },
  });

  const checkAddedPositionNumber = (obj) => {
    if (Object.keys(obj).length === 0) return 0;

    const values = Object.values(obj);
    return values.filter(str => str.length !== 0).length;
  }

  useEffect(() => {
    setAddedPositionsNumber(checkAddedPositionNumber(panoramaDetails?.images || {}));
  }, [panoramaDetails?.images]);

  const { current: permission } = vehiclePermission;

  return (
    <MainTemplate>
      <Breadcrumbs
        breadcrumbs={[
          [URLS.home.path, 'My aircrafts'],
          [URLS.vehiclesGetDetails.getUrl(vehicleId), getVehicleFullName(vehicle)],
        ]}
        currentLocation={panoramaDetails.name}
      />

      {fetchingOn
        ? <Loader />
        : (fetchError !== null
          ? <p className="error">{fetchError}</p>
          : (
              <>
                <VehicleInfo
                  isActive={vehicle.isActive}
                  vehicleDetails={vehicle}
                />
                <DetailsHeader
                  actionButtons={user.isAdmin && (
                    <div className="flex-container">
                      <Button
                        color="secondary"
                        startIcon={<DeleteForever />}
                        variant="outlined"
                        onClick={() => setIsRemoveDialogOpened(true)}
                      >
                        Remove
                      </Button>
                      <Button
                        startIcon={<Edit />}
                        variant="contained"
                        onClick={() => setIsEditDialogOpened(true)}
                      >
                        Edit
                      </Button>
                    </div>
                  )}
                  image={null}
                  model={panoramaDetails.name}
                >
                  <div className="details-header__details-container">
                    <div className="flex-container expand">
                      <p className="details-header__details__item">
                        <Tooltip title={addedPositionsNumber === PANORAMA_POSITIONS_COUNT ? "Complete" : "Not complete"}>
                          {addedPositionsNumber === PANORAMA_POSITIONS_COUNT
                            ? <><AddPhotoAlternate color="primary" /><span>added 6/6</span><CheckCircleOutlined color="success" /></>
                            : <><AddPhotoAlternate color="secondary" sx={{opacity: '.4'}}/><span>{`added ${addedPositionsNumber}/${PANORAMA_POSITIONS_COUNT}`}</span></>}
                        </Tooltip>
                      </p>
                      {addedPositionsNumber === PANORAMA_POSITIONS_COUNT &&
                        <Button
                          size="small"
                          sx={{margin: '0 auto .2rem 0'}}
                          startIcon={<PanoramaPhotosphere />}
                          variant="contained"
                          onClick={() => setIsPreviewOpened(true)}
                        >
                          Preview
                        </Button>
                      }
                    </div>
                  </div>
                </DetailsHeader>
                <Positions
                  permission={permission}
                  positions={panoramaDetails.images}
                  onAddedPosition={(name, url) => 
                    setPanoramaDetails(prevVal => ({
                      ...prevVal, images: {...prevVal.images, [name]: url}
                    }))
                  }
                  />
                {isRemoveDialogOpened &&
                  <Dialog
                    confirmationText="Remove"
                    dialogTitle="Remove panorama"
                    onCancel={() => setIsRemoveDialogOpened(false)}
                    onCloseDialog={() => setIsRemoveDialogOpened(false)}
                    onConfirm={() => setRemovingOn(true)}
                  >
                    <ScrollContainer>
                      <p>Are you sure you want to remove this panorama?</p>
                      {removeFetchError !== null && <p className="error">{removeFetchError}</p>}
                    </ScrollContainer>
                  </Dialog>
                }
                {isEditDialogOpened &&
                  <Dialog onCloseDialog={() => setIsEditDialogOpened(false)}>
                    <AddPanoramaForm
                      addCallbackSuccess={(data) => {
                        setPanoramaDetails(data);
                        setIsEditDialogOpened(false);
                      }}
                      initialName={panoramaDetails.name}
                      isEditing
                      panoramaPhotoId={panoramaPhotoId}
                      vehicleId={vehicleId}
                      onCancel={() => setIsEditDialogOpened(false)}
                    />
                  </Dialog>
                }
                {isPreviewOpened &&
                  <Dialog
                    cancelText="Close"
                    dialogTitle="Panorama preview"
                    onCancel={() => setIsPreviewOpened(false)}
                    onCloseDialog={() => setIsPreviewOpened(false)}
                  >
                    <div className="configurator__panorama-container positions__preview">
                      <ConfiguratorPreviewModeProvider>
                        <Legend className="legend--preview" />
                        <WebGLEngine
                          panoramaImages={panoramaDetails.images}
                          previewMode={true}
                        />
                      </ConfiguratorPreviewModeProvider>
                    </div>
                  </Dialog>
                }
              </>
          )
        )
      }
    </MainTemplate>
  );
};
