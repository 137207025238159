import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Tab,
  Tabs,
  TextField
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import { VehicleTagsContext } from '../../../context/vehicleTagsContext';
import { useGetData } from '../../../helpers/hooks';
import { API_URLS } from '../../../urls/backend';
import { Loader } from '../../common';
import { Photo } from '../../photos';
import { PhotoTagList } from '../../tags';
import './PhotoList.sass';


const MODE_FILTERS = {
  ALL: 'ALL',
  USED: 'USED',
  UNUSED: 'UNUSED',
};
const MODE_INDEXES = {
  'ALL': 0,
  'USED': 1,
  'UNUSED': 2,
};
const DEFAULT_FILTERS = {
  mode: MODE_FILTERS.ALL,
  query: '',
  tags: [],
};
const PAGINATE_BY = 50;


export const PhotoList = ({
  addButtonDisabled,
  canEditPhoto,
  canSelectPhoto,
  componentPhotosIds = [],
  counters,
  photos,
  scrollableElementRef,
  selectedPhotosIds = [],
  setCountersFunction,
  setPhotosFunction,
  withAddButton,
  vehicleId,
  onAddPhotosClick,
  onDeletePhoto,
  onFetchingOnChange,
  onFiltersChange,
  onLoadPhotos,
  onSavePhoto,
  onSelectPhoto
}) => {
  const [hasNextPage, setHasNextPage] = useState(true);
  const [requestId, setRequestId] = useState(1);
  const [lastId, setLastId] = useState(null);
  const [filters, setFilters] = useState({...DEFAULT_FILTERS});
  const [query, setQuery] = useState(DEFAULT_FILTERS.query);

  const {
    vehicleTags,
    setVehicleTags,
  } = useContext(VehicleTagsContext);

  const scrollableElement = scrollableElementRef?.current || document;

  const { fetchingOn, fetchError } = useGetData({
    url: API_URLS.photosList.getUrl(vehicleId),
    body: {
      ...(lastId ? {last_id: lastId} : {}),
      paginate_by: PAGINATE_BY,
      ...filters
    },
    requestId,
    callbackSuccess: ({ data }) => {
      if (data.photos?.length) {
        setPhotosFunction(prevVal => [...prevVal, ...data.photos]);
        setHasNextPage(true);
      } else {
        setHasNextPage(false);
      }
      // counters
      const used = data.counters?.used || 0;
      const unused = data.counters?.unused || 0;
      setCountersFunction({
        all: used + unused,
        used: used,
        unused: unused,
      });
      onLoadPhotos && onLoadPhotos(data);
    },
  });
  const lastPhotoRef = useRef();

  useEffect(() => {
    onFetchingOnChange && onFetchingOnChange(fetchingOn);
  }, [fetchingOn]);

  // Get tags for vehicle
  const { fetchingOn: fetchingTagsOn, fetchError: fetchTagsError } = useGetData({
    url: API_URLS.tagsGetForVehicle.getUrl(vehicleId),
    callbackSuccess: ({ data }) => {
      setVehicleTags(data.tags);
    },
  });

  // Lazy load photos
  const handleScroll = useCallback((e) => {
    if (!lastPhotoRef) { return }
    if (lastPhotoRef.current?.getBoundingClientRect().bottom <= window.innerHeight && hasNextPage) {
      setLastId(photos[photos.length - 1].id);
      setRequestId(prevVal => prevVal + 1);
      scrollableElement.removeEventListener('scroll', handleScroll);
    }
  }, [hasNextPage, lastPhotoRef, photos]);

  useEffect(() => {
    scrollableElement.removeEventListener('scroll', handleScroll);
    scrollableElement.addEventListener('scroll', handleScroll);
    return () => scrollableElement.removeEventListener('scroll', handleScroll);
  }, [photos]);

  useEffect(() => {
    onFiltersChange && onFiltersChange();
  }, [filters]);

  function handleChangeFilter(name, value) {
    setFilters(prevVal => ({...prevVal, [name]: value}));
    setPhotosFunction([]);
    setLastId(null);
    setRequestId(prevVal => prevVal + 1);
  }

  return (
    <section className="photos">
      <div className="flex-container--between flex-container--center photos__header">
        <Tabs
          value={MODE_INDEXES[filters.mode]}
          indicatorColor="primary"
          textColor="primary"
          aria-label="Photos filter"
        >
          <Tab
            component="button"
            disabled={fetchingOn || filters.mode === MODE_FILTERS.ALL}
            label={`ALL (${counters.all})`}
            onClick={() => handleChangeFilter('mode', MODE_FILTERS.ALL)}
          />
          <Tab
            component="button"
            disabled={fetchingOn || filters.mode === MODE_FILTERS.USED}
            label={`In Use (${counters.used})`}
            onClick={() => handleChangeFilter('mode', MODE_FILTERS.USED)}
          />
          <Tab
            component="button"
            disabled={fetchingOn || filters.mode === MODE_FILTERS.UNUSED}
            label={`Unused (${counters.unused})`}
            onClick={() => handleChangeFilter('mode', MODE_FILTERS.UNUSED)}
          />
        </Tabs>
        <div className="photos__search">
          <div className="flex-container">
            <TextField
              disabled={fetchingOn}
              id="queryId"
              inputProps={{ maxLength: 50 }}
              label="Query"
              variant="outlined"
              value={query}
              onChange={ev => setQuery(ev.target.value)}
            />
            <Button
              disabled={fetchingOn || (query.length > 0 && query.length < 3)}
              startIcon={<SearchOutlinedIcon />}
              type="button"
              variant="contained"
              onClick={() => handleChangeFilter('query', query)}
            >
              {!!filters.query && !query ? 'Show all' : 'Search' }
            </Button>
          </div>
          {!!filters.query && <p className="info photos__query-info">Photos filtered by: "{filters.query}"</p>}
        </div>
        {withAddButton &&
          <Button
            disabled={addButtonDisabled || filters.mode === MODE_FILTERS.USED || fetchError || fetchingOn}
            startIcon={<AddIcon />}
            variant="contained"
            onClick={onAddPhotosClick}
          >
            Add photos
          </Button>
        }
      </div>
      {fetchingTagsOn
        ? <Loader />
        : (fetchTagsError !== null
          ? <p className="error">{fetchTagsError}</p>
          : (
            <>
              {vehicleTags.length > 0 ? (
                <div>
                  <h3>Filter by tags</h3>
                  <div className="flex-container flex-container--center">
                    <PhotoTagList
                      addedTagIds={filters.tags}
                      onAddTag={(tag) => {
                        handleChangeFilter('tags', [...filters.tags, tag.id]);
                      }}
                      onRemoveTag={(tag) => {
                        handleChangeFilter('tags', filters.tags.filter(id_ => id_ !== tag.id));
                      }}
                    />
                    <Button
                      startIcon={<ClearOutlinedIcon />}
                      size="small"
                      onClick={() => {
                        handleChangeFilter('tags', []);
                      }}
                    >
                      Clear filters
                    </Button>
                  </div>
                </div>
              ) : <p className="info">There are no tags added for this plane.</p>}
            </>
          )
        )
      }
      <div className="photo-list">
        {photos.length > 0
          ? (
            <>
              <ul className="photos">
                {photos.map(photo =>
                  <li key={photo.id} ref={photos[photos.length - 1].id === photo.id ? lastPhotoRef : null}>
                    <Photo
                      canEdit={canEditPhoto}
                      canSelect={canSelectPhoto}
                      initialSelected={canSelectPhoto && componentPhotosIds.includes(photo.id)}
                      photo={photo}
                      selected={canSelectPhoto && selectedPhotosIds.includes(photo.id)}
                      onDelete={onDeletePhoto}
                      onSave={onSavePhoto}
                      onSelect={onSelectPhoto}
                    />
                  </li>
                )}
              </ul>
              {!!fetchError && <p className="error">{fetchError}</p>}
            </>
          ) : fetchError !== null
            ? <p className="error">{fetchError}</p>
            : !fetchingOn ? <p className="info">There are no photos yet</p> : null
        }
        {fetchingOn && <Loader/>}
      </div>
    </section>
  )
}
