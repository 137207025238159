import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Image } from '@mui/icons-material';

import { getFieldErrors } from '../../../helpers/helpers';
import {
    useFetchError,
    usePostWithFileData,
    useValidatingForm
} from '../../../helpers/hooks';
import { API_URLS } from '../../../urls/backend';
import { validate } from '../../../validators/PositionForm';
import { BasicForm, FormButtons, ImageInput, ScrollContainer } from '../../common';


export const AddPositionForm = ({
    addCallbackSuccess,
    onCancel,
    positionName,
}) => {
    const [validatingOn, setValidatingOn] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [savingOn, setSavingOn] = useState(false);
    const [fetchError, setFetchError] = useState(null);
    const [imageName, setImageName] = useState(null);

    const fileInputRef = useRef();
    const { panoramaPhotoId } = useParams();

    const handleSubmit = (ev) => {
        ev.preventDefault();
        setValidatingOn(true);
    }

    const onChangeFile = (ev) => {
        const file = ev.target.files[0]; 
        if (file) {
            setImageName(file.name);
            setFormErrors({});
        };
    }

    let imgFile = null;
    if (fileInputRef.current?.files.length) {
        imgFile = fileInputRef.current.files[0];
    }

    useValidatingForm({
        data: { image: imgFile },
        validateFunction: validate,
        validatingOn,
        setFormErrors,
        setSavingOn,
        setValidatingOn,
    });

    const { errors } = usePostWithFileData({
        savingOn,
        image: imgFile,
        body: {
            panorama_photo_id: panoramaPhotoId,
            position: positionName,
        },
        url: API_URLS.panoramaPhotosSaveImage.path,
        setSavingOn,
        callbackSuccess: ({ data, status }) => {
          status === 200 && addCallbackSuccess(data);
        },
        callbackError: ({ error }) => setFetchError(error),
    });

    useEffect(() => {
        setFormErrors(errors);
    }, [errors]);

    useFetchError({ fetchError, setFetchError, timeoutValue: 5000 });

    const [isImageInvalid, imageErrors] = getFieldErrors(formErrors, 'image');

    return (
        <BasicForm formTitle={`Add ${positionName.toUpperCase()} image`}>
            <ScrollContainer>
                <form id="addPositionForm" onSubmit={handleSubmit}>
                    <ImageInput
                        disabled={savingOn}
                        isUploadingSingleFile
                        refElement={fileInputRef}
                        onAddFile={onChangeFile}
                    />
                    {imageName &&
                        <div className="flex-container-no-margin">
                            <Image color="primary" fontSize="small" />
                            <p className="info">{imageName}</p>
                        </div>}
                    {!!isImageInvalid && <p className="error">{imageErrors}</p>}
                    {!!fetchError && <p className="error" >{fetchError}</p>}
                </form>
            </ScrollContainer>
            <FormButtons
                disabled={savingOn || !imageName}
                idForm="addPositionForm"
                savingButtonText={`Save ${positionName} image`}
                savingOn={savingOn}
                onCancel={onCancel}
                onSubmit={handleSubmit}
            />
        </BasicForm>
    )
}
