import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  CardMedia,
  Chip,
  Grid,
  List,
  ListItem,
  Tooltip,
  Typography,
} from '@mui/material';
import { Add, CheckCircle, NotInterested } from '@mui/icons-material';

import { UserContext } from '../../../context/userContext';
import { THUMBNAILS_SIZES } from '../../../helpers/consts';
import { getThumbnailUrl, getVehicleFullName } from '../../../helpers/helpers';
import { useAddPageTitle, useGetData } from '../../../helpers/hooks';
import { API_URLS } from '../../../urls/backend';
import { URLS } from '../../../urls/frontend';
import { Dialog, Loader } from '../../common';
import { AddVehicleForm } from '..';
import plane from '../../../assets/plane.png';

export const VehiclesList = () => {
  const [isAddDialogOpened, setIsAddDialogOpened] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const { data = {}, fetchingOn, fetchError } = useGetData({
    url: API_URLS.vehiclesGet.path,
  });

  useAddPageTitle('My aricrafts | JetVar');

  useEffect(() => {
    if (Object.keys(data).length === 0) return;

    setVehicles(data.vehicles.reverse());
  }, [data]);

  return (
    <>
      {user.isAdmin &&
        <Button
          startIcon={<Add />}
          sx={{ display: 'flex', padding: '.7rem 1rem', ml: 'auto', mt: '1.2rem', lineHeight: 'initial'}}
          variant="contained"
          onClick={() => setIsAddDialogOpened(true)}
        >
          Add Vehicle
        </Button>
      }
      <Typography
        className="basic-heading"
        sx={{mt: '2.4rem', mb: '2.8rem'}}
        variant="h4" component='h2'
      >
        Vehicles
        <Chip
          label={vehicles.length}
          size="small"
          sx={{ml: '.8rem', fontSize: '.9rem', fontWeight: '700', transform: 'translateY(1px)'}}
          variant="outlined"
        />
      </Typography>

      {fetchingOn
        ? <Loader />
        : (fetchError !== null
          ? <p className="error">{fetchError}</p>
          : (
              <>
                {vehicles.length > 0
                  ? (
                    <Grid component={List} container spacing={3} sx={{mt: '.5rem'}}>
                      {vehicles.map((vehicle) => (
                        <Grid
                          component={ListItem}
                          item
                          key={vehicle.id}
                          lg={3}
                          md={4}
                          sm={6}
                          xs={12}
                          sx={{justifyContent: 'center'}}
                        >
                          <Link to={URLS.vehiclesGetDetails.getUrl(vehicle.id)}>
                              <Card
                                className={!vehicle.isActive ? "card-plane__disabled" : "card-plane"}
                                sx={{boxShadow: '0px 0px 7px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'}}
                              >
                                <CardMedia
                                  alt="airbus plane"
                                  className="card-plane__image"
                                  component="img"
                                  height="200"
                                  image={getThumbnailUrl(vehicle.imageUrl, THUMBNAILS_SIZES.L) || plane}
                                />
                                <div className="card-plane__content">
                                  <div className="card-plane__title">
                                    <Tooltip title={vehicle.model.length > 25 ? vehicle.model : ""}>
                                      <Typography noWrap variant= 'subtitle1' sx={{pr: '1rem', fontWeight: '600'}}>
                                        {getVehicleFullName(vehicle)}
                                      </Typography>
                                    </Tooltip>
                                    {user.isAdmin &&
                                      <>
                                        {vehicle.isActive && <Tooltip title="Active">
                                          <CheckCircle color='primary' aria-label="active"/>
                                        </Tooltip>}
                                        {!vehicle.isActive && <Tooltip title="Inactive">
                                        <NotInterested color='secondary' sx={{opacity: ".3"}} aria-label="inactive"/>
                                        </Tooltip>}
                                      </>
                                    }
                                  </div>
                                  <p className="details-header__details__item">
                                    <Tooltip title="Permission">
                                      <span className="color-badge">{vehicle.permission.replace('_', ' & ')}</span>
                                    </Tooltip>
                                  </p>
                                </div>
                              </Card>
                          </Link>
                        </Grid>
                      ))}
                    </Grid>
                  ) : <p className="info">There are no vehicles yet</p>
                }
                {isAddDialogOpened && (
                  <Dialog>
                    <AddVehicleForm
                      addCallbackSuccess={(data) => navigate(URLS.vehiclesGetDetails.getUrl(data.id))}
                      onCancel={() => setIsAddDialogOpened(false)} />
                  </Dialog>
                )}
              </>
            )
          )
      }
    </>
  );
};
