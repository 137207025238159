import { useContext, useEffect, useRef } from 'react';
import {
  Button,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { ConfiguratorContext } from '../../../context/configuratorContext';
import './OuterPanoramaConfiguratorListItem.sass';

export const OuterPanoramaConfiguratorListItem = ({
  component,
  isExpanded,
  panorama,
  panoramas,
  onExpandListItem,
}) => {
  const scrollToRef = useRef();
  const outerSelectedPanorama = panoramas?.filter(p => p.id === panorama.id)[0];

  const {
    setOuterSelectedComponentId,
    setSelectedPanorama,
  } = useContext(ConfiguratorContext);

  useEffect(() => {
    if(isExpanded) {
      scrollToRef.current?.scrollIntoView({behavior: 'smooth'});
    }
  }, [isExpanded]);

  return (
    <li className="outer-list-item">
      <ListItemButton
        className="outer-list-item__button"
        onClick={onExpandListItem}
      >
        <ListItemIcon className="outer-list-item__icon">
          <CircleIcon
            color="#ccc"
            fontSize="8px"
          />
        </ListItemIcon>
        <ListItemText primary={component.name} />
        <div className="flex-container--small">
          <IconButton
            aria-label={isExpanded ? 'Collapse' : 'Expand'}
            color="cancel"
            ref={isExpanded ? scrollToRef : null}
            size="small"
            variant="outlined"
          >
            {isExpanded
              ? <ExpandLessIcon fontSize="small" />
              : <ExpandMoreIcon fontSize="small" />
            }
          </IconButton>
        </div>
      </ListItemButton>
      {isExpanded &&
        <div className="component-list__outer-expanded">
          {outerSelectedPanorama ?
            <Button
              variant="contained"
              onClick={() => {
                setSelectedPanorama(outerSelectedPanorama);
                setOuterSelectedComponentId(component.id);
              }}
            >
              Go to this component
            </Button> : <p className="error">Please refresh the page</p>
          }
        </div>
      }
    </li>
  );
}
