import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  AccountCircle,
  Key,
  Logout,
} from '@mui/icons-material';
import {
  AppBar,
  FormControlLabel,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Switch,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';

import { UserContext } from '../../../context/userContext';
import { PERMISSION } from '../../../helpers/consts';
import { URLS } from '../../../urls/frontend';
import { LogoutWidget } from '../../accounts';
import Logo from '../../../assets/logo.png';
import './NavBar.sass';


export const NavBar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const {
    forcedReadOnlyModeAllowed,
    readOnlyModeOn,
    setReadOnlyModeOn,
    user,
    vehiclePermission
  } = useContext(UserContext);

  return (
    <AppBar color="secondary" position="fixed">
      <Toolbar>
        <Typography variant="h6" component="h1" >
          <Link to="/"><img alt="JetVar" className="main-header__logo" src={Logo} /></Link>
        </Typography>
        <Typography component={Link} to={URLS.home.path} variant="subtitle2" sx={{ ml: '3rem', mr: 'auto', fontWeight: '700'}}>My aircrafts</Typography>
        {user.isAuthenticated ? (
          <>
            {forcedReadOnlyModeAllowed && vehiclePermission.original === PERMISSION.READ_WRITE &&
              <div className="user__switch">
                <FormControlLabel
                  control={
                    <Switch
                      checked={readOnlyModeOn}
                      onChange={ev => setReadOnlyModeOn(ev.target.checked)}
                    />
                  }
                  label="READ ONLY mode"
                />
              </div>
            }
            <div className="user">
              {user.isAdmin && <div className="user__isAdmin-label">Admin</div>}
              <Typography className="user__name" variant="subtitle2" component="span" sx={{ flexGrow: 1, fontWeight: '700' }}>
                {user.username}
              </Typography>
              <Tooltip title="Account settings">
                <IconButton
                  onClick={(ev) => setAnchorEl(ev.currentTarget)}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                >
                  <AccountCircle sx={{ color: '#fff' }}/>
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={() => setAnchorEl(null)}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    maxWidth: '13rem',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                  <Typography className="user__name--mobile" variant="subtitle2" component="span" sx={{ maxWidth: '12rem', padding: '.5rem 1rem', fontWeight: '700', wordWrap: 'break-word' }}>{user.username}</Typography>
                <MenuItem>
                  <ListItemIcon>
                    <Key fontSize="small" />
                  </ListItemIcon>
                  <Link to={URLS.accountsChangePassword.path}>Change password</Link>
                </MenuItem>
                <MenuItem>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  <LogoutWidget />
                </MenuItem>
              </Menu>
            </div>
          </>
        ) : <Link to={URLS.accountsLogin.path}>Log in</Link>}
      </Toolbar>
    </AppBar>
  );
}
