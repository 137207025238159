import clsx from 'clsx';
import { Button } from '@mui/material';

import { Loader } from '../../common';
import './FormButtons.sass';

export const FormButtons = ({
  cancelText,
  className,
  disabled,
  disabledSavingButton,
  idForm,
  noCancel,
  savingButtonText,
  savingOn,
  onCancel,
  onSubmit,
}) => {
  const containerClassName = clsx([
    'flex-column-container',
    className,
  ]);

  return (
    <>
      <div className={containerClassName}>
        <Button
          disabled={disabled || disabledSavingButton}
          form={idForm}
          fullWidth
          type="submit"
          variant="contained"
          onClick={onSubmit}
        >
          {savingOn ? <Loader isButton /> : savingButtonText}
        </Button>
        {!noCancel && <Button fullWidth variant="outlined" onClick={onCancel}>{cancelText || 'Cancel'}</Button>}
     </div>
    </>
  )
}
