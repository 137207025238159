import { Dialog } from '../../common';


export const PhotoPreview = ({ photo, onClose }) => {
  return (
    <Dialog cancelText="Close" onCancel={onClose} onCloseDialog={onClose}>
      <div className="photos__preview">
        <img
          src={photo.imageUrl}
          alt={photo.name}
        />
        <p className="photos__preview-name">{photo.name}</p>
        <p className="photos__preview-desc">{photo.description || '-'}</p>
      </div>
    </Dialog>
  )
}
