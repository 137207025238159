import { useEffect, useState } from 'react';

import { usePostData, useValidatingForm } from '../../../helpers/hooks';
import { API_URLS } from '../../../urls/backend';
import { validate } from '../../../validators/PhotoForm';
import { BasicForm, FormButtons, ScrollContainer } from '../../common';
import { EditPhotoTemplate } from '..';


export const EditPhotoForm = ({
  initialData,
  photoId,
  onCancel,
  onSave
}) => {
  const [formData, setFormData] = useState(initialData);
  const [formErrors, setFormErrors] = useState({});
  const [validatingOn, setValidatingOn] = useState(false);
  const [savingOn, setSavingOn] = useState(false);

  useValidatingForm({
    data: formData,
    validateFunction: validate,
    validatingOn,
    setFormErrors,
    setSavingOn,
    setValidatingOn,
  });

  function handleChangeAttribute(photoId, name, value) {
    setFormData(prevVal => ({...prevVal, [name]: value}));
  }

  function handleSubmit(ev) {
    ev.preventDefault();
    setValidatingOn(true);
  }

  const { fetchError, errors } = usePostData({
    url: API_URLS.photosEdit.getUrl(photoId),
    method: 'PUT',
    postData: formData,
    savingOn,
    setSavingOn,
    callbackSuccess: ({data}) => onSave(data.photo),
  });

  useEffect(() => {
    if (Object.keys(errors).length) {
      setFormErrors(errors);
    }
  }, [errors]);

  return (
    <BasicForm formTitle="Edit photo">
      <ScrollContainer>
        <form id="editPhotoForm" onSubmit={handleSubmit}>
          <EditPhotoTemplate
            disabled={savingOn}
            formErrors={formErrors}
            isEditing
            photoData={formData}
            onChangeAttribute={handleChangeAttribute}
          />
          {!!fetchError && <p className="error">{fetchError}</p>}
        </form>
      </ScrollContainer>
      <FormButtons
        disabled={savingOn}
        idForm="editPhotoForm"
        savingButtonText="Save photo"
        savingOn={savingOn}
        onCancel={onCancel}
        onSubmit={handleSubmit}
      />
    </BasicForm>
  )
}
