import { useContext, useEffect, useMemo, useState} from 'react';
import { Button, FormControlLabel, Switch, TextField } from '@mui/material';
import clsx from 'clsx';

import { ConfiguratorContext } from '../../../context/configuratorContext';
import { UserContext } from '../../../context/userContext';
import { PERMISSION } from '../../../helpers/consts';
import { getFieldErrors } from '../../../helpers/helpers';
import { usePostData, useValidatingForm } from '../../../helpers/hooks';
import { API_URLS } from '../../../urls/backend';
import { validate, validateNewComponent } from '../../../validators/ComponentForm';
import { BasicForm, FormButtons } from '../../common';
import { ComponentPhotoList } from '../../photos';


const getInitialData = (selectedComponent, temporaryComponent, isTemporary) => {
  const component = isTemporary ? temporaryComponent : selectedComponent
  return {
    name: component.name,
    description: component.description,
    is_visible: component.isVisible,
    coordinates: component.coordinates,
    size: {h: 1, w: 1},
    ...(isTemporary ? {} : {photos: component.photos || []}),
  }
};

const getMovingComponentFormData = (componentData) => {
  const { isMoving, newCoordinates, ...others } = componentData;
  return {
    ...others,
    coordinates: newCoordinates,
    photos: componentData.photos.map(photo => photo.id)
  }
};


export const ComponentForm = ({ isTemporary = false }) => {
  const {
    addComponent,
    editComponent,
    markInputFocused,
    selectedComponent,
    selectedPanorama,
    selectedSet,
    setSelectedComponent,
    temporaryComponent,
    setTemporaryComponent
  } = useContext(ConfiguratorContext);
  const { current: permission } = useContext(UserContext).vehiclePermission;

  const [formData, setFormData] = useState(
    getInitialData(selectedComponent, temporaryComponent, isTemporary));
  const [formErrors, setFormErrors] = useState({});
  const [validatingOn, setValidatingOn] = useState(false);
  const [savingOn, setSavingOn] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (!isTemporary || !temporaryComponent?.coordinates) { return }
    setFormData(prevVal => (
      {...prevVal, coordinates: temporaryComponent.coordinates}))
  }, [temporaryComponent?.coordinates]);

  function handleChangeFormAttribute(attrName, value) {
    setFormData(prevState => ({ ...prevState, [attrName]: value }));
    setIsChanged(true);
  }

  const [isMoving, newCoordinates] = useMemo(() => {
    return !selectedComponent
      ? [false, null]
      : [selectedComponent.isMoving || false, selectedComponent.newCoordinates]
  }, [selectedComponent]);

  useEffect(() => {
    if (newCoordinates) {
      setSavingOn(true);
    }
  }, [newCoordinates]);

  function handleSubmit(ev) {
    ev.preventDefault();
    setValidatingOn(true);
  }

  useValidatingForm({
    data: formData,
    validateFunction: isTemporary ? validateNewComponent : validate,
    validatingOn,
    setFormErrors,
    setSavingOn,
    setValidatingOn,
  });

  const { errors, fetchError } = usePostData({
    savingOn,
    setSavingOn,
    url: isTemporary
      ? API_URLS.panoramaPhotosAddComponent.getUrl(
        selectedPanorama?.id, selectedSet?.id)
      : API_URLS.componentsEdit.getUrl(selectedComponent?.id),
    method: isTemporary ? 'POST' : 'PUT',
    postData: isTemporary
      ? formData
      : isMoving && newCoordinates
        ? getMovingComponentFormData(selectedComponent)
        : {...formData, photos: formData.photos.map(photo => photo.id)},
    callbackSuccess: ({ data }) => {
      if (isTemporary) {
        setTemporaryComponent(null);
        const component = data.component;
        addComponent(component);
        setSelectedComponent(component);
      } else {
        if (isMoving) {
          // update coordinates in formData
          handleChangeFormAttribute('coordinates', data.component.coordinates);
        }
        // set data saved on server, so clean unnecessary fields
        editComponent(data.component);
        setSelectedComponent(data.component);
      }
      setIsChanged(false);
    },
    callbackError: () => {
      isMoving && setSelectedComponent({...selectedComponent, isMoving: false});
    },
  });

  useEffect(() => {
    setFormErrors(errors);
  }, [errors]);

  function handleAddPhotos(selectedPhotos) {
    setFormData(prevState =>
      ({ ...prevState, photos: [...prevState.photos, ...selectedPhotos] }));
    setIsChanged(true);
  }

  function handleRemovePhoto(photoId) {
    setFormData(prevState => ({
      ...prevState,
      photos: prevState.photos.filter(photo => photo.id !== photoId),
    }));
    setIsChanged(true);
  }

  const [
    isNameInvalid, nameErrors,
    isDescriptionInvalid, descriptionErrors,
    isIsVisibleInvalid, isVisibleErrors,
    arePhotosInvalid, photosErrors,
  ] = useMemo(() => {
    return [
      ...getFieldErrors(formErrors, 'name'),
      ...getFieldErrors(formErrors, 'description'),
      ...getFieldErrors(formErrors, 'is_visible'),
      ...getFieldErrors(formErrors, 'photos'),
    ]
  }, [formErrors]);
  const formDisabled = savingOn || validatingOn || isMoving;

  const formClassName = clsx([
    'basic-form--in-content',
    isTemporary && 'basic-form--temporary',
  ]);

  return (
    <BasicForm formTitle={isTemporary ? 'Add new component' : null} className={formClassName}>
      <form id="componentForm" onSubmit={handleSubmit}>
        <div>
          <TextField
            disabled={formDisabled}
            error={isNameInvalid}
            fullWidth
            id="nameId"
            inputProps={{ maxLength: 255 }}
            label="Name"
            variant="outlined"
            value={formData.name}
            onBlur={() => markInputFocused(false)}
            onChange={ev => handleChangeFormAttribute('name', ev.target.value)}
            onFocus={() => markInputFocused(true)}
          />
          {isNameInvalid && <p className="error">{nameErrors}</p>}
        </div>
        <div>
          <TextField
            disabled={formDisabled}
            error={isDescriptionInvalid}
            fullWidth
            id="descriptionId"
            inputProps={{ maxLength: 400 }}
            label="Description"
            variant="outlined"
            value={formData.description}
            onBlur={() => markInputFocused(false)}
            onChange={ev => handleChangeFormAttribute('description', ev.target.value)}
            onFocus={() => markInputFocused(true)}
          />
          {isDescriptionInvalid && <p className="error">{descriptionErrors}</p>}
        </div>
        <div className="flex-container flex-container--between">
          <FormControlLabel
            control={
              <Switch
                checked={formData.is_visible || false}
                disabled={formDisabled}
                onChange={ev => handleChangeFormAttribute('is_visible', ev.target.checked)}
              />
            }
            label={formData.is_visible ? 'Visible' : 'Not visible'}
          />
          {!isTemporary && <Button
              disabled={savingOn || validatingOn}
              size="small"
              variant={selectedComponent?.isMoving ? 'contained' : 'outlined'}
              onClick={() => setSelectedComponent({ ...selectedComponent, isMoving: !selectedComponent.isMoving })}
            >Change position</Button>
          }
        </div>
        {isIsVisibleInvalid && <p className="error">{isVisibleErrors}</p>}
        {!isTemporary &&
          <>
            <ComponentPhotoList
              canEdit={permission === PERMISSION.READ_WRITE}
              disabled={formDisabled}
              photos={formData.photos.sort((p1, p2) => p1.id - p2.id)}
              onAddPhotos={handleAddPhotos}
              onRemovePhoto={handleRemovePhoto}
            />
            {arePhotosInvalid && <p className="error">{photosErrors}</p>}
          </>
        }
        <div>
          {!!fetchError && <p className="error">{fetchError}</p>}
        </div>
      </form>
      <FormButtons
        cancelText="Cancel"
        disabled={formDisabled || (!isTemporary && !isChanged)}
        idForm="componentForm"
        noCancel={!isTemporary}
        savingButtonText="Save component"
        savingOn={savingOn}
        onCancel={() => isTemporary
          ? setTemporaryComponent(null) : setSelectedComponent(null)
        }
        onSubmit={handleSubmit}
      />
    </BasicForm>
  )
}
