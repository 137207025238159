import { Component, createContext } from 'react';

export const VehicleTagsContext = createContext({
    vehicleTags: [],
    setVehicleTags: () => [],
});


export class VehicleTagsProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vehicleTags: [],
            setVehicleTags: this.setVehicleTags,
        };
    }

    setVehicleTags = (tags) => {
        this.setState({ vehicleTags: tags });
    }

    render() {
        return (
            <VehicleTagsContext.Provider value={this.state}>
                {this.props.children}
            </VehicleTagsContext.Provider>
        );
    }
}
