import { useContext, useState } from 'react';
import Button from '@mui/material/Button';

import { API_URLS } from '../../../urls/backend';
import { UserContext } from '../../../context/userContext';
import { usePostData } from '../../../helpers/hooks';
import { Loader } from '../../common';


export const LogoutWidget = () => {
    const [savingOn, setSavingOn] = useState(false);

    const { logout } = useContext(UserContext);

    usePostData({
        url: API_URLS.accountsLogout.path,
        savingOn,
        setSavingOn,
        callbackSuccess: logout,
        callbackError: logout,
    });

    return (
        <Button
            color="secondary"
            disabled={savingOn}
            variant="outlined"
            onClick={() => setSavingOn(true)}
            sx={{width: '100%'}}
        >
          { savingOn ? <Loader isButton /> : 'Log out' }
        </Button>
    )
}
