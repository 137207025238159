import { useContext, useState } from 'react';
import PanToolIcon from '@mui/icons-material/PanTool';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined';
import ZoomOutOutlinedIcon from '@mui/icons-material/ZoomOutOutlined';

import { ConfiguratorContext } from '../../../context/configuratorContext';
import { UserContext } from '../../../context/userContext';
import { PERMISSION } from '../../../helpers/consts';
import HandIcon from '../../../assets/panorama_hand.png';
import Location from '../../../assets/panorama_location.png';
import ZoomIn from '../../../assets/panorama_zoom.png';
import './Configurator.sass';


export const Legend = ({ className, showInstruction }) => {
  const [isInstructionVisible, setIsInstructionVisible] = useState(true);
  const {
    isPanoramaInRotatingMode,
    listeners,
    selectedSet,
    setZoom,
    zoom
  } = useContext(ConfiguratorContext);
  const { current: permission } = useContext(UserContext).vehiclePermission;

  function handleClickToggleCameraRotation() {
    window.dispatchEvent(new KeyboardEvent('keydown', {'code': 'Space'}))
  }

  return (
    <section className={`legend ${className || ''}`}>
      <div className="legend__shortcuts">
        {listeners.activatePreview &&
          <button
            className="legend__shortcut-item"
            onClick={handleClickToggleCameraRotation}
          >
            <PanToolIcon color={isPanoramaInRotatingMode ? 'primary' : 'secondary'} />
            <p>Press <b>Space</b> character to {isPanoramaInRotatingMode ? 'deactivate' : 'activate'} panorama preview</p>
          </button>
        }
        {listeners.markNewComponent && selectedSet &&
          <div className="legend__shortcut-item">
            <CenterFocusStrongIcon color={!isPanoramaInRotatingMode ? 'primary' : 'secondary'} style={{fontSize: '1.85rem'}} />
            <p>Press <b>a</b> letter to add a new component or click the <b>scroll button</b> on your mouse</p>
          </div>
        }
        <div className="flex-container">
          {!isPanoramaInRotatingMode &&
            <button
              className="legend__shortcut-item"
              disabled={zoom === 1}
              type="button" onClick={() => setZoom(false)}
            >
              <ZoomOutOutlinedIcon style={{fontSize: '1.9rem'}} />
              <p>Zoom out</p>
            </button>
          }
          <div className="legend__shortcut-item legend__shortcut-item--zoom">
            <span className={zoom > 1 ? 'legend__zoom--zoomed' : 'legend__zoom'}>{zoom * 100}%</span>
            <p>Press <b>+</b> to zoom in and <b>-</b> to zoom out</p>
          </div>
          {!isPanoramaInRotatingMode &&
            <button
              className="legend__shortcut-item"
              disabled={zoom === 5}
              type="button" onClick={() => setZoom(true)}
            >
              <ZoomInOutlinedIcon style={{fontSize: '1.9rem'}} />
              <p>Zoom in</p>
            </button>
          }
        </div>
      </div>
      {showInstruction && isInstructionVisible &&
        <button className="legend__instruction" type="buttom" onClick={() => setIsInstructionVisible(false)}>
          <ul className="legend__instruction-list">
            <li className="legend__instruction-item">
              <div className="legend__container">
                <img className="legend__image" alt="illustration of icon to move panorama" src={HandIcon} />
                <div className="legend__button-wrapper">
                  <span className="legend__button">SPACE</span>
                </div>
              </div>
              <span className="legend__instruction-main">To start / stop moving through panorama</span>
              <span className="legend__instruction-secondary">press Space on your keyboard</span>
            </li>
            {permission === PERMISSION.READ_WRITE &&
              <li className="legend__instruction-item">
                <div className="legend__container">
                  <img className="legend__image" alt="illustration of icon to move panorama" src={Location} />
                  <div className="legend__button-wrapper">
                    <span className="legend__button">A</span>
                  </div>
                </div>
                <span className="legend__instruction-main">To place component</span>
                <span className="legend__instruction-secondary">move your cursor to desired location and press A on your keyboard</span>
              </li>
            }
            <li className="legend__instruction-item">
              <div className="legend__container">
                <img className="legend__image" alt="illustration of icon to move panorama" src={ZoomIn} />
                <div className="legend__button-wrapper">
                  <span className="legend__button">-</span>
                  <span className="legend__button">+</span>
                </div>
              </div>
              <span className="legend__instruction-main">To zoom panorama</span>
              <span className="legend__instruction-secondary">press + or - on your keyboard</span>
            </li>
          </ul>
        </button>
      }
    </section>
  )
}
