import { useState } from 'react';
import { Chip, IconButton } from "@mui/material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import { useDeleteData } from '../../../helpers/hooks';
import { API_URLS } from '../../../urls/backend';
import { Dialog } from '../../common';
import { AddTagForm } from '..';
import '../TagList/TagList.sass';

export const Tag = ({
  isActiveVehicle,
  isAdmin,
  onDeleteItem,
  tag,
}) => {
  const [fetchError, setFetchError] = useState(null);
  const [forcedDelete, setForcedDelete] = useState(false);
  const [isEditDialogOpened, setIsEditDialogOpened] = useState(false);
  const [isRemoveDialogOpened, setIsRemoveDialogOpened] = useState(false);
  const [removingOn, setRemovingOn] = useState(false);
  const [tagDetails, setTagDetails] = useState(tag);

  useDeleteData({
    removingOn,
    url: API_URLS.tagsRemove.getUrl(tag.id),
    data: forcedDelete ? {force: 1} : {},
    callbackSuccess: (data) => {
      if (data.status === 400) {
        setFetchError(data.data.error);
        setForcedDelete(true);
        setRemovingOn(false);
      } else {
        onDeleteItem(tagDetails.id);
      }
    },
    callbackError: ({error}) => {
      setFetchError(error);
      setRemovingOn(false);
    },
  });

  const handleDelete = (e) => {
    e.preventDefault();
    setIsRemoveDialogOpened(true);
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setIsEditDialogOpened(true);
  };

  function handleCancelRemoving() {
    setFetchError(null);
    setForcedDelete(false);
    setRemovingOn(false);
    setIsRemoveDialogOpened(false);
  }

  return (
    <>
      <li className="tags-list__tag">
        <Chip
          color="primary"
          deleteIcon={<DeleteOutlineOutlinedIcon />}
          label={tagDetails.name}
          onDelete={(isAdmin && isActiveVehicle) ? handleDelete : null}
        />
        {isAdmin && isActiveVehicle &&
          <IconButton
            aria-label="Edit"
            className="tags-list__edit-btn"
            size="small"
            variant="contained"
            onClick={handleEdit}
          >
            <EditOutlinedIcon fontSize="inherit" />
          </IconButton>
        }
      </li>
      {isRemoveDialogOpened &&
        <Dialog
          confirmationText={forcedDelete ? 'Yes, remove' : 'Remove'}
          dialogTitle="Remove tag"
          onCancel={handleCancelRemoving}
          onConfirm={() => setRemovingOn(true)}
        >
          <p>Are you sure you want to remove this tag - name: {tagDetails.name}, id: {tagDetails.id}?</p>
          {fetchError !== null && <p className="error">{fetchError}</p>}
        </Dialog>
      }
      {isEditDialogOpened &&
        <Dialog>
          <AddTagForm
            addCallbackSuccess={(data) => {
              setTagDetails(data);
              setIsEditDialogOpened(false);
            }}
            initialName={tagDetails.name}
            isEditing
            onCancel={() => setIsEditDialogOpened(false)}
            tagId={tagDetails.id}
          />
        </Dialog>
      }
    </>
  );
};
