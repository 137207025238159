
export const API_URLS = {
    accountsChangePassword: {
        path: '/accounts/change-password',
    },
    accountsIdentify: {
        path: '/accounts/identify',
    },
    accountsLogin: {
        path: '/accounts/login',
    },
    accountsLogout: {
        path: '/accounts/logout',
    },
    componentsEdit: {
      getUrl: componentId => `/components/${componentId}/edit`,
    },
    componentsRemove: {
      getUrl: componentId => `/components/${componentId}/remove`,
    },
    configurationsAdd: {
        getUrl: vehicleId => `/vehicles/${vehicleId}/add-configuration`,
    },
    configurationsEdit: {
        getUrl: configurationId => `/configurations/${configurationId}/edit`,
    },
    configurationsGetDetails: {
        getUrl: configurationId => `/configurations/${configurationId}`,
    },
    configurationsRemove: {
        getUrl: configurationId => `/configurations/${configurationId}/remove`,
    },
    configurationsVRPackages: {
      getUrl: configurationId => `/configurations/${configurationId}/vr-packages`,
    },
    configurationsCreateVRPackage: {
      getUrl: configurationId => `/configurations/${configurationId}/create-vr-package`,
    },
    configurationsRemoveVRPackage: {
      getUrl: (configurationId, identifier) => `/configurations/${configurationId}/remove-vr-package/${identifier}`,
    },
    configurationsRenewVRPackage: {
      getUrl: (configurationId, identifier) => `/configurations/${configurationId}/renew-vr-package/${identifier}`,
    },
    configurationsCloseToThePublicVRPackage: {
      getUrl: (configurationId, identifier) => `/configurations/${configurationId}/close-to-the-public-vr-package/${identifier}`,
    },
    panoramaPhotosGetForVehicle: {
        getUrl: vehicleId => `/vehicles/${vehicleId}/panorama-photos`,
    },
    panoramaPhotoAdd: {
        getUrl: vehicleId => `/vehicles/${vehicleId}/add-panorama-photo`,
    },
    panoramaPhotosGetDetails: {
        getUrl: panoramaPhotoId => `/panorama-photos/${panoramaPhotoId}`,
    },
    panoramaPhotosEdit: {
        getUrl: panoramaPhotoId => `/panorama-photos/${panoramaPhotoId}/edit`,
    },
    panoramaPhotosSaveImage: {
        path: '/panorama-photos/save-image',
    },
    panoramaPhotosRemove: {
        getUrl: panoramaPhotoId => `/panorama-photos/${panoramaPhotoId}/remove`,
    },
    panoramaPhotosComponents: {
        getUrl: (panoramaPhotoId, systemId, setId) =>
          `/panorama-photos/${panoramaPhotoId}/${setId === 0 ? systemId : 0}/${setId}/components`,
    },
    panoramaPhotosAddComponent: {
      getUrl: (panoramaPhotoId, setId) =>
        `/panorama-photos/${panoramaPhotoId}/${setId}/add-component`,
    },
    photosList: {
      getUrl: vehicleId => `/vehicles/${vehicleId}/photos`,
    },
    photosAdd: {
      getUrl: () => '/photos/add',
    },
    photosRemove: {
      getUrl: photoId => `/photos/${photoId}/remove`,
    },
    photosEdit: {
      getUrl: photoId => `/photos/${photoId}/edit`,
    },
    photosSavePhotos: {
        getUrl: vehicleId => `/vehicles/${vehicleId}/save-photos`,
    },
    setsEdit: {
      getUrl: setId => `/sets/${setId}/edit`,
    },
    setsRemove: {
      getUrl: setId => `/sets/${setId}/remove`,
    },
    systemsAdd: {
        getUrl: systemsGroupId => `/systems-groups/${systemsGroupId}/add-system`,
    },
    systemsEdit: {
        getUrl: systemId => `/systems/${systemId}/edit`,
    },
    systemsGetDetails: {
        getUrl: systemId => `/systems/${systemId}`,
    },
    systemsRemove: {
        getUrl: systemId => `/systems/${systemId}/remove`,
    },
    systemsAddSet: {
        getUrl: systemId => `/systems/${systemId}/add-set`,
    },
    systemsGroupsAdd: {
        getUrl: configurationId => `/configurations/${configurationId}/add-systems-group`,
    },
    systemsGroupsEdit: {
        getUrl: systemsGroupId => `/systems-groups/${systemsGroupId}/edit`,
    },
    systemsGroupsGetDetails: {
        getUrl: systemsGroupId => `/systems-groups/${systemsGroupId}`,
    },
    systemsGroupsRemove: {
        getUrl: systemsGroupId => `/systems-groups/${systemsGroupId}/remove`,
    },
    tagsAdd: {
        getUrl: vehicleId => `/vehicles/${vehicleId}/add-tag`,
    },
    tagsGetForVehicle: {
        getUrl: vehicleId => `/vehicles/${vehicleId}/tags`,
    },
    tagsEdit: {
        getUrl: tagId => `/tags/${tagId}/edit`,
    },
    tagsRemove: {
        getUrl: tagId => `/tags/${tagId}/remove`,
    },
    vehiclesAdd: {
        path: '/vehicles/add',
    },
    vehiclesEdit: {
        path: '/vehicles/edit',
    },
    vehiclesGet: {
        path: '/vehicles',
    },
    vehiclesGetDetails: {
        getUrl: vehicleId => `/vehicles/${vehicleId}`,
    },
    vehiclesRemove: {
        getUrl: vehicleId => `/vehicles/${vehicleId}/remove`,
    },
}
