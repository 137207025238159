import { useState } from 'react';
import { DynamicFeed } from '@mui/icons-material';

import { useDeleteData } from '../../../helpers/hooks';
import { API_URLS } from '../../../urls/backend';
import { Dialog, DefaultListItem, ScrollContainer } from '../../common';
import { AddSystemsGroupForm } from '..';

export const SystemsGroup = ({
  activeItemId,
  isActiveVehicle,
  onDeleteItem,
  onGetSystems,
  permission,
  systemsGroup,
}) => {
  const [isEditDialogOpened, setIsEditDialogOpened] = useState(false);
  const [isRemoveDialogOpened, setIsRemoveDialogOpened] = useState(false);
  const [removingOn, setRemovingOn] = useState(false);
  const [systemsGroupDetails, setSystemGroupsDetails] = useState(systemsGroup);


  const { fetchError } = useDeleteData({
    removingOn,
    url: API_URLS.systemsGroupsRemove.getUrl(systemsGroupDetails.id),
    callbackSuccess: () => onDeleteItem(systemsGroupDetails.id),
    callbackError: () => setRemovingOn(false),
  });

  return (
    <>
      <DefaultListItem
        actionButtons
        description={systemsGroupDetails.name}
        icon={<DynamicFeed />}
        isActiveVehicle={isActiveVehicle}
        permission={permission}
        selected={activeItemId === systemsGroup.id ? true : false}
        title={systemsGroupDetails.code}
        onClick={() => onGetSystems(systemsGroup.id)}
        onEdit={() => setIsEditDialogOpened(true)}
        onDelete={() => setIsRemoveDialogOpened(true)}
      />
      {isRemoveDialogOpened &&
        <Dialog
          confirmationText="Remove"
          dialogTitle="Remove systems group"
          onCancel={() => setIsRemoveDialogOpened(false)}
          onCloseDialog={() => setIsRemoveDialogOpened(false)}
          onConfirm={() => setRemovingOn(true)}
        >
          <ScrollContainer>
            <p>Are you sure you want to remove this systems group - name: {systemsGroupDetails.name}, id: {systemsGroupDetails.id}?</p>
            {fetchError !== null && <p className="error">{fetchError}</p>}
          </ScrollContainer>
        </Dialog>
      }
      {isEditDialogOpened &&
        <Dialog onCloseDialog={() => setIsEditDialogOpened(false)}>
          <AddSystemsGroupForm
            addCallbackSuccess={(data) => {
              setSystemGroupsDetails(data);
              setIsEditDialogOpened(false);
            }}
            initialCode={systemsGroupDetails.code}
            initialName={systemsGroupDetails.name}
            isEditing
            onCancel={() => setIsEditDialogOpened(false)}
            systemsGroupId={systemsGroupDetails.id}
          />
        </Dialog>
      }
    </>
  );
};
