import { useContext, useEffect } from 'react';
import { useThree } from '@react-three/fiber';
import { PointerLockControls } from 'three/examples/jsm/controls/PointerLockControls';

import { ConfiguratorContext } from '../../../context/configuratorContext';


export const PointerLockCamera = () => {
  const { camera, gl } = useThree();
  const {
    listeners,
    setIsPanoramaInRotatingMode,
    setZoom,
    zoom
  } = useContext(ConfiguratorContext);

  function setAsLocked() {
    setIsPanoramaInRotatingMode(true);
  }
  function setAsUnlocked() {
    setIsPanoramaInRotatingMode(false);
  }

  useEffect(() => {
    camera.position.set(0, 0, 0);
  }, [camera, gl]);

  useEffect(() => {
    camera.zoom = zoom;
    camera.updateProjectionMatrix();
  }, [zoom]);

  useEffect(() => {
    const controls = new PointerLockControls(camera, document.body);

    function changeLock(e) {
      if (e.code === 'Space') {
        e.preventDefault();
        if (controls.isLocked) {
          controls.unlock();
        } else {
          controls.lock();
        }
      }
    }

    function zoomCamera(e) {
      if (e.key === '+') {
        setZoom(true);
      } else if (e.key === '-') {
        setZoom(false);
      }
    }

    if (listeners.activatePreview) {
      window.addEventListener('keydown', changeLock);
      window.addEventListener('keydown', zoomCamera);
      controls.addEventListener('lock', setAsLocked);
      controls.addEventListener('unlock', setAsUnlocked);
    } else {
      window.removeEventListener('keydown', changeLock);
      window.removeEventListener('keydown', zoomCamera);
      controls.removeEventListener('lock', setAsLocked);
      controls.removeEventListener('unlock', setAsUnlocked);
      controls.dispose();
    }

    return () => {
      window.removeEventListener('keydown', changeLock);
      window.removeEventListener('keydown', zoomCamera);
      if (!controls) { return }
      controls.dispose();
      controls.removeEventListener('lock', setAsLocked);
      controls.removeEventListener('unlock', setAsUnlocked);
    };
  }, [listeners.activatePreview]);

  return null;
};

export default PointerLockCamera;
